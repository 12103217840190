define('op/pods/repair/mixins/reset-repair-properties', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    resetProperties: function resetProperties() {
      this.setProperties({

        filterDeviceAll: false,
        filterDeviceRepaired: false,
        filterCradleAll: false,
        filterCradleRepaired: false,

        colorDeviceAll: 'bg-box-filter-color',
        colorDeviceRepaired: 'bg-box-filter-color',
        colorCradleAll: 'bg-box-filter-color',
        colorCradleRepaired: 'bg-box-filter-color'

      });
    },
    resetDeviceProperties: function resetDeviceProperties() {
      this.setProperties({
        //filterDeviceAll: false,
        filterDeviceRepaired: false,
        //colorDeviceAll: 'bg-box-filter-color',
        colorDeviceRepaired: 'bg-box-filter-color'
      });
    },
    resetCradleProperties: function resetCradleProperties() {
      this.setProperties({
        //filterCradleAll: false,
        filterCradleRepaired: false,
        //colorCradleAll: 'bg-box-filter-color',
        colorCradleRepaired: 'bg-box-filter-color'
      });
    },
    toggleFilterProperty: function toggleFilterProperty(propertyName) {
      var colorName = propertyName.replace("filter", "color");
      this.toggleProperty(propertyName);
      if (this.get(propertyName) === true) {
        this.resetProperties();
        this.set(propertyName, true);
        this.set(colorName, 'select-color');
      } else {
        this.set(colorName, 'bg-box-filter-color');
      }
    },
    filterDeviceAll: false,
    filterDeviceRepaired: false,
    filterCradleAll: false,
    filterCradleRepaired: false,

    colorDeviceAll: 'bg-box-filter-color',
    colorDeviceRepaired: 'bg-box-filter-color',
    colorCradleAll: 'bg-box-filter-color',
    colorCradleRepaired: 'bg-box-filter-color'
  });
});