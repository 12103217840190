define("op/pods/asset/controller", ["exports", "ember", "op/tools/checkHelper"], function (exports, _ember, _opToolsCheckHelper) {
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;
  var computed = _ember["default"].computed;
  var arrayDefinedWithData = _opToolsCheckHelper["default"].arrayDefinedWithData;
  var selectValueInFilter = _opToolsCheckHelper["default"].selectValueInFilter;
  var unselectValueInFilter = _opToolsCheckHelper["default"].unselectValueInFilter;
  exports["default"] = Controller.extend({
    i18n: service(),
    blue: '#258BE2',
    updates: service('updates'),

    userproperties: service('userproperties'),

    siteNumber: null,
    session: _ember["default"].inject.service('session'),
    disabledFilterProjects: false,
    disabledFilterCountries: false,
    disabledFilterSites: false,
    disabledFilterComponentKinds: false,
    disabledFilterComponentTypes: false,
    FilterComponentKindsIsVisible: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'input-group';
      }
    }),
    FilterComponentTypesIsVisible: 'input-group',
    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    filterChanged: 0,
    tileTabActive: null,
    grid1Init: false,
    grid2Init: false,
    grid3Init: false,
    filterValue: 'filterDepots',
    filterTypeMap: new Map([["filterDepots", "all"], ["filterDepotsOk", "ok"], ["filterDepotsCritical", "critical"], ["filterDepotsWarning", "warning"], ["filterDeviceName", "PDA"], ["filterCradleName", "CRA"], ["filterDevicesInPool", "4"], ["filterDevicesInPoolOthers", "99"], ["filterMobileDevicesSet", "mdmSet"], ["filterMobileDevicesNotSet", "mdmNotSet"], ["filterDevicesAll", "all"], ["filterDevicesAvailable", "1"], ["filterDevicesCalled", "5"], ["filterDevicesInRepair", "2"], ["filterDevicesOthers", "99"], ["filterCradlesAll", "all"], ["filterCradlesAvailable", "1"], ["filterCradlesCalled", "5"], ["filterCradlesInRepair", "2"], ["filterCradlesOthers", "99"]]),
    toolPanelVisible: false,
    autoFilter: '',
    init: function init() {
      this.set('tileTabActive', true);
      this.get('updates').setLastUpdated('AssetIsUpdated');
    },

    actions: {
      selectProjects: function selectProjects(projects) {

        this.set('selectedProjects', projects);
        console.log(this.get('selectedProjects'));
        this.get('selectedCountries').clear();
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.set('filterChanged', 0);
        if (!this.get('tileTabActive')) {
          this.createNewRowData1(this.get('aggregatedData').toArray());
          this.createNewRowData2(this.get('deviceData2').toArray());
          this.createNewRowData3(this.get('devicePoolData2').toArray());
        }
      },
      selectCountries: function selectCountries(countries) {
        this.set('selectedCountries', countries);
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTabActive')) {
          this.createNewRowData1(this.get('aggregatedData').toArray());
          this.createNewRowData2(this.get('deviceData2').toArray());
          //this.createNewRowData3(this.get('devicePoolData2').toArray());
        }
      },
      selectSites: function selectSites(sites) {
        this.set('selectedSites', sites);
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTabActive')) {
          this.createNewRowData1(this.get('aggregatedData').toArray());
          this.createNewRowData2(this.get('deviceData2').toArray());
          //this.createNewRowData3(this.get('devicePoolData2').toArray());
        }
      },
      selectComponentKinds: function selectComponentKinds(kinds) {
        this.set('selectedComponentKinds', kinds);
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTabActive')) {
          this.createNewRowData1(this.get('aggregatedData').toArray());
          this.createNewRowData2(this.get('deviceData2').toArray());
          this.createNewRowData3(this.get('devicePoolData2').toArray());
        }
      },
      selectComponentTypes: function selectComponentTypes(types) {
        this.set('selectedComponentTypes', types);
        this.incrementProperty('filterChanged');
        if (!this.get('tileTabActive')) {
          this.createNewRowData1(this.get('aggregatedData').toArray());
          this.createNewRowData2(this.get('deviceData2').toArray());
          this.createNewRowData3(this.get('devicePoolData2').toArray());
        }
      },
      activateTileTab: function activateTileTab() {
        this.setProperties({
          tileTabActive: true
        });
      },
      activateListTab: function activateListTab() {
        this.setProperties({
          tileTabActive: false
        });
        var grid = null;
        var filter = this.get('filterValue');
        if (filter.includes("Pool")) {
          grid = this.get('grid3');
          this.filter3();
        } else if (filter.includes("Depots")) {
          grid = this.get('grid1');
          this.filter1();
        } else {
          grid = this.get('grid2');
          this.filter2();
        }
        if (typeof grid.api !== 'undefined') {
          grid.api.sizeColumnsToFit();
        }
      },

      toggleToolPanel: function toggleToolPanel() {
        var filter = this.get('filterValue');
        var grid = null;
        if (filter.includes("Pool")) {
          grid = this.get('grid3');
        } else if (filter.includes("Depots")) {
          grid = this.get('grid1');
        } else {
          grid = this.get('grid2');
        }
        this.toggleProperty('toolPanelVisible');
        grid.api.showToolPanel(this.get('toolPanelVisible'));
        grid.api.sizeColumnsToFit();
      },

      autoComplete: function autoComplete() {
        var grid = null;
        if (this.get('filterValue').includes("Pool")) {
          grid = this.get('grid3');
        } else if (this.get('filterValue').includes("Depots")) {
          grid = this.get('grid1');
        } else {
          grid = this.get('grid2');
        }
        grid.api.setQuickFilter(this.get('autoFilter'));
      },
      filterGrid: function filterGrid(filter, cp) {
        this.set('filterValue', filter);
        this.set('asset-overview-panel', cp);
        if (this.get('filterValue').includes("Pool")) {
          this.filter3();
        } else if (this.get('filterValue').includes("Depots")) {
          this.filter1();
        } else {
          this.filter2();
        }
      }

    },
    filter1: function filter1() {
      var filter = this.get('filterValue');
      var cp = this.get('asset-overview-panel');

      var filterMap = this.get('filterTypeMap');
      var value = filterMap.get(filter);
      var grid = this.get('grid1');
      if (typeof grid.api !== 'undefined') {
        var nameFilterInstance = null;
        // reset all filter
        grid.api.setFilterModel(null);
        if (value === 'all') {
          //
        } else {
            nameFilterInstance = grid.api.getFilterInstance('siteState');
            if (cp.filterDepotsOk === true || cp.filterDepotsCritical === true || cp.filterDepotsWarning === true) {
              nameFilterInstance.selectNothing();
              if (cp.filterDepotsOk === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsOk'));
              }
              if (cp.filterDepotsCritical === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsCritical'));
              }
              if (cp.filterDepotsWarning === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsWarning'));
              }
            } else {
              nameFilterInstance.selectEverything();
            }
            grid.api.onFilterChanged();
          }
      }
    },

    isTileTabActive: computed('tileTabActive', function () {
      return this.get('tileTabActive');
    }),
    selectedColorCritical: computed('colorCritical', function () {
      //noinspection JSPotentiallyInvalidConstructorUsage
      return new _ember["default"].String.htmlSafe(this.get('colorCritical'));
    }),
    selectedColorWarning: computed('colorWarning', function () {
      //noinspection JSPotentiallyInvalidConstructorUsage
      return new _ember["default"].String.htmlSafe(this.get('colorWarning'));
    }),
    pageTitle: computed(function () {
      return 'Assets Dashboard';
    }),
    pageDescription: computed(function () {
      return 'Assets Overview';
    }),

    //#49384 Asset Knobs (für GEFCO)
    mappedDataWithoutSiteFilter: computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'AssetIsUpdated', 'model.assetsum', function () {

      var filterAssetSumModel = this.get('model.assetsum');
      var sitesMapped = filterAssetSumModel.mapBy('SiteId');
      var uniqueSites = sitesMapped.uniq();
      var filteredArr = null;
      var selectedProjects = this.get('selectedProjects');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      filteredArr = filterAssetSumModel.toArray();

      if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedProjects.includes(item.data.ProjectFamilyId);
        });
        filterAssetSumModel = filteredArr;
      }

      if (typeof selectedComponentKinds !== "undefined" && selectedComponentKinds !== null && selectedComponentKinds.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedComponentKinds.includes(item.data.ComponentKindID);
        });
        filterAssetSumModel = filteredArr;
      }
      if (typeof selectedComponentTypes !== "undefined" && selectedComponentTypes !== null && selectedComponentTypes.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedComponentTypes.includes(item.data.ComponentTypeID);
        });
        filterAssetSumModel = filteredArr;
      }

      var mappedData = _ember["default"].Map.create();
      uniqueSites.forEach(function (siteId) {
        mappedData.set(siteId, filterAssetSumModel.filterBy('SiteId', siteId));
      });

      return mappedData;
    }),

    mappedData: computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'AssetIsUpdated', 'model.assetsum', function () {

      var filterAssetSumModel = this.get('model.assetsum');
      var sitesMapped = filterAssetSumModel.mapBy('SiteId');
      var uniqueSites = sitesMapped.uniq();
      var filteredArr = null;
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      filteredArr = filterAssetSumModel.toArray();

      if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedProjects.includes(item.data.ProjectFamilyId);
        });
        filterAssetSumModel = filteredArr;
      }

      if (typeof selectedCountries !== "undefined" && selectedCountries !== null && selectedCountries.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedCountries.includes(item.data.SiteCCode);
        });
        filterAssetSumModel = filteredArr;
      }
      if (typeof selectedSites !== "undefined" && selectedSites !== null && selectedSites.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedSites.includes(item.data.SiteId);
        });
        filterAssetSumModel = filteredArr;
      }

      if (typeof selectedComponentKinds !== "undefined" && selectedComponentKinds !== null && selectedComponentKinds.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedComponentKinds.includes(item.data.ComponentKindID);
        });
        filterAssetSumModel = filteredArr;
      }
      if (typeof selectedComponentTypes !== "undefined" && selectedComponentTypes !== null && selectedComponentTypes.length > 0) {
        filteredArr = filterAssetSumModel.filter(function (item) {
          return selectedComponentTypes.includes(item.data.ComponentTypeID);
        });
        filterAssetSumModel = filteredArr;
      }

      var mappedData = _ember["default"].Map.create();
      uniqueSites.forEach(function (siteId) {
        mappedData.set(siteId, filterAssetSumModel.filterBy('SiteId', siteId));
      });

      return mappedData;
    }),

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    }),

    isProjectPoolActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-ProjectPool", 1) === "1" || userProperties.getNameByType("AC-Show-ProjectPool", 1) === "cadis") {
        return true;
      } else {
        return false;
      }
    }),

    isProjectPoolOthersActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-ProjectOthers", 1) === "1" || userProperties.getNameByType("AC-Show-ProjectOthers", 1) === "cadis") {
        return true;
      } else {
        return false;
      }
    }),

    isRepairInProject: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Asset-KnobRepairOutsideDepot", 0) === "1") {
        return true;
      } else {
        return false;
      }
    }),

    aggregatedDataWithoutSiteFilter: computed('mappedDataWithoutSiteFilter', function () {
      var _this = this;

      var aggregatedArray = [];
      var ii = 1;

      this.get('mappedDataWithoutSiteFilter').forEach(function (valueArray) {

        if (valueArray.length > 0) {
          var first = valueArray.get('firstObject');

          var ProjectNamesMapped = valueArray.mapBy('ProjectName').uniq('ProjectName').sort();
          var ProjectNamesString = '';
          ProjectNamesMapped.every(function (ProjectName) {
            ProjectNamesString = ProjectNamesString + ProjectName + ' , ';
            return true;
          });
          if (ProjectNamesString.length > 0) {
            ProjectNamesString = ProjectNamesString.substr(0, ProjectNamesString.length - 3);
          }

          var ProjectIdsMapped = valueArray.mapBy('ProjectId').uniq('ProjectId').sort();
          var ProjectIdsString = '';
          ProjectIdsMapped.every(function (ProjectId) {
            ProjectIdsString = ProjectIdsString + ProjectId + ',';
            return true;
          });
          if (ProjectIdsString.length > 0) {
            ProjectIdsString = ProjectIdsString.substr(0, ProjectIdsString.length - 1);
          }

          var ProjectFamiliesMapped = valueArray.mapBy('ProjectFamily').uniq('ProjectFamily').sort();
          var ProjectFamiliesString = '';
          ProjectFamiliesMapped.every(function (ProjectFamily) {
            ProjectFamiliesString = ProjectFamiliesString + ProjectFamily + ' , ';
            return true;
          });
          if (ProjectFamiliesString.length > 0) {
            ProjectFamiliesString = ProjectFamiliesString.substr(0, ProjectFamiliesString.length - 3);
          }

          var ProjectFamilyIdsMapped = valueArray.mapBy('ProjectFamilyId').uniq('ProjectFamilyId').sort();
          var ProjectFamilyIdsString = '';
          ProjectFamilyIdsMapped.every(function (ProjectFamilyId) {
            ProjectFamilyIdsString = ProjectFamilyIdsString + ProjectFamilyId + ',';
            return true;
          });
          if (ProjectFamilyIdsString.length > 0) {
            ProjectFamilyIdsString = ProjectFamilyIdsString.substr(0, ProjectFamilyIdsString.length - 1);
          }

          var DevicesSystems = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesSystems;
          }, 0);
          var AllDevices = 0;
          if (_this.get('isRepairInProject')) {
            AllDevices = valueArray.reduce(function (a, b) {
              return a + b.data.AllDevices;
            }, 0) - valueArray.reduce(function (a, b) {
              return a + b.data.DevicesInRepair;
            }, 0);
          } else {
            AllDevices = valueArray.reduce(function (a, b) {
              return a + b.data.AllDevices;
            }, 0);
          }
          var DevicesAvailable = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesAvailable;
          }, 0);
          var DevicesCalled = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesCalled;
          }, 0);
          var DevicesInRepair = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesInRepair;
          }, 0);
          var DevicesOther = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesOther;
          }, 0);

          var CradlesSystems = 0;
          var AllCradles = 0;
          var CradlesAvailable = 0;
          var CradlesCalled = 0;
          var CradlesInRepair = 0;
          var CradlesOther = 0;

          if (_this.get('isCradleActive')) {
            CradlesSystems = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesSystems;
            }, 0);
            if (_this.get('isRepairInProject')) {
              AllCradles = valueArray.reduce(function (a, b) {
                return a + b.data.AllCradles;
              }, 0) - valueArray.reduce(function (a, b) {
                return a + b.data.CradlesInRepair;
              }, 0);
            } else {
              AllCradles = valueArray.reduce(function (a, b) {
                return a + b.data.AllCradles;
              }, 0);
            }
            CradlesAvailable = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesAvailable;
            }, 0);
            CradlesCalled = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesCalled;
            }, 0);
            CradlesInRepair = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesInRepair;
            }, 0);
            CradlesOther = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesOther;
            }, 0);
          }

          var DevicesSum = AllDevices;
          var CradlesSum = AllCradles;

          if (_this.get('isRepairInProject')) {
            DevicesSum = DevicesSum + DevicesInRepair;
            CradlesSum = CradlesSum + CradlesInRepair;
          }

          var color = 'bg-green';
          var siteState = 'ok';
          var deviceOk = true;
          var deviceWarning = false;
          var deviceCritical = false;
          var colorDeviceAvailable = 'bg-green';
          var colorCradleAvailable = 'bg-green';
          if (DevicesSystems > 0) {
            var deviceAvailPercent = DevicesAvailable * 100 / DevicesSystems;
            //console.log('deviceAvailPercent: ' + deviceAvailPercent);
            if (deviceAvailPercent < 97) {
              deviceWarning = true;
              deviceOk = false;
              colorDeviceAvailable = 'bg-yellow';
            }
            if (deviceAvailPercent < 90) {
              deviceCritical = true;
              colorDeviceAvailable = 'bg-red';
            }
          }
          var cradleOk = true;
          var cradleWarning = false;
          var cradleCritical = false;
          if (CradlesSystems > 0) {
            var cradleAvailPercent = CradlesAvailable * 100 / CradlesSystems;
            //console.log('cradleAvailPercent: ' + cradleAvailPercent);
            if (cradleAvailPercent < 97) {
              cradleWarning = true;
              cradleOk = false;
              colorCradleAvailable = 'bg-yellow';
            }
            if (cradleAvailPercent < 90) {
              cradleCritical = true;
              colorCradleAvailable = 'bg-red';
            }
          }
          if (deviceOk && cradleOk) {
            siteState = 'ok';
            color = 'bg-green';
          }
          if (deviceWarning || cradleWarning) {
            siteState = 'warning';
            color = 'bg-yellow';
          }
          if (deviceCritical || cradleCritical) {
            siteState = 'critical';
            color = 'bg-red';
          }

          var SiteObject = _ember["default"].Object.create({
            id: ii,
            //UserLogin: first.get('UserLogin'),

            //ProjectName: first.get('ProjectName'),
            //ProjectId: first.get('ProjectId'),
            //ProjectFamily: first.get('ProjectFamily'),
            //ProjectFamilyId: first.get('ProjectFamilyId'),
            ProjectName: ProjectNamesString,
            ProjectId: ProjectIdsString,
            ProjectFamily: ProjectFamiliesString,
            ProjectFamilyId: ProjectFamilyIdsString,

            SiteIdDetail: first.get('SiteIdDetail'),
            SiteName: first.get('SiteName'),
            SiteCCode: first.get('SiteCCode'),
            SiteCountry: first.get('SiteCountry'),
            SiteNumber: first.get('SiteNumber'),
            SiteIdentifier: first.get('SiteIdentifier'),
            SiteId: first.get('SiteId'),
            SiteAdditionalAddress: first.get('SiteAdditionalAddress'),
            SiteStreet: first.get('SiteStreet'),
            SiteStreetNumber: first.get('SiteStreetNumber'),
            SitePostalCode: first.get('SitePostalCode'),
            SiteCity: first.get('SiteCity'),
            SitePhoneNumber: first.get('SitePhoneNumber'),
            SitePostReceiver: first.get('SitePostReceiver'),

            DevicesSystems: DevicesSystems,
            DevicesSum: DevicesSum,
            AllDevices: AllDevices,
            DevicesAvailable: DevicesAvailable,
            DevicesCalled: DevicesCalled,
            DevicesInRepair: DevicesInRepair,
            DevicesOther: DevicesOther,
            CradlesSystems: CradlesSystems,
            CradlesSum: CradlesSum,
            AllCradles: AllCradles,
            CradlesAvailable: CradlesAvailable,
            CradlesCalled: CradlesCalled,
            CradlesInRepair: CradlesInRepair,
            CradlesOther: CradlesOther,
            maxPercentAllDevices: '100',
            PercentAllDevices: '100',
            maxPercentDevicesAvailable: '100',
            PercentDevicesAvailable: '100',
            PercentDevicesCalled: '0',
            PercentDevicesInRepair: '0',
            PercentDevicesOthers: '0',
            maxPercentAllCradles: '100',
            PercentAllCradles: '100',
            maxPercentCradlesAvailable: '100',
            PercentCradlesAvailable: '100',
            PercentCradlesCalled: '0',
            PercentCradlesInRepair: '0',
            PercentCradlesOthers: '0',
            bgColor: color,
            isOk: siteState === 'ok',
            isCritical: siteState === 'critical',
            isWarning: siteState === 'warning',
            siteState: siteState,
            bgColorDeviceAvailable: colorDeviceAvailable,
            bgColorCradleAvailable: colorCradleAvailable,
            cradleFgColor: "#ffffff", //this.get('blue'),
            deviceFgColor: "#ffffff" });
          //this.get('blue')
          if (DevicesSystems > 0) {
            SiteObject.PercentAllDevices = (AllDevices * 100 / DevicesSystems).toFixed(0);
            if (SiteObject.PercentAllDevices > 100) {
              SiteObject.maxPercentAllDevices = SiteObject.PercentAllDevices;
            }
            SiteObject.PercentDevicesAvailable = (DevicesAvailable * 100 / DevicesSystems).toFixed(0);
            if (SiteObject.PercentDevicesAvailable > 100) {
              SiteObject.maxPercentDevicesAvailable = SiteObject.PercentDevicesAvailable;
            }
            SiteObject.PercentDevicesCalled = (DevicesCalled * 100 / DevicesSystems).toFixed(0);
            SiteObject.PercentDevicesInRepair = (DevicesInRepair * 100 / DevicesSystems).toFixed(0);
            SiteObject.PercentDevicesOthers = (DevicesOther * 100 / DevicesSystems).toFixed(0);
          }
          if (CradlesSystems > 0) {
            SiteObject.PercentAllCradles = (AllCradles * 100 / CradlesSystems).toFixed(0);
            if (SiteObject.PercentAllCradles > 100) {
              SiteObject.maxPercentAllCradles = SiteObject.PercentAllCradles;
            }
            SiteObject.PercentCradlesAvailable = (CradlesAvailable * 100 / CradlesSystems).toFixed(0);
            if (SiteObject.PercentCradlesAvailable > 100) {
              SiteObject.maxPercentCradlesAvailable = SiteObject.PercentCradlesAvailable;
            }
            SiteObject.PercentCradlesCalled = (CradlesCalled * 100 / CradlesSystems).toFixed(0);
            SiteObject.PercentCradlesInRepair = (CradlesInRepair * 100 / CradlesSystems).toFixed(0);
            SiteObject.PercentCradlesOthers = (CradlesOther * 100 / CradlesSystems).toFixed(0);
          }
          aggregatedArray.pushObject(SiteObject);
          ii += 1;
        }
      });

      return aggregatedArray;
    }),

    aggregatedData: computed('mappedData', function () {
      var _this2 = this;

      var aggregatedArray = [];
      var ii = 1;

      this.get('mappedData').forEach(function (valueArray) {

        if (valueArray.length > 0) {
          var first = valueArray.get('firstObject');

          var ProjectNamesMapped = valueArray.mapBy('ProjectName').uniq('ProjectName').sort();
          var ProjectNamesString = '';
          ProjectNamesMapped.every(function (ProjectName) {
            ProjectNamesString = ProjectNamesString + ProjectName + ' , ';
            return true;
          });
          if (ProjectNamesString.length > 0) {
            ProjectNamesString = ProjectNamesString.substr(0, ProjectNamesString.length - 3);
          }

          var ProjectIdsMapped = valueArray.mapBy('ProjectId').uniq('ProjectId').sort();
          var ProjectIdsString = '';
          ProjectIdsMapped.every(function (ProjectId) {
            ProjectIdsString = ProjectIdsString + ProjectId + ',';
            return true;
          });
          if (ProjectIdsString.length > 0) {
            ProjectIdsString = ProjectIdsString.substr(0, ProjectIdsString.length - 1);
          }

          var ProjectFamiliesMapped = valueArray.mapBy('ProjectFamily').uniq('ProjectFamily').sort();
          var ProjectFamiliesString = '';
          ProjectFamiliesMapped.every(function (ProjectFamily) {
            ProjectFamiliesString = ProjectFamiliesString + ProjectFamily + ' , ';
            return true;
          });
          if (ProjectFamiliesString.length > 0) {
            ProjectFamiliesString = ProjectFamiliesString.substr(0, ProjectFamiliesString.length - 3);
          }

          var ProjectFamilyIdsMapped = valueArray.mapBy('ProjectFamilyId').uniq('ProjectFamilyId').sort();
          var ProjectFamilyIdsString = '';
          ProjectFamilyIdsMapped.every(function (ProjectFamilyId) {
            ProjectFamilyIdsString = ProjectFamilyIdsString + ProjectFamilyId + ',';
            return true;
          });
          if (ProjectFamilyIdsString.length > 0) {
            ProjectFamilyIdsString = ProjectFamilyIdsString.substr(0, ProjectFamilyIdsString.length - 1);
          }

          var DevicesSystems = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesSystems;
          }, 0);
          var AllDevices = 0;
          if (_this2.get('isRepairInProject')) {
            AllDevices = valueArray.reduce(function (a, b) {
              return a + b.data.AllDevices;
            }, 0) - valueArray.reduce(function (a, b) {
              return a + b.data.DevicesInRepair;
            }, 0);
          } else {
            AllDevices = valueArray.reduce(function (a, b) {
              return a + b.data.AllDevices;
            }, 0);
          }
          var DevicesAvailable = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesAvailable;
          }, 0);
          var DevicesCalled = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesCalled;
          }, 0);
          var DevicesInRepair = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesInRepair;
          }, 0);
          var DevicesOther = valueArray.reduce(function (a, b) {
            return a + b.data.DevicesOther;
          }, 0);

          var MobileDevices = valueArray.reduce(function (a, b) {
            return a + b.data.MobileDevices;
          }, 0);
          var MobileDevices1Available = valueArray.reduce(function (a, b) {
            return a + b.data.MobileDevices1Available;
          }, 0);
          var MobileDevices0Available = valueArray.reduce(function (a, b) {
            return a + b.data.MobileDevices0Available;
          }, 0);
          var MobileDevicesOnline = valueArray.reduce(function (a, b) {
            return a + b.data.MobileDevicesOnline;
          }, 0);

          var CradlesSystems = 0;
          var AllCradles = 0;
          var CradlesAvailable = 0;
          var CradlesCalled = 0;
          var CradlesInRepair = 0;
          var CradlesOther = 0;

          if (_this2.get('isCradleActive')) {
            CradlesSystems = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesSystems;
            }, 0);
            if (_this2.get('isRepairInProject')) {
              AllCradles = valueArray.reduce(function (a, b) {
                return a + b.data.AllCradles;
              }, 0) - valueArray.reduce(function (a, b) {
                return a + b.data.CradlesInRepair;
              }, 0);
            } else {
              AllCradles = valueArray.reduce(function (a, b) {
                return a + b.data.AllCradles;
              }, 0);
            }
            CradlesAvailable = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesAvailable;
            }, 0);
            CradlesCalled = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesCalled;
            }, 0);
            CradlesInRepair = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesInRepair;
            }, 0);
            CradlesOther = valueArray.reduce(function (a, b) {
              return a + b.data.CradlesOther;
            }, 0);
          }

          var DevicesSum = AllDevices;
          var CradlesSum = AllCradles;

          if (_this2.get('isRepairInProject')) {
            DevicesSum = DevicesSum + DevicesInRepair;
            CradlesSum = CradlesSum + CradlesInRepair;
          }

          var color = 'bg-green';
          var siteState = 'ok';
          var deviceOk = true;
          var deviceWarning = false;
          var deviceCritical = false;
          var colorDeviceAvailable = 'bg-green';
          var colorCradleAvailable = 'bg-green';
          if (DevicesSystems > 0) {
            var deviceAvailPercent = DevicesAvailable * 100 / DevicesSystems;
            //console.log('deviceAvailPercent: ' + deviceAvailPercent);
            if (deviceAvailPercent < 97) {
              deviceWarning = true;
              deviceOk = false;
              colorDeviceAvailable = 'bg-yellow';
            }
            if (deviceAvailPercent < 90) {
              deviceCritical = true;
              colorDeviceAvailable = 'bg-red';
            }
          }

          var cradleOk = true;
          var cradleWarning = false;
          var cradleCritical = false;
          if (CradlesSystems > 0) {
            var cradleAvailPercent = CradlesAvailable * 100 / CradlesSystems;
            //console.log('cradleAvailPercent: ' + cradleAvailPercent);
            if (cradleAvailPercent < 97) {
              cradleWarning = true;
              cradleOk = false;
              colorCradleAvailable = 'bg-yellow';
            }
            if (cradleAvailPercent < 90) {
              cradleCritical = true;
              colorCradleAvailable = 'bg-red';
            }
          }
          if (deviceOk && cradleOk) {
            siteState = 'ok';
            color = 'bg-green';
          }
          if (deviceWarning || cradleWarning) {
            siteState = 'warning';
            color = 'bg-yellow';
          }
          if (deviceCritical || cradleCritical) {
            siteState = 'critical';
            color = 'bg-red';
          }

          var SiteObject = _ember["default"].Object.create({
            id: ii,
            //UserLogin: first.get('UserLogin'),

            //ProjectName: first.get('ProjectName'),
            //ProjectId: first.get('ProjectId'),
            //ProjectFamily: first.get('ProjectFamily'),
            //ProjectFamilyId: first.get('ProjectFamilyId'),
            ProjectName: ProjectNamesString,
            ProjectId: ProjectIdsString,
            ProjectFamily: ProjectFamiliesString,
            ProjectFamilyId: ProjectFamilyIdsString,

            SiteIdDetail: first.get('SiteIdDetail'),
            SiteName: first.get('SiteName'),
            SiteCCode: first.get('SiteCCode'),
            SiteCountry: first.get('SiteCountry'),
            SiteNumber: first.get('SiteNumber'),
            SiteIdentifier: first.get('SiteIdentifier'),
            SiteId: first.get('SiteId'),
            SiteAdditionalAddress: first.get('SiteAdditionalAddress'),
            SiteStreet: first.get('SiteStreet'),
            SiteStreetNumber: first.get('SiteStreetNumber'),
            SitePostalCode: first.get('SitePostalCode'),
            SiteCity: first.get('SiteCity'),
            SitePhoneNumber: first.get('SitePhoneNumber'),
            SitePostReceiver: first.get('SitePostReceiver'),
            DevicesSystems: DevicesSystems,
            DevicesSum: DevicesSum,
            AllDevices: AllDevices,
            DevicesAvailable: DevicesAvailable,
            DevicesCalled: DevicesCalled,
            DevicesInRepair: DevicesInRepair,
            DevicesOther: DevicesOther,
            MobileDevices: MobileDevices,
            MobileDevices1Available: MobileDevices1Available,
            MobileDevices0Available: MobileDevices0Available,
            MobileDevicesOnline: MobileDevicesOnline,
            CradlesSystems: CradlesSystems,
            CradlesSum: CradlesSum,
            AllCradles: AllCradles,
            CradlesAvailable: CradlesAvailable,
            CradlesCalled: CradlesCalled,
            CradlesInRepair: CradlesInRepair,
            CradlesOther: CradlesOther,
            maxPercentAllDevices: '100',
            PercentAllDevices: '100',
            maxPercentDevicesAvailable: '100',
            PercentDevicesAvailable: '100',
            PercentDevicesCalled: '0',
            PercentDevicesInRepair: '0',
            PercentDevicesOthers: '0',
            maxPercentAllCradles: '100',
            PercentAllCradles: '100',
            maxPercentCradlesAvailable: '100',
            PercentCradlesAvailable: '100',
            PercentCradlesCalled: '0',
            PercentCradlesInRepair: '0',
            PercentCradlesOthers: '0',
            bgColor: color,
            isOk: siteState === 'ok',
            isCritical: siteState === 'critical',
            isWarning: siteState === 'warning',
            siteState: siteState,
            bgColorDeviceAvailable: colorDeviceAvailable,
            bgColorCradleAvailable: colorCradleAvailable,
            cradleFgColor: "#ffffff", //this.get('blue'),
            deviceFgColor: "#ffffff" });
          //this.get('blue')
          if (DevicesSystems > 0) {
            SiteObject.PercentAllDevices = (AllDevices * 100 / DevicesSystems).toFixed(0);
            if (SiteObject.PercentAllDevices > 100) {
              SiteObject.maxPercentAllDevices = SiteObject.PercentAllDevices;
            }
            SiteObject.PercentDevicesAvailable = (DevicesAvailable * 100 / DevicesSystems).toFixed(0);
            if (SiteObject.PercentDevicesAvailable > 100) {
              SiteObject.maxPercentDevicesAvailable = SiteObject.PercentDevicesAvailable;
            }
            SiteObject.PercentDevicesCalled = (DevicesCalled * 100 / DevicesSystems).toFixed(0);
            SiteObject.PercentDevicesInRepair = (DevicesInRepair * 100 / DevicesSystems).toFixed(0);
            SiteObject.PercentDevicesOthers = (DevicesOther * 100 / DevicesSystems).toFixed(0);
          }
          if (CradlesSystems > 0) {
            SiteObject.PercentAllCradles = (AllCradles * 100 / CradlesSystems).toFixed(0);
            if (SiteObject.PercentAllCradles > 100) {
              SiteObject.maxPercentAllCradles = SiteObject.PercentAllCradles;
            }
            SiteObject.PercentCradlesAvailable = (CradlesAvailable * 100 / CradlesSystems).toFixed(0);
            if (SiteObject.PercentCradlesAvailable > 100) {
              SiteObject.maxPercentCradlesAvailable = SiteObject.PercentCradlesAvailable;
            }
            SiteObject.PercentCradlesCalled = (CradlesCalled * 100 / CradlesSystems).toFixed(0);
            SiteObject.PercentCradlesInRepair = (CradlesInRepair * 100 / CradlesSystems).toFixed(0);
            SiteObject.PercentCradlesOthers = (CradlesOther * 100 / CradlesSystems).toFixed(0);
          }
          aggregatedArray.pushObject(SiteObject);
          ii += 1;
        }
      });

      return aggregatedArray;
    }),

    grid1: computed(function () {
      var _this3 = this;

      var columnDefs = [];
      var userProperties = this.get('userproperties');

      columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }, { headerName: "AdditionalAddressInfo", field: "SiteAdditionalAddress", hide: true }, { headerName: "Street", field: "SiteStreet", hide: true }, { headerName: "StreetNumber", field: "SiteStreetNumber", hide: true }, { headerName: "PostalCode", field: "SitePostalCode", hide: true }, { headerName: "City", field: "SiteCity", hide: true }, { headerName: "PostReceiver", field: "SitePostReceiver", hide: true }, { headerName: "State", field: "siteState" }]
      }, {
        headerName: "Devices",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "All", field: "AllDevices" }, { headerName: "Available", field: "DevicesAvailable" }, { headerName: "Called", field: "DevicesCalled" }, { headerName: "In Repair", field: "DevicesInRepair" }, { headerName: "Others", field: "DevicesOther" }, { headerName: "Available[%]", field: "PercentDevicesAvailable", hide: true }, { headerName: "Called[%]", field: "PercentDevicesCalled", hide: true }, { headerName: "In Repair[%]", field: "PercentDevicesInRepair", hide: true }, { headerName: "Others[%]", field: "PercentDevicesOthers", hide: true }]
      }];

      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        //
      } else {
          columnDefs.push({
            headerName: "Cradles",
            headerClass: "centeredHeader",
            marryChildren: true,
            openByDefault: true,
            children: [{ headerName: "All", field: "AllCradles" }, { headerName: "Available", field: "CradlesAvailable" }, { headerName: "Called", field: "CradlesCalled" }, { headerName: "In Repair", field: "CradlesInRepair" }, { headerName: "Others", field: "CradlesOther" }, { headerName: "Available[%]", field: "PercentCradlesAvailable", hide: true }, { headerName: "Called[%]", field: "PercentCradlesCalled", hide: true }, { headerName: "In Repair[%]", field: "PercentCradlesInRepair", hide: true }, { headerName: "Others[%]", field: "PercentCradlesOthers", hide: true }]
          });
        }

      return {
        columnDefs: columnDefs,
        rowData: null,
        rowHeight: 30,
        ///			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: false,
        suppressRowClickSelection: false,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        enableStatusBar: true,
        onGridReady: function onGridReady() {
          _this3.createNewRowData1(_this3.get('aggregatedData').toArray());
        },
        onModelUpdated: function onModelUpdated() {
          _this3.updateSiteTotals();
        },
        onRowDoubleClicked: function onRowDoubleClicked() {
          _this3.onSiteRowDoubleClicked();
        }

      };
    }),
    onSiteRowDoubleClicked: function onSiteRowDoubleClicked() {
      if (this.get('siteGridReady')) {
        var selectedRows = this.get('grid1').api.getSelectedRows();
        //this.get('siteRowDoubleClicked')(selectedRows[0].SiteId);
        this.transitionToRoute('asset-sites', { queryParams: { siteId: selectedRows[0].SiteId } });
      }
    },

    createNewRowData1: function createNewRowData1(data) {
      if (!data) {
        return;
      }
      var grid = this.get('grid1');
      if (grid.api === null) {
        return;
      }
      grid.api.setRowData(data);
      this.filter1();
      grid.api.sizeColumnsToFit();
      grid.api.refreshView();
      this.set('siteGridRowCount', this.get('grid1').api.getModel().getRowCount());
      this.set('siteGridReady', true);
    },

    updateSiteTotals: function updateSiteTotals() {
      if (this.get('siteGridReady')) {
        this.set('siteGridRowCount', this.get('grid1').api.getModel().getRowCount());
      } else {
        this.set('siteGridRowCount', 0);
      }
      this.incrementProperty('siteGridEvent');
    },

    grid1IsVisible: _ember["default"].computed('filterValue', function () {
      if (this.get('filterValue').indexOf("Depots") > -1) {
        return 'display-inline';
      } else {
        return 'display-none';
      }
    }),

    //
    // Device
    //
    filter2: function filter2() {
      var filter = this.get('filterValue');
      var cp = this.get('asset-overview-panel');
      var mappedKindIds = this.get('mappedKindIds');
      var mappedStatusGroupIds = this.get('mappedStatusGroupIds');
      var filterMap = this.get('filterTypeMap');
      var value = filterMap.get(filter);
      var grid = this.get('grid2');
      if (typeof grid.api !== 'undefined') {
        var nameFilterInstance = null;

        // reset all filter
        grid.api.setFilterModel(null);

        if (value === 'mdmSet' || value === 'mdmNotSet') {
          nameFilterInstance = grid.api.getFilterInstance('ComponentKindName');
          nameFilterInstance.selectNothing();
          selectValueInFilter(nameFilterInstance, mappedKindIds[1]);
          grid.api.onFilterChanged();

          if (value === 'mdmSet') {
            nameFilterInstance = grid.api.getFilterInstance('MobileDevice1Available');
            nameFilterInstance.selectNothing();
            selectValueInFilter(nameFilterInstance, '1');
          }
          if (value === 'mdmNotSet') {
            nameFilterInstance = grid.api.getFilterInstance('MobileDevice0Available');
            nameFilterInstance.selectNothing();
            selectValueInFilter(nameFilterInstance, '1');
          }
          grid.api.onFilterChanged();
        } else if (value === 'all') {
          nameFilterInstance = grid.api.getFilterInstance('ComponentKindName');
          nameFilterInstance.selectNothing();
          if (filter.includes("Devices")) {
            selectValueInFilter(nameFilterInstance, mappedKindIds[1]);
          } else if (filter.includes("Cradles")) {
            selectValueInFilter(nameFilterInstance, mappedKindIds[2]);
          } else {
            nameFilterInstance.selectEverything();
          }

          nameFilterInstance = grid.api.getFilterInstance('ComponentStatusGroup');
          if (this.get('isRepairInProject')) {
            nameFilterInstance.selectEverything();
            unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInRepair')]);
            unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesInRepair')]);
          } else {
            nameFilterInstance.selectEverything();
          }

          grid.api.onFilterChanged();
        } else {

          nameFilterInstance = grid.api.getFilterInstance('ComponentKindName');
          nameFilterInstance.selectNothing();
          if (filter.includes("Devices")) {
            selectValueInFilter(nameFilterInstance, mappedKindIds[1]);
          } else if (filter.includes("Cradles")) {
            selectValueInFilter(nameFilterInstance, mappedKindIds[2]);
          } else {
            nameFilterInstance.selectEverything();
          }
          grid.api.onFilterChanged();

          if (cp.filterDevicesAvailable === true || cp.filterDevicesCalled === true || cp.filterDevicesInRepair === true || cp.filterDevicesOthers === true || cp.filterCradlesAvailable === true || cp.filterCradlesCalled === true || cp.filterCradlesInRepair === true || cp.filterCradlesOthers === true) {

            nameFilterInstance = grid.api.getFilterInstance('ComponentStatusGroup');
            nameFilterInstance.selectNothing();

            if (filter.includes("Devices")) {
              if (cp.filterDevicesAvailable === true) {
                selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesAvailable')]);
              }
              if (cp.filterDevicesCalled === true) {
                selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesCalled')]);
              }
              if (cp.filterDevicesInRepair === true) {
                selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInRepair')]);
              }
              if (cp.filterDevicesOthers === true) {
                selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesOthers')]);
              }
            } else //if (filter.includes("Cradles"))
              {
                if (cp.filterCradlesAvailable === true) {
                  selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesAvailable')]);
                }
                if (cp.filterCradlesCalled === true) {
                  selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesCalled')]);
                }
                if (cp.filterCradlesInRepair === true) {
                  selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesInRepair')]);
                }
                if (cp.filterCradlesOthers === true) {
                  selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesOthers')]);
                }
              }
          } else {
            nameFilterInstance = grid.api.getFilterInstance('ComponentStatusGroup');
            if (this.get('isRepairInProject')) {
              nameFilterInstance.selectEverything();
              unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInRepair')]);
              unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterCradlesInRepair')]);
            } else {
              nameFilterInstance.selectEverything();
            }
          }
          grid.api.onFilterChanged();
        }
      }
    },

    componentKindIds: computed.mapBy('model.device', 'ComponentKindID'),
    uniqueComponentKindIDs: computed.uniq('componentKindIds'),
    mappedKindIds: computed('model.device', 'uniqueComponentKindIDs', function () {
      var data = this.get('model.device');
      var uniqueComponentKindIDs = this.get('uniqueComponentKindIDs');
      var mappedData = {};
      uniqueComponentKindIDs.forEach(function (id) {
        var filteredData = data.findBy('ComponentKindID', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.ComponentKindName;
        }
      });
      return mappedData;
    }),
    componentStatusGroupIds: computed.mapBy('model.device', 'ComponentStatusGroupID'),
    uniqueComponentStatusGroupIds: computed.uniq('componentStatusGroupIds'),
    mappedStatusGroupIds: computed('model.device', 'uniqueComponentStatusGroupIds', function () {
      var data = this.get('model.device');
      var uniqueStatusGroupIds = this.get('uniqueComponentStatusGroupIds');
      var mappedData = {};
      uniqueStatusGroupIds.forEach(function (id) {
        var filteredData = data.findBy('ComponentStatusGroupID', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.ComponentStatusGroup;
        }
      });
      return mappedData;
    }),

    deviceData: _ember["default"].computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'AssetIsUpdated', 'model.device', function () {
      var filterDeviceModel = this.get('model.device');

      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = filterDeviceModel; //.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.get('SiteId'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      }
      return data;
    }),
    deviceData2: _ember["default"].computed('deviceData', function () {
      var aggregatedArray = [];

      this.get('deviceData').forEach(function (data) {
        aggregatedArray.push(data.data);
      });
      return aggregatedArray;
    }),

    grid2: _ember["default"].computed(function () {
      var _this4 = this;

      //let userProperties = this.get('userproperties');
      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }]
      }, {
        headerName: "Component",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Kind", field: "ComponentKindName" }, { headerName: "Kind Id", field: "ComponentKindID", hide: true }, { headerName: "TypeNr", field: "ComponentTypeNumber", hide: true }, { headerName: "Type", field: "ComponentTypeName" }, { headerName: "Status", field: "ComponentStatusText", hide: false }, { headerName: "Status Group", field: "ComponentStatusGroup" }, { headerName: "Status Group Id", field: "ComponentStatusGroupID", hide: true }, { headerName: "Time", field: "ComponentStatusTime", hide: false }]
      }, { headerName: "System Number", field: "SystemNumber" }, { headerName: "Serial Number", field: "Snr" }, { headerName: "Serial Number Id", field: "SnrId", hide: true }];

      //if (userProperties.getNameByType("AC-Show-DeviceIsMDM", 1) === "1" ||
      //  userProperties.getNameByType("AC-Show-DeviceIsMDM", 1) === "cadis") {
      columnDefs.push({ headerName: "MDM", field: "MobileDevice", hide: true }, { headerName: "Available MDM ", field: "MobileDevice1Available", hide: true }, { headerName: "Available no MDM", field: "MobileDevice0Available", hide: true }, { headerName: "MDM Online <= 2h", field: "MobileDeviceOnline", hide: true }, { headerName: "MDM LastCheckIn", field: "MDMLastCheckInTime", hide: true });
      //}

      columnDefs.push({
        headerName: "Device",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "ICCID", field: "AsPhoneIdentifier" }, { headerName: "Phone number", field: "AsPhoneNumber" }, { headerName: "KD Reference Number", field: "AsKDReferenz", hide: true }, { headerName: "IMEI", field: "AsImei" }, { headerName: "MAC", field: "AsMac" }]
      });
      return {
        columnDefs: columnDefs,
        //rowData: data,
        rowHeight: 30,
        ///			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this4.createNewRowData2(_this4.get('deviceData2').toArray());
        },
        onModelUpdated: function onModelUpdated() {
          _this4.updateDeviceTotals();
        }
      };
    }),

    createNewRowData2: function createNewRowData2(data) {
      if (!data) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      var grid = this.get('grid2');
      if (grid.api === null) {
        return;
      }
      grid.api.setRowData(data);
      this.filter2();
      grid.api.sizeColumnsToFit();
      grid.api.refreshView();
      this.set('deviceGridRowCount', this.get('grid2').api.getModel().getRowCount());
      this.set('deviceGridReady', true);
    },

    updateDeviceTotals: function updateDeviceTotals() {
      if (this.get('deviceGridReady')) {
        this.set('deviceGridRowCount', this.get('grid2').api.getModel().getRowCount());
      } else {
        this.set('deviceGridRowCount', 0);
      }
      this.incrementProperty('deviceGridEvent');
    },

    grid2IsVisible: _ember["default"].computed('filterValue', function () {
      if (this.get('filterValue').indexOf("Depots") > -1 || this.get('filterValue').indexOf("Pool") > -1) {
        return 'display-none';
      } else {
        return 'display-inline';
      }
    }),

    //
    // DevicePool
    //
    filter3: function filter3() {

      var filter = this.get('filterValue');
      var cp = this.get('asset-overview-panel');
      var mappedStatusGroupIds = this.get('mappedStatusGroupIds');
      var filterMap = this.get('filterTypeMap');
      var value = filterMap.get(filter);

      var grid = this.get('grid3');
      if (typeof grid.api !== 'undefined') {
        var nameFilterInstance = null;

        // reset all filter
        grid.api.setFilterModel(null);

        // filterDevicesInPool=4, filterDevicesInPool-inRepair=2, filterDevicesInPoolOthers=99
        if (value === '4' || value === '2' || value === '99') {

          if (cp.filterDevicesInPool === true || cp.filterDevicesInPoolOthers === true) {

            //#52044 AGEDISS cradles + SIM
            // 1. ComponentKindName
            nameFilterInstance = grid.api.getFilterInstance('ComponentKindName');
            nameFilterInstance.selectNothing();
            //selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDeviceName')]);
            selectValueInFilter(nameFilterInstance, 'PDA');

            // 2. ComponentStatusGroup
            nameFilterInstance = grid.api.getFilterInstance('ComponentStatusGroup');

            if (value === '4' || value === '2') {
              nameFilterInstance.selectEverything();
              unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesOthers')]);
              unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInPoolOthers')]);
            } else if (value === '99') {
              nameFilterInstance.selectNothing();
              selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesOthers')]);
              selectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInPoolOthers')]);

              //unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesAvailable')]);
              //unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInPool')]);
              //unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesCalled')]);
              //unselectValueInFilter(nameFilterInstance, mappedStatusGroupIds[filterMap.get('filterDevicesInRepair')]);
            }
          }

          grid.api.onFilterChanged();
        }
      }
    },

    //devicePoolData: Ember.computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'AssetIsUpdated', 'model.devicepool', function () {
    devicePoolData: _ember["default"].computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'AssetIsUpdated', 'model.devicepool', function () {
      var model = this.get('model.devicepool');

      var selectedProjects = this.get('selectedProjects');
      //let selectedCountries = this.get('selectedCountries');
      //let selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = model.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      //if (arrayDefinedWithData(selectedCountries)) {
      //  data2 = data.filter(function (item) {
      //    return (selectedCountries.includes(item.get('SiteCCode')));
      //  });
      //  data = data2;
      //}
      //if (arrayDefinedWithData(selectedSites)) {
      //  data2 = data.filter(function (item) {
      //    return (selectedSites.includes(item.get('SiteId')));
      //  });
      //  data = data2;
      //}
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          //return (selectedComponentTypes.includes(item.data.ComponentTypeID));
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      }

      return data;
    }),

    devicePoolData2: _ember["default"].computed('devicePoolData', function () {
      var isProjectPoolActive = this.get('isProjectPoolActive');
      var isProjectPoolOthersActive = this.get('isProjectPoolOthersActive');
      //const isCradleActive = this.get('isCradleActive');
      var aggregatedArray = [];
      //let userProperties = this.get('userproperties');

      this.get('devicePoolData').forEach(function (data) {
        //4,  --'in pool', 2, --'in repair' 99)  --'other' (Im Rollout, gestohlen, Verlust, Totalschaden, Verlust nicht ersetzt, Clearing)     
        if (isProjectPoolActive && data.data.ComponentStatusGroupID === 4) {
          aggregatedArray.push(data.data);
        } else if (isProjectPoolActive && data.data.ComponentStatusGroupID === 2) {
          aggregatedArray.push(data.data);
        } else if (isProjectPoolOthersActive && data.data.ComponentStatusGroupID === 99) {
          aggregatedArray.push(data.data);
        }
      });
      return aggregatedArray;
    }),

    grid3: _ember["default"].computed(function () {
      var _this5 = this;

      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" },

      // {
      //   headerName: "Site",
      //   headerClass: "centeredHeader",
      //   marryChildren: true,
      //   openByDefault: true,
      //   children: [
      //     {headerName: "CCode", field: "SiteCCode", width: 10},
      //     {headerName: "Country", field: "SiteCountry", hide: true, width: 30},
      //     {headerName: "Site Id", field: "SiteIdDetail", hide: true},
      //     {headerName: "Name", field: "SiteName"},
      //     {headerName: "Identifier", field: "SiteIdentifier"},
      //   ]
      // },

      {
        headerName: "Component",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Kind", field: "ComponentKindName" }, { headerName: "Kind Id", field: "ComponentKindID", hide: true }, { headerName: "TypeNr", field: "ComponentTypeNumber", hide: true }, { headerName: "Type", field: "ComponentTypeName" }, { headerName: "Status", field: "ComponentStatusText", hide: false }, { headerName: "Status Group", field: "ComponentStatusGroup" }, { headerName: "Status Group Id", field: "ComponentStatusGroupID", hide: true }, { headerName: "Time", field: "ComponentStatusTime", hide: false }]
      }, { headerName: "System Number", field: "SystemNumber" }, { headerName: "Serial Number", field: "Snr" }, { headerName: "Serial Number Id", field: "SnrId", hide: true }, {
        headerName: "Device",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [
        // {headerName: "ICCID", field: "AsPhoneIdentifier"},
        // {headerName: "Phone Number", field: "AsPhoneNumber"},
        { headerName: "KD Reference Number", field: "AsKDReferenz", hide: true }, { headerName: "IMEI", field: "AsImei" }, { headerName: "MAC", field: "AsMac" }]
      }];
      return {
        columnDefs: columnDefs,
        //rowData: data,
        rowHeight: 30,
        ///			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this5.createNewRowData3(_this5.get('devicePoolData2').toArray());
        },
        onModelUpdated: function onModelUpdated() {
          _this5.updateDevicePoolTotals();
        }
      };
    }),

    createNewRowData3: function createNewRowData3(data) {
      if (!data) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      var grid = this.get('grid3');
      if (grid.api === null) {
        return;
      }
      grid.api.setRowData(data);
      this.filter3();
      grid.api.sizeColumnsToFit();
      grid.api.refreshView();
      this.set('devicePoolGridRowCount', this.get('grid3').api.getModel().getRowCount());
      this.set('devicePoolGridReady', true);
    },

    updateDevicePoolTotals: function updateDevicePoolTotals() {
      if (this.get('devicePoolGridReady')) {
        this.set('devicePoolGridRowCount', this.get('grid3').api.getModel().getRowCount());
      } else {
        this.set('devicePoolGridRowCount', 0);
      }
      this.incrementProperty('deviceGridEvent');
    },

    grid3IsVisible: _ember["default"].computed('filterValue', function () {
      if (this.get('filterValue').indexOf("Pool") > -1) {
        return 'display-inline';
      } else {
        return 'display-none';
      }
    })
  });

  /////

  function definedWithData(object) {
    return typeof object !== "undefined" && object !== null;
  }
});