define("op/controllers/init-password", ["exports", "ember", "ember-cp-validations"], function (exports, _ember, _emberCpValidations) {

  /*
  export default Ember.Controller.extend({
    queryParams: ['uid'],
    uid: null
  });
  */

  var Validations = (0, _emberCpValidations.buildValidations)({
    newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
      message: 'Password must be at least 12 characters long and contain at least one upper case letter, one lower case letter, and a number'
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  exports["default"] = _ember["default"].Controller.extend(Validations, {
    applicationController: _ember["default"].inject.controller('application'),
    ajax: _ember["default"].inject.service(),
    actions: {
      initPassword: function initPassword() {
        var _this = this;

        console.log('init password');
        var ajax = this.get('ajax');

        ///////////////////////
        this.set('locUID', this.get('uid'));
        ///////////////////////

        //#49635 Trim bei allen Eingaben
        var myTrim = this.get('newPassword').trim();
        this.set('newPassword', myTrim);
        myTrim = this.get('confirmPassword').trim();
        this.set('confirmPassword', myTrim);

        var pwdata = this.getProperties('locUID', 'newPassword');

        return ajax.request('/api/password-init', {
          method: 'POST',
          data: pwdata
        }).then(function (result) {
          _this.set('newPassword', '');
          _this.set('confirmPassword', '');
          console.log(result);
          _this.transitionToRoute('application');
        })["catch"](function (error) {
          _this.set('errors', error.errors);
        });
      }
    }

  });
});