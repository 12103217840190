define('op/pods/mdm-device-detail/device-status/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    chartOptions_: {
      chart: {
        type: 'column',
        color: '#258BE2',
        height: 300

      },
      title: {
        useHTML: true,
        text: "<img src='assets/img/svg/device_battery_state_anthrazit-60x60px.svg' style='height: 50px; width: 50px;'>",
        x: 27
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: ['battery state']
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'current state'
        },
        labels: {
          enabled: 'true'
        },
        plotLines: [{
          label: {
            text: 'critical', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: -10, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'red', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          //value: 80, // Value of where the line will appear
          width: 2 // Width of the line
        }, {
          label: {
            text: 'warning', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: +110, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'yellow', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          width: 2 // Width of the line
        }]

      },
      tooltip: {
        headerFormat: '<span style="font-size:10px;">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0;">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#258BE2',
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'center',
            color: 'white'
          }
        }
      }
    },

    chartBatteryStateOptions: _ember['default'].computed('chartOptions_', 'model', function () {
      var deviceData = this.get('model');
      var opt = this.get('chartOptions_');
      opt.yAxis.plotLines[0].value = deviceData.get('limitBatteryCritical');
      opt.yAxis.plotLines[1].value = deviceData.get('limitBatteryWarning');
      return opt;
    }),
    chartAvailableRAMOptions_: {
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        useHTML: true,
        text: "<img class='center-block' src='assets/img/svg/available_RAM_anthrazit-60x60px.svg' style='height: 50px; width: 50px;'>"
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: ['available RAM']
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: ''
        },
        labels: {
          enabled: false
        },
        plotLines: [{
          label: {
            text: 'critical', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: -10, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'red', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          //value: 80, // Value of where the line will appear
          width: 2 // Width of the line
        }, {
          label: {
            text: 'warning', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: +110, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'yellow', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          width: 2 // Width of the line
        }]

      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#258BE2',
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'center',
            color: 'white'
          }
        }
      }
    },
    chartAvailableRAMOptions: _ember['default'].computed('chartAvailableRAMOptions_', 'model', function () {
      var deviceData = this.get('model');
      var opt = this.get('chartAvailableRAMOptions_');
      opt.yAxis.plotLines[0].value = deviceData.get('limitMemoryCritical');
      opt.yAxis.plotLines[1].value = deviceData.get('limitMemoryWarning');
      return opt;
    }),
    chartAvailableStorageOptions_: {
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        useHTML: true,
        text: "<img src='assets/img/svg/available_hard_disk_storage_anthrazit-60x60px.svg' style='height: 50px; width: 50px;'>",
        x: -21
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: ['available Storage']
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: ''
        },
        labels: {
          enabled: true
        },
        opposite: true,
        plotLines: [{
          label: {
            text: 'critical', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: -10, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'red', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          //value: 80, // Value of where the line will appear
          width: 2 // Width of the line
        }, {
          label: {
            text: 'warning', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: +110, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'yellow', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          width: 2 // Width of the line
        }]

      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#258BE2',
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'center',
            color: 'white'
          }
        }
      }
    },
    chartAvailableStorageOptions: _ember['default'].computed('chartAvailableStorageOptions_', 'model', function () {
      var deviceData = this.get('model');
      var opt = this.get('chartAvailableStorageOptions_');
      opt.yAxis.plotLines[0].value = deviceData.get('limitStorageCritical');
      opt.yAxis.plotLines[1].value = deviceData.get('limitStorageWarning');
      return opt;
    }),
    chartBatteryData: _ember['default'].computed('model', function () {
      var deviceData = this.get('model');
      return [{
        name: 'current Battery',
        data: [deviceData.get('BatteryStatus')]
      }];
    }),
    chartRAMData: _ember['default'].computed('model', function () {
      var deviceData = this.get('model');
      return [{
        name: 'available RAM',
        data: [deviceData.get('AvailableRAMPercent')]
      }];
    }),
    chartStorageData: _ember['default'].computed('model', function () {
      var deviceData = this.get('model');
      return [{
        name: 'available Storage',
        data: [deviceData.get('AvailableStoragePercent')]
      }];
    })
  });
});