define("op/pods/incident/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var computed = _ember["default"].computed;
  var getOwner = _ember["default"].getOwner;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    session: service('session'),
    updates: service('updates'),
    ajax: service('ajax'),
    model: function model() {
      if (this.get('updates').get('IncidentIsUpdated') === true) {
        return _ember["default"].RSVP.hash({
          incidents: this.store.findAll('incident', { reload: true })
        });
      } else {
        return { incidents: this.store.peekAll('incident') };
      }
    },
    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    afterModel: function afterModel() {

      var ajax = this.get('ajax');

      this.set('userId', this.get('userId'));
      this.set('subModulId', 2);
      this.set('routeId', 1);
      this.set('description', ''); //"incident");
      var pwdata = this.getProperties('userId', 'subModulId', 'routeId', 'description');

      ajax.request('/api/set-subModule', {
        method: 'POST',
        data: pwdata
      });

      if (this.get('updates').get('IncidentIsUpdated')) {
        this.get('updates').resetLastUpdated('IncidentIsUpdated');
      }
    },
    actions: {
      error: function error(_error, transition) {
        console.log(transition);
        alert('The following error occurred: ' + _error);
        this.transitionTo('application');
      }
    }
  });
});