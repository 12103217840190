define("op/pods/repair/failure-rate-history/chart-failure-rate-history/component", ["exports", "ember", "ember-highcharts/components/high-charts", "moment", "op/tools/checkHelper"], function (exports, _ember, _emberHighchartsComponentsHighCharts, _moment, _opToolsCheckHelper) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var definedWithData = _opToolsCheckHelper["default"].definedWithData;
  exports["default"] = _emberHighchartsComponentsHighCharts["default"].extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      //		this.get('content');
    },

    userproperties: service('userproperties'),

    aggregatedDataBySite: computed('repairYearData', function () {
      var _this = this;

      var data = this.get('repairYearData');
      var refDates = data.mapBy('RefDate');
      var uniqueRefDates = refDates.uniq();
      var mappedData = {};
      var filterValue = this.get('filterValue');
      var filterProperty = this.get('filterProperty');
      var data2 = [];
      if (definedWithData(filterProperty) && definedWithData(filterValue) && filterValue === true) {
        if (filterProperty.startsWith('filterDevice')) {
          data2 = data.filter(function (item) {
            return item.get('ComponentKindID') === 1;
          });
          data = data2;
        }
        if (filterProperty.startsWith('filterCradle')) {
          data2 = data.filter(function (item) {
            return item.get('ComponentKindID') === 2;
          });
          data = data2;
        }
      } else {
        if (this.get('isCradleActive')) {
          // nichts zu tun
        } else {
            // wie filterDevice
            data2 = data.filter(function (item) {
              return item.get('ComponentKindID') === 1;
            });
            data = data2;
          }
      }

      uniqueRefDates.forEach(function (refDate) {
        var filteredRefDate = data.filterBy('RefDate', refDate);
        var siteIds = filteredRefDate.mapBy('SiteId');
        var uniqueSiteIds = siteIds.uniq();
        var siteArray = [];
        uniqueSiteIds.forEach(function (siteId) {
          var filteredSiteIds = filteredRefDate.filterBy('SiteId', siteId);
          var first = filteredSiteIds.get('firstObject');
          var allSystems = 0;
          if (definedWithData(filterProperty) && definedWithData(filterValue) && filterValue === true) {
            if (filterProperty.startsWith('filterDevice')) {
              allSystems = filteredSiteIds.reduce(function (a, b) {
                return a + b.data.AllDevices;
              }, 0);
            } else if (filterProperty.startsWith('filterCradle')) {
              allSystems = filteredSiteIds.reduce(function (a, b) {
                return a + b.data.AllCradles;
              }, 0);
              //console.log('all cradles: ' + allSystems + ', SiteId: ' + siteId + ', RefDate: ' + refDate);
            }
          } else {
              if (_this.get('isCradleActive')) {
                allSystems = filteredSiteIds.reduce(function (a, b) {
                  return a + b.data.AllSystems;
                }, 0);
              } else {
                allSystems = filteredSiteIds.reduce(function (a, b) {
                  return a + b.data.AllDevices;
                }, 0);
              }
            }
          var repairedCount = filteredSiteIds.reduce(function (a, b) {
            return a + b.data.RepairedCount;
          }, 0);
          var failureRate = 0;
          if (allSystems > 0) {
            failureRate = (repairedCount * 100 / allSystems).toFixed(2);
          }
          if (failureRate > 0) {
            var group_data = [first.get('SiteName'), Number(failureRate)];
            siteArray.push(group_data);
          }
        });
        if (siteArray.length > 0) {
          mappedData[refDate] = mappedData[refDate] || [];
          siteArray.sort(function (a, b) {
            return b[1] - a[1];
          });
          mappedData[refDate].push(siteArray);
        }
      });
      return mappedData;
    }),

    aggregatedRepairData: computed('repairYearData', 'uniqueComponentKindIDs', function () {
      var data = this.get('repairYearData');
      var aggregatedArray = [];
      var refDates = data.mapBy('RefDate');
      var uniqueRefDates = refDates.uniq();
      var uniqueComponentKindIDs = this.get('uniqueComponentKindIDs');
      var mappedData = _ember["default"].Map.create();
      var data2 = [];
      var filterValue = this.get('filterValue');
      var filterProperty = this.get('filterProperty');
      if (definedWithData(filterProperty) && definedWithData(filterValue) && filterValue === true) {
        if (filterProperty.startsWith('filterDevice')) {
          data2 = data.filter(function (item) {
            return item.get('ComponentKindID') === 1;
          });
          data = data2;
        }
        if (filterProperty.startsWith('filterCradle')) {
          data2 = data.filter(function (item) {
            return item.get('ComponentKindID') === 2;
          });
          data = data2;
        }
      } else {
        if (this.get('isCradleActive')) {
          // nichts zu tun
        } else {
            // wie filterDevice
            data2 = data.filter(function (item) {
              return item.get('ComponentKindID') === 1;
            });
            data = data2;
          }
      }

      uniqueRefDates.forEach(function (refDate) {
        mappedData.set(refDate, data.filterBy('RefDate', refDate));
      });

      mappedData.forEach(function (valueArray) {
        if (valueArray.length > 0) {
          (function () {
            var first = valueArray.get('firstObject');

            var kindMap = _ember["default"].Map.create();
            //let kindIds = valueArray.mapBy('ComponentKindID');
            //let uniqueKindIds = kindIds.uniq();
            uniqueComponentKindIDs.forEach(function (kindId) {
              kindMap.set(kindId, valueArray.filterBy('ComponentKindID', kindId));
            });
            var allSystems = 0;
            var allRepairs = 0;
            kindMap.forEach(function (kindArray) {
              //const firstKind = kindArray.get('firstObject');
              var systemsAll = kindArray.reduce(function (a, b) {
                return a + b.data.AllSystems;
              }, 0);
              var repairedCount = kindArray.reduce(function (a, b) {
                return a + b.data.RepairedCount;
              }, 0);
              allSystems += systemsAll;
              allRepairs += repairedCount;
            });
            var RepairObject = _ember["default"].Object.create({
              RefDate: first.get('RefDate'),
              AllSystems: allSystems,
              RepairedCount: allRepairs
            });
            aggregatedArray.pushObject(RepairObject);
          })();
        }
      });
      return aggregatedArray;
    }),
    drillDownSet: _ember["default"].observer('chart.options.drilledDown', function () {
      console.log('drilledDown');
      if (this.get('chart.options.drilledDown') === true) {
        this.attrs.drillDownClicked(this.get('chart.options.id'));
      } else {
        this.attrs.drillUpClicked();
      }
    }),
    content: computed('aggregatedRepairData', 'aggregatedDataBySite', 'filterValue', 'filterProperty', function () {
      var _this2 = this;

      var chart = this.get('chart');
      if (!definedWithData(chart) || chart.options.drilledDown === false) {
        var _ret2 = (function () {

          var aggregatedRepairData = _this2.get('aggregatedRepairData');
          var filterValue = _this2.get('filterValue');
          var filterProperty = _this2.get('filterProperty');
          var chart = _this2.get('chart');
          if (typeof chart !== 'undefined' && chart !== null) {
            var opt = chart.options;
            opt["groups"] = _this2.get('aggregatedDataBySite');

            if (chart.options.drilledDown === true) {
              if (chart.drilldownLevels.length > 0) {
                chart.drillUp();
              }
            }
          }
          var _chartData = [{
            name: 'Repair failure rate',
            type: 'column',
            colorByPoint: false,
            color: '#258BE2',
            data: []
          }, {
            name: 'repaired systems',
            type: 'column',
            yAxis: 1,
            colorByPoint: false,
            color: '#0C9E5C',
            data: []
          }];
          aggregatedRepairData.forEach(function (data) {
            var refDate = data.get('RefDate');
            var formattedDate = (0, _moment["default"])(refDate).format('YYYY-MM');
            var allSystems = data.get('AllSystems');
            var repairedCount = data.get('RepairedCount');
            var failureRate = 0;
            if (allSystems > 0) {
              failureRate = (repairedCount * 100 / allSystems).toFixed(2);
            }
            var entry = {
              name: formattedDate,
              y: Number(failureRate),
              myData: ' failure rate',
              drilldown: refDate
            };
            var formattedMyData = ' / ' + Number(allSystems) + ' [failures/all systems]';
            var entry2 = {
              name: formattedDate,
              y: Number(repairedCount),
              myData: formattedMyData,
              drilldown: refDate
            };
            if (definedWithData(filterProperty) && definedWithData(filterValue) && filterValue === true && (filterProperty === 'filterDeviceRepaired' || filterProperty === 'filterCradleRepaired')) {
              if (repairedCount > 0) {
                _chartData[0].data.pushObject(entry);
                _chartData[1].data.pushObject(entry2);
              }
            } else {
              _chartData[0].data.pushObject(entry);
              _chartData[1].data.pushObject(entry2);
            }
          });
          return {
            v: _chartData
          };
        })();

        if (typeof _ret2 === "object") return _ret2.v;
      }
    }),

    chartOptions_: {
      chart: {
        type: 'column',
        events: {
          drilldown: function drilldown(e) {
            if (!e.seriesOptions) {
              var id = e.point.drilldown.toString();

              var chart = this;
              chart.setTitle({ text: 'Repair failure rate per Depot for month ' + e.point.name });
              this.yAxis[0].setTitle({ text: 'Repair failure rate[%]' });
              var options = chart.options;
              var groups = chart.options.groups;
              var drilldowns = _defineProperty({}, id, {
                name: 'Repair failure rate',
                tooltip: {
                  headerFormat: '<span style="font-size:11px;">{series.name}</span><br>',
                  pointFormat: '<span style="color:{point.color};">{point.name}</span>: failure rate ' + '<b>{point.y}</b> for depot!<br/>'
                },
                data: groups[id][0]
              }),
                  series = drilldowns[id];
              chart.addSeriesAsDrilldown(e.point, series);
              _ember["default"].set(options, 'id', id);
              _ember["default"].set(options, 'drilledDown', true);
            }
          },
          drillup: function drillup() {
            _ember["default"].set(this.options, 'drilledDown', false);
            this.setTitle({ text: 'Repair failure rate' });
            this.yAxis[0].setTitle({ text: 'Repair failure rate [%]' });
          }
        }
      },
      title: {
        text: 'Repair failure rate'
      },
      xAxis: {
        type: 'category'
      },
      yAxis: [{
        title: {
          text: 'Repair failure rate [%]'
        },
        allowDecimals: true,
        plotLines: [{
          label: {
            text: 'critical failure rate limit', // Content of the label.
            align: 'right', // Positioning of the label.
            x: -10 },
          // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'red', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          value: 5, // Value of where the line will appear
          width: 2 // Width of the line
        }]
      }, { // Secondary yAxis
        title: {
          text: 'Repaired systems count'
        },
        allowDecimals: false,
        opposite: true
      }],
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px;">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color};">{point.name}</span>: ' + '<b>{point.y}</b> {point.myData}<br/>'
      },
      drilldown: {
        series: []
      }
    },

    chartOptions: _ember["default"].computed('chartOptions_', function () {
      var opt = this.get('chartOptions_');
      opt["groups"] = this.get('aggregatedDataBySite');
      opt["drilledDown"] = false;
      return opt;
    }),

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    })

  });
});