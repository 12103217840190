define('op/pods/mdm-device-detail/device-battery-history/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend({
    chartBatteryData: _ember['default'].computed('model.mdmbatteryhistory', function () {
      var chartBatteryData = [];
      var mdmbatteryhistory = this.get('model.mdmbatteryhistory');
      var batteryStateData = {
        name: 'Battery states',
        color: '#258BE2',
        data: []
      };
      mdmbatteryhistory.forEach(function (model) {
        var allStates = new Array(2);
        var currentDate = new Date(model.get('TimeStamp'));
        allStates[0] = currentDate.getTime();
        allStates[1] = model.get('Value');
        batteryStateData.data.pushObject(allStates);
      });
      chartBatteryData.pushObject(batteryStateData);
      return chartBatteryData;
    }),
    minBatteryData: _ember['default'].computed('model.mdmbatteryhistory', function () {
      var allBatteries = this.get('model.mdmbatteryhistory').toArray().mapBy('Value');
      return Math.min.apply(Math, _toConsumableArray(allBatteries));
    }),

    chartOptions_: {
      chart: {
        type: 'area'

      },
      rangeSelector: {
        buttons: [{
          type: 'hour',
          count: 1,
          text: '1h'
        }, {
          type: 'day',
          count: 1,
          text: '1d'
        }, {
          type: 'day',
          count: 3,
          text: '3d'
        }, {
          type: 'week',
          count: 1,
          text: '1w'
        }, {
          type: 'week',
          count: 2,
          text: '2w'
        }, {
          type: 'month',
          count: 1,
          text: '1m',
          dataGrouping: {
            forced: true,
            units: [['day', [1]]]
          }
        }, {
          type: 'all',
          count: 1,
          text: 'all',
          dataGrouping: {
            forced: true,
            units: [['week', [1]]]
          }
        }],
        selected: 2,
        inputEnabled: false
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{y:.0f}'
          },
          color: '#258BE2'
        },
        color: '#258BE2',
        step: true
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime'

      },
      yAxis: {
        title: {
          text: 'Battery state'
        },
        allowDecimals: false,
        //			minRange: 3,
        opposite: false,
        min: 0,
        max: 100,

        plotLines: [{
          label: {
            text: 'critical limit', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: -10, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4
          zIndex: 10,
          color: 'red', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          //value: 80, // Value of where the line will appear
          width: 2 // Width of the line
        }, {
          label: {
            text: 'warning limit', // Content of the label.
            align: 'left' },
          // Positioning of the label.
          //x: +110, // Amount of pixels the label will be repositioned according to the alignment.
          //y: 4

          zIndex: 10,
          color: 'yellow', // Color value
          dashStyle: 'solid', // Style of the plot line. Default to solid
          //value: 80, // Value of where the line will appear
          width: 2 // Width of the line
        }]
      },
      tooltip: {
        //xDateFormat: '%Y-%m-%d',
        shared: true,
        valueDecimals: 0
      },
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [[0, 'red'], [1, 'green']]
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0
      }
    },

    chartBatteryOptions: _ember['default'].computed('chartOptions_', 'minBatteryData', function () {
      var deviceData = this.get('model.mdmdevicedata');
      var opt = this.get('chartOptions_');
      //opt.yAxis.floor = this.get('minBatteryData') - 2;
      opt.yAxis.plotLines[0].value = deviceData.get('limitBatteryCritical');
      opt.yAxis.plotLines[1].value = deviceData.get('limitBatteryWarning');
      return opt;
    })
  });
});