define("op/pods/incident/controller", ["exports", "ember", "op/tools/checkHelper"], function (exports, _ember, _opToolsCheckHelper) {
  var Controller = _ember["default"].Controller;
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  var arrayDefinedWithData = _opToolsCheckHelper["default"].arrayDefinedWithData;
  var definedWithData = _opToolsCheckHelper["default"].definedWithData;
  exports["default"] = Controller.extend({
    updates: service('updates'),

    userproperties: service('userproperties'),

    disabledFilterProjects: false,
    disabledFilterCountries: false,
    disabledFilterSites: false,
    disabledFilterComponentKinds: false,
    disabledFilterComponentTypes: false,
    FilterComponentKindsIsVisible: computed('userproperties', function () {
      //let userProperties = this.get('userproperties');
      //if (userProperties.getNameByType("AC-Hide-Cradles",1) === "1") {
      return 'display-none';
      //} else {
      //  return 'input-group';
      //}
    }),
    FilterComponentTypesIsVisible: 'display-none',
    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    detailTabActive: null,
    init: function init() {
      this.set('detailTabActive', true);
      this.get('updates').setLastUpdated('IncidentIsUpdated');
    },
    actions: {
      selectProjects: function selectProjects(projects) {
        this.set('selectedProjects', projects);
        //console.log(this.get('selectedProjects'));
        this.get('selectedCountries').clear();
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.set('filterChanged', 0);
      },
      selectCountries: function selectCountries(countries) {
        this.set('selectedCountries', countries);
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectSites: function selectSites(sites) {
        this.set('selectedSites', sites);
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectComponentKinds: function selectComponentKinds(kinds) {
        this.set('selectedComponentKinds', kinds);
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectComponentTypes: function selectComponentTypes(types) {
        this.set('selectedComponentTypes', types);
        this.incrementProperty('filterChanged');
      },
      filterGrid: function filterGrid(filterProperty, filterValue) {
        this.set('filterProperty', filterProperty);
        this.set('filterValue', filterValue);
      },
      activateDetailTab: function activateDetailTab() {
        this.setProperties({
          detailTabActive: true
        });
      },
      activateAggTab: function activateAggTab() {
        this.setProperties({
          detailTabActive: false
        });
      }

    },
    siteIds: computed.mapBy('model.incidents', 'SiteId'),
    uniqueSites: computed.uniq('siteIds'),

    incidentData: computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'IncidentIsUpdated', 'model.incidents', function () {
      var model = this.get('model.incidents');

      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      //const selectedComponentKinds = this.get('selectedComponentKinds');
      //const selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = model.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.get('SiteId'));
        });
        data = data2;
      }
      /*
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return (selectedComponentKinds.includes(item.get('ComponentKindID')));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return (selectedComponentTypes.includes(item.get('ComponentTypeID')));
        });
        data = data2;
      }
      */

      //this.sendAction('onIncidentDataChanged', data);
      return data;
    }),
    ticketTypeIds: computed.mapBy('model.incidents', 'TicketTypeGroupId'),
    uniqueTicketTypeIds: computed.uniq('ticketTypeIds'),

    mappedTypes: computed('incidentData', 'uniqueTicketTypeIds', function () {
      var data = this.get('incidentData');
      var uniqueTicketTypeIds = this.get('uniqueTicketTypeIds');
      var mappedData = {};
      uniqueTicketTypeIds.forEach(function (typeId) {
        var filteredData = data.findBy('TicketTypeGroupId', typeId);
        if (definedWithData(filteredData)) {
          mappedData[typeId] = filteredData.data.TicketTypeGroup;
        }
      });
      return mappedData;
    }),
    ticketPriorityIds: computed.mapBy('model.incidents', 'TicketPriorityGroupId'),
    uniqueTicketPriorityIds: computed.uniq('ticketPriorityIds'),

    mappedPriorities: computed('incidentData', 'uniqueTicketPriorityIds', function () {
      var data = this.get('incidentData');
      var uniqueTicketPriorityIds = this.get('uniqueTicketPriorityIds');
      var mappedData = {};
      uniqueTicketPriorityIds.forEach(function (id) {
        var filteredData = data.findBy('TicketPriorityGroupId', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.TicketPriorityGroup;
        }
      });
      return mappedData;
    }),
    ticketStateIds: computed.mapBy('model.incidents', 'TicketStateId'),
    uniqueTicketStateIds: computed.uniq('ticketStateIds'),

    mappedStates: computed('incidentData', 'uniqueTicketStateIds', function () {
      var data = this.get('incidentData');
      var uniqueTicketStateIds = this.get('uniqueTicketStateIds');
      var mappedData = {};
      uniqueTicketStateIds.forEach(function (id) {
        var filteredData = data.findBy('TicketStateId', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.TicketState;
        }
      });
      return mappedData;
    }),
    isDetailTabActive: _ember["default"].computed('detailTabActive', function () {
      return this.get('detailTabActive');
    })

  });
});