define('op/helpers/await', ['exports', 'ember-promise-helpers/helpers/await'], function (exports, _emberPromiseHelpersHelpersAwait) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersAwait['default'];
    }
  });
  Object.defineProperty(exports, 'await', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersAwait.await;
    }
  });
});