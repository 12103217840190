define("op/transforms/number-null-to-0", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized || Number(0);
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});