define('op/models/devicepool', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    ProjectName: _emberData['default'].attr(),
    ProjectId: _emberData['default'].attr('number'),
    ProjectFamily: _emberData['default'].attr(),
    ProjectFamilyId: _emberData['default'].attr('number'),
    ComponentKindName: _emberData['default'].attr(),
    ComponentKindID: _emberData['default'].attr('number'),
    SystemNumber: _emberData['default'].attr(),
    Snr: _emberData['default'].attr(),
    SnrId: _emberData['default'].attr('number'),
    SysComponentStatusId: _emberData['default'].attr(),
    ComponentStatusID: _emberData['default'].attr('number'),
    ComponentStatusText: _emberData['default'].attr(),
    ComponentStatusGroup: _emberData['default'].attr(),
    ComponentStatusGroupID: _emberData['default'].attr('number'),
    ComponentStatusTime: _emberData['default'].attr(),
    ComponentTypeNumber: _emberData['default'].attr(),
    ComponentTypeName: _emberData['default'].attr(),
    ComponentTypeID: _emberData['default'].attr('number'),
    AsPhoneIdentifier: _emberData['default'].attr(),
    AsPhoneNumber: _emberData['default'].attr(),
    AsKDReferenz: _emberData['default'].attr(),
    AsImei: _emberData['default'].attr(),
    AsMac: _emberData['default'].attr()

  });
});