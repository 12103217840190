define('op/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    ProjectName: _emberData['default'].attr(),
    ProjectFamily: _emberData['default'].attr(),
    SiteName: _emberData['default'].attr(),
    SiteCCode: _emberData['default'].attr(),
    SiteCountry: _emberData['default'].attr(),
    ProjectId: _emberData['default'].attr('number'),
    ProjectFamilyId: _emberData['default'].attr('number'),
    SiteIdDetail: _emberData['default'].attr('number'),
    SiteId: _emberData['default'].attr('number')
  });
});