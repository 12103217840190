define("op/pods/asset-sites/controller", ["exports", "ember", "op/themes/default-theme"], function (exports, _ember, _opThemesDefaultTheme) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend({
    assetController: _ember["default"].inject.controller('asset'),

    userproperties: service('userproperties'),

    disabledFilterProjects: true,
    disabledFilterCountries: true,
    disabledFilterSites: true,
    disabledFilterComponentKinds: true,
    disabledFilterComponentTypes: true,
    FilterComponentKindsIsVisible: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'input-group';
      }
    }),
    FilterComponentTypesIsVisible: 'input-group',
    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    filterChanged: 0,

    actions: {
      selectProjects: function selectProjects(projects) {
        this.set('selectedProjects', projects);
      },
      selectCountries: function selectCountries(countries) {
        this.set('selectedCountries', countries);
      },
      selectSites: function selectSites(sites) {
        this.set('selectedSites', sites);
      },
      selectComponentKinds: function selectComponentKinds(kinds) {
        this.set('selectedComponentKinds', kinds);
      },
      selectComponentTypes: function selectComponentTypes(types) {
        this.set('selectedComponentTypes', types);
      }
    },

    selectedProject: _ember["default"].computed.reads('assetController.selectedProject'),
    allSiteData: _ember["default"].computed.reads('assetController.aggregatedData'),
    allDeviceData: _ember["default"].computed.reads('assetController.deviceData2'),
    siteId: _ember["default"].computed('model.siteId', function () {
      return this.get('model.siteId');
    }),
    siteData: _ember["default"].computed('allSiteData', function () {
      var siteId = parseInt(this.get('siteId'));
      var sites = this.get('allSiteData');
      var data = null;
      sites.some(function (item) {
        if (item.SiteId === siteId) {
          data = item;
          return true;
        } else {
          return false;
        }
      });
      return data;
    }),
    deviceData: _ember["default"].computed('allDeviceData', function () {
      var siteId = parseInt(this.get('siteId'));
      var devices = this.get('allDeviceData');
      return devices.filterBy('SiteId', siteId);
    }),
    chartDeviceData: _ember["default"].computed('model.sitehistories', function () {
      var chartDeviceData = [];
      var sitehistories = this.get('model.sitehistories');
      var firstEntry = sitehistories.get('firstObject');
      var currentDate = new Date(firstEntry.get('AvailabilityDay'));
      var allDevicesData = {
        name: 'all Devices',
        data: []
      };
      var availableDevicesData = {
        name: 'available Devices',
        data: []
      };
      var allDevices = new Array(2);
      var availableDevices = new Array(2);
      allDevices[0] = currentDate.getTime();
      allDevices[1] = firstEntry.get('AllDevices');
      availableDevices[0] = currentDate.getTime();
      availableDevices[1] = firstEntry.get('DevicesAvailable');
      var first = true;
      sitehistories.forEach(function (model) {
        var date = new Date(model.get('AvailabilityDay'));
        //console.log('new date - day:' + date.getDate() + ', date: ' + date.getTime());
        if (date.getDate() !== currentDate.getDate()) {
          allDevicesData.data.pushObject(allDevices);
          availableDevicesData.data.pushObject(availableDevices);

          currentDate = date;
          //console.log('new date - day:' + currentDate.getDate() + ', date: ' + currentDate.getTime());
          allDevices = new Array(2);
          availableDevices = new Array(2);
          allDevices[0] = currentDate.getTime();
          allDevices[1] = model.get('AllDevices');
          availableDevices[0] = currentDate.getTime();
          availableDevices[1] = model.get('DevicesAvailable');
        } else {
          if (!first) {
            allDevices[1] += model.get('AllDevices');
            availableDevices[1] += model.get('DevicesAvailable');
          } else {
            first = false;
          }
        }
      });
      allDevicesData.data.pushObject(allDevices);
      availableDevicesData.data.pushObject(availableDevices);
      chartDeviceData.pushObject(allDevicesData);
      chartDeviceData.pushObject(availableDevicesData);
      return chartDeviceData;
    }),

    chartCradleData: _ember["default"].computed('model.sitehistories', function () {
      var chartCradleData = [];
      var siteHistories = this.get('model.sitehistories');
      var firstEntry = siteHistories.get('firstObject');
      var currentDate = new Date(firstEntry.get('AvailabilityDay'));
      var allCradlesData = {
        name: 'all Cradles',
        data: []
      };
      var availableCradlesData = {
        name: 'available Cradles',
        data: []
      };
      var allCradles = new Array(2);
      var availableCradles = new Array(2);
      allCradles[0] = currentDate.getTime();
      allCradles[1] = firstEntry.get('AllCradles');
      availableCradles[0] = currentDate.getTime();
      availableCradles[1] = firstEntry.get('CradlesAvailable');
      var first = true;
      siteHistories.forEach(function (model) {
        var date = new Date(model.get('AvailabilityDay'));
        //console.log('new date - day:' + date.getDate() + ', date: ' + date.getTime());
        if (date.getDate() !== currentDate.getDate()) {
          allCradlesData.data.pushObject(allCradles);
          availableCradlesData.data.pushObject(availableCradles);

          currentDate = date;
          //console.log('new date - day:' + currentDate.getDate() + ', date: ' + currentDate.getTime());
          allCradles = new Array(2);
          availableCradles = new Array(2);
          allCradles[0] = currentDate.getTime();
          allCradles[1] = model.get('AllCradles');
          availableCradles[0] = currentDate.getTime();
          availableCradles[1] = model.get('CradlesAvailable');
        } else {
          if (!first) {
            allCradles[1] += model.get('AllCradles');
            availableCradles[1] += model.get('CradlesAvailable');
          } else {
            first = false;
          }
        }
      });
      allCradlesData.data.pushObject(allCradles);
      availableCradlesData.data.pushObject(availableCradles);
      chartCradleData.pushObject(allCradlesData);
      chartCradleData.pushObject(availableCradlesData);
      return chartCradleData;
    }),

    minDevicesData: _ember["default"].computed('model.sitehistories', function () {
      var allDevices = this.get('model.sitehistories').toArray().mapBy('AllDevices').uniq('AllDevices').filter(function (item) {
        return item !== null ? true : false;
      });
      var availableDevices = this.get('model.sitehistories').toArray().mapBy('DevicesAvailable').uniq('DevicesAvailable').filter(function (item) {
        return item !== null ? true : false;
      });
      var minDevicesData = Math.min(Math.min.apply(Math, _toConsumableArray(allDevices)), Math.min.apply(Math, _toConsumableArray(availableDevices)));
      return minDevicesData;
    }),
    minCradleData: _ember["default"].computed('model.sitehistories', function () {
      var allCradles = this.get('model.sitehistories').toArray().mapBy('AllCradles').uniq('AllCradles').filter(function (item) {
        return item !== null ? true : false;
      });
      var availableCradles = this.get('model.sitehistories').toArray().mapBy('CradlesAvailable').uniq('CradlesAvailable').filter(function (item) {
        return item !== null ? true : false;
      });
      var minCradleData = Math.min(Math.min.apply(Math, _toConsumableArray(allCradles)), Math.min.apply(Math, _toConsumableArray(availableCradles)));
      return minCradleData;
    }),

    chartDeviceOptions_: {
      chart: {
        type: 'area'
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true
          }
        },
        step: true
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime'

      },
      yAxis: {
        title: {
          text: 'Devices'
        },
        allowDecimals: false,
        minRange: 3
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        shared: true
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0
      }
    },
    chartDeviceOptions: _ember["default"].computed('chartDeviceOptions_', 'minDevicesData', function () {
      var opt = this.get('chartDeviceOptions_');
      opt.yAxis.floor = this.get('minDevicesData') - 2;
      return opt;
    }),
    chartCradleOptions_: _ember["default"].Object.create({
      chart: {
        type: 'area'
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true
          }
        },
        step: true
      },
      title: {
        text: ''
      },

      xAxis: {
        type: 'datetime',
        staggerLines: 2

      },

      yAxis: {
        title: {
          text: 'Cradles'
        },
        allowDecimals: false,
        minRange: 3
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0
      }
    }),
    chartCradleOptions: _ember["default"].computed('chartCradleOptions_', 'minCradleData', function () {
      var opt = this.get('chartCradleOptions_');
      opt.yAxis.floor = this.get('minCradleData') - 2;
      return opt;
    }),
    theme: _opThemesDefaultTheme["default"],

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    }),
    isRepairInProject: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Asset-KnobRepairOutsideDepot", 0) === "1") {
        return true;
      } else {
        return false;
      }
    }),

    isCradleActive_smallbox: computed('isCradleActive', function () {
      if (this.get('isCradleActive')) {
        return 'small-box bg-box-filter-color';
      } else {
        return 'display-none';
      }
    }),

    isRepairInProject_flex: computed('isRepairInProject', function () {
      if (this.get('isRepairInProject')) {
        return 'flex align-center gaps column';
      } else {
        return 'display-none';
      }
    }),
    isRepairInDepot_flex: computed('userproperties', function () {
      if (this.get('isRepairInProject')) {
        return 'display-none';
      } else {
        return 'flex align-center gaps column';
      }
    }),
    isRepairInProject_smallbox: computed('isRepairInProject', function () {
      if (this.get('isRepairInProject')) {
        return 'small-box bg-box-filter-color';
      } else {
        return 'display-none';
      }
    }),
    isRepairInProjectCradleActive_smallbox: computed('isRepairInProject', 'isCradleActive', function () {
      if (this.get('isRepairInProject') && this.get('isCradleActive')) {
        return 'small-box bg-box-filter-color';
      } else {
        return 'display-none';
      }
    }),

    isCradleActive_box: computed('isCradleActive', function () {
      if (this.get('isCradleActive')) {
        return 'box';
      } else {
        return 'display-none';
      }
    })

  });
});