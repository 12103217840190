define('op/components/device-table', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    toolPanelVisible: false,
    filter: null,
    actions: {
      toggleToolPanel: function toggleToolPanel() {
        this.toggleProperty('toolPanelVisible');
        this.get('deviceGrid').api.showToolPanel(this.get('toolPanelVisible'));
      },
      autoComplete: function autoComplete() {
        this.get('deviceGrid').api.setQuickFilter(this.get('filter'));
      }
    },
    deviceGrid: computed('model', function () {
      var _this = this;

      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Component",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Kind", field: "ComponentKindName" }, { headerName: "TypeNr", field: "ComponentTypeNumber", hide: true }, { headerName: "Type", field: "ComponentTypeName" }, { headerName: "Status", field: "ComponentStatusText" }, { headerName: "Status Group", field: "ComponentStatusGroup", hide: false }, { headerName: "Time", field: "ComponentStatusTime", hide: false }]
      }, { headerName: "System Number", field: "SystemNumber" }, { headerName: "Serial Number", field: "Snr" }, { headerName: "Serial Number Id", field: "SnrId", hide: true }, {
        headerName: "Device",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "ICCID", field: "AsPhoneIdentifier" }, { headerName: "Phone Number", field: "AsPhoneNumber" }, { headerName: "KD Reference Number", field: "AsKDReferenz", hide: true }, { headerName: "IMEI", field: "AsImei" }, { headerName: "MAC", field: "AsMac" }]
      }];

      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: false,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this.createDeviceRowData(_this.get('model'));
        },
        onModelUpdated: function onModelUpdated() {
          _this.updateDeviceTotals();
        }
      };
    }),
    createDeviceRowData: function createDeviceRowData(model) {
      if (!model) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      var aggregatedArray = [];

      model.forEach(function (data) {
        aggregatedArray.push(data);
      });

      this.get('deviceGrid').api.setRowData(aggregatedArray.toArray());
      this.get('deviceGrid').api.sizeColumnsToFit();
      this.set('deviceGridRowCount', this.get('deviceGrid').api.getModel().getRowCount());
      this.set('deviceGridReady', true);
    },
    updateDeviceTotals: function updateDeviceTotals() {
      if (this.get('deviceGridReady')) {
        this.set('deviceGridRowCount', this.get('deviceGrid').api.getModel().getRowCount());
      } else {
        this.set('deviceGridRowCount', 0);
      }
      this.incrementProperty('deviceGridEvent');
    }
  });
});