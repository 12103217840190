define('op/pods/repair/model', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    ProjectId: attr('number'),
    ProjectName: attr(),
    ProjectFamilyId: attr('number'),
    ProjectFamily: attr(),
    SiteId: attr('number'),
    SiteIdDetail: attr('number'),
    SiteName: attr(),
    SiteCCode: attr(),
    SiteCountry: attr(),
    SiteIdentifier: attr(),
    ComponentTypeNumber: attr(),
    ComponentTypeName: attr(),
    ComponentTypeID: attr('number'),
    ComponentKindName: attr(),
    ComponentKindID: attr('number'),
    ComponentKindGroup: attr(),
    TicketNumber: attr(),
    OrderNumber: attr(),
    InRecordingDate: attr(),
    OutRecordingDate: attr(),
    SystemNumber: attr('number'),
    ///#52037 UPS Tracking Nummer
    TrackingNummer: attr(),
    InSNR: attr(),
    OutSNR: attr(),
    RMANumber: attr(),
    OrderState: attr(),
    OrderStateGroup: attr(),
    ComponentStatusText: _emberData['default'].attr(),
    ComponentStatusGroup: _emberData['default'].attr(),
    SNR_Exchange: _emberData['default'].attr('number')
  });
});