define('op/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: service('session'),
    modules: service('modules'),
    userproperties: service('userproperties'),
    updates: service('updates'),
    componenttypes: service('componenttypes'),
    title: 'Operational Services',

    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);
      this._loadModules()['catch'](function () {
        _this.get('session').invalidate();
      });
      this._loadUserProperties()['catch'](function () {
        _this.get('session').invalidate();
      });
      this._loadProjectsAndSites()['catch'](function () {
        _this.get('session').invalidate();
      });
      this._loadUpdates()['catch'](function () {
        _this.get('session').invalidate();
      });
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    },
    _loadModules: function _loadModules() {
      return this.get('modules').load();
    },
    _loadUserProperties: function _loadUserProperties() {
      return this.get('userproperties').load();
    },
    _loadUpdates: function _loadUpdates() {
      return this.get('updates').load();
    },
    _loadProjectsAndSites: function _loadProjectsAndSites() {
      return this.get('componenttypes').load();
    }

  });
});
// app/routes/application.js