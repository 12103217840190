define("op/pods/asset/asset-overview-panel/component", ["exports", "ember", "op/pods/asset/mixins/reset-asset-properties"], function (exports, _ember, _opPodsAssetMixinsResetAssetProperties) {
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Component.extend(_opPodsAssetMixinsResetAssetProperties["default"], {

    //modules: service('modules'),
    userproperties: service('userproperties'),

    filterChanged: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.incrementProperty('filterChanged');
    },
    actions: {
      filterDevicesInPool: function filterDevicesInPool() {
        this.resetProperties();
        this.toggleFilterProperty('filterDevicesInPool');
        this.setDevicePoolProperty(true);
        this.attrs.doFilter('filterDevicesInPool', this);
      },
      filterDevicesInPoolOthers: function filterDevicesInPoolOthers() {
        this.resetProperties();
        this.toggleFilterProperty('filterDevicesInPoolOthers');
        this.setDevicePoolOthersProperty(true);
        this.attrs.doFilter('filterDevicesInPoolOthers', this);
      },
      filterMobileDevices: function filterMobileDevices() {
        this.resetProperties();
        this.toggleFilterProperty('filterMobileDevices');
        this.setMobileDevicesProperty(true);
        this.attrs.doFilter('filterMobileDevices', this);
      },
      filterMobileDevicesSet: function filterMobileDevicesSet() {
        this.resetProperties();
        this.setMobileDevicesProperty(true);
        this.toggleFilterProperty('filterMobileDevicesSet');
        this.attrs.doFilter('filterMobileDevicesSet', this);
      },
      filterMobileDevicesNotSet: function filterMobileDevicesNotSet() {
        this.resetProperties();
        this.setMobileDevicesProperty(true);
        this.toggleFilterProperty('filterMobileDevicesNotSet');
        this.attrs.doFilter('filterMobileDevicesNotSet', this);
      },
      filterDepots: function filterDepots() {
        this.resetProperties();
        this.toggleFilterProperty('filterDepots');
        this.attrs.doFilter('filterDepots', this);
      },
      filterDepotsOk: function filterDepotsOk() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDevicesProperties();
        this.resetCradlesProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsOk');
        this.attrs.doFilter('filterDepotsOk', this);
      },
      filterDepotsCritical: function filterDepotsCritical() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDevicesProperties();
        this.resetCradlesProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsCritical');
        this.attrs.doFilter('filterDepotsCritical', this);
      },
      filterDepotsWarning: function filterDepotsWarning() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDevicesProperties();
        this.resetCradlesProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsWarning');
        this.attrs.doFilter('filterDepotsWarning', this);
      },
      filterDevicesAll: function filterDevicesAll() {
        this.resetProperties();
        this.toggleFilterProperty('filterDevicesAll');
        this.attrs.doFilter('filterDevicesAll', this);
      },
      filterDevicesAvailable: function filterDevicesAvailable() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetCradlesProperties();
        this.setDeviceProperty(true);
        this.toggleFilterProperty('filterDevicesAvailable');
        this.attrs.doFilter('filterDevicesAvailable', this);
      },
      filterDevicesCalled: function filterDevicesCalled() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetCradlesProperties();
        this.setDeviceProperty(true);
        if (this.get('isRepairInProject')) {
          this.resetRepairInProjectProperties();
        }
        this.toggleFilterProperty('filterDevicesCalled');
        this.attrs.doFilter('filterDevicesCalled', this);
      },
      filterDevicesInRepair: function filterDevicesInRepair() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetCradlesProperties();
        if (this.get('isRepairInProject')) {
          this.resetDevicesProperties();
        } else {
          this.setDeviceProperty(true);
        }
        this.toggleFilterProperty('filterDevicesInRepair');
        this.attrs.doFilter('filterDevicesInRepair', this);
      },
      filterDevicesOthers: function filterDevicesOthers() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetCradlesProperties();
        if (this.get('isRepairInProject')) {
          this.resetRepairInProjectProperties();
        }
        this.setDeviceProperty(true);
        this.toggleFilterProperty('filterDevicesOthers');
        this.attrs.doFilter('filterDevicesOthers', this);
      },
      filterCradlesAll: function filterCradlesAll() {
        this.resetProperties();
        this.toggleFilterProperty('filterCradlesAll');
        this.attrs.doFilter('filterCradlesAll', this);
      },
      filterCradlesAvailable: function filterCradlesAvailable() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetDevicesProperties();
        this.setCradleProperty(true);
        this.toggleFilterProperty('filterCradlesAvailable');
        this.attrs.doFilter('filterCradlesAvailable', this);
      },
      filterCradlesCalled: function filterCradlesCalled() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetDevicesProperties();
        if (this.get('isRepairInProject')) {
          this.resetRepairInProjectProperties();
        }
        this.setCradleProperty(true);
        this.toggleFilterProperty('filterCradlesCalled');
        this.attrs.doFilter('filterCradlesCalled', this);
      },
      filterCradlesInRepair: function filterCradlesInRepair() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetDevicesProperties();
        if (this.get('isRepairInProject')) {
          this.resetCradlesProperties();
        } else {
          this.setCradleProperty(true);
        }
        this.toggleFilterProperty('filterCradlesInRepair');
        this.attrs.doFilter('filterCradlesInRepair', this);
      },
      filterCradlesOthers: function filterCradlesOthers() {
        this.resetDevicesInPoolProperties();
        this.resetDevicesInPoolOthersProperties();
        this.resetMobileDevicesProperties();
        this.resetDepotsProperties();
        this.resetDevicesProperties();
        if (this.get('isRepairInProject')) {
          this.resetRepairInProjectProperties();
        }
        this.setCradleProperty(true);
        this.toggleFilterProperty('filterCradlesOthers');
        this.attrs.doFilter('filterCradlesOthers', this);
      }
    },

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    }),
    isCradleActive_smallbox: computed('isCradleActive', function () {
      if (this.get('isCradleActive')) {
        return 'small-box bg-box-overview-color';
      } else {
        return 'display-none';
      }
    }),

    isRepairInProject: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Asset-KnobRepairOutsideDepot", 0) === "1") {
        return true;
      } else {
        return false;
      }
    }),
    isRepairInProject_flex: computed('isRepairInProject', function () {
      if (this.get('isRepairInProject')) {
        return 'bg-box-filter-color hover_over';
      } else {
        return 'display-none';
      }
    }),
    isRepairInDepot_flex: computed('userproperties', function () {
      if (this.get('isRepairInProject')) {
        return 'display-none';
      } else {
        return 'bg-box-filter-color hover_over';
      }
    }),
    isRepairInProjectCradleActive_smallbox: computed('isRepairInProject', 'isCradleActive', function () {
      if (this.get('isRepairInProject') && this.get('isCradleActive')) {
        return 'small-box bg-box-overview-color';
      } else {
        return 'display-none';
      }
    }),

    isProjectPoolActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-ProjectPool", 1) === "1" || userProperties.getNameByType("AC-Show-ProjectPool", 1) === "cadis") {
        return true;
      } else {
        return false;
      }
    }),
    isProjectPoolActive_flex: computed('isProjectPoolActive', function () {
      if (this.get('isProjectPoolActive')) {
        return 'bg-box-filter-color hover_over';
      } else {
        return 'display-none';
      }
    }),

    isProjectPoolOthersActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-ProjectOthers", 1) === "1" || userProperties.getNameByType("AC-Show-ProjectOthers", 1) === "cadis") {
        return true;
      } else {
        return false;
      }
    }),
    isProjectPoolOthersActive_flex: computed('isProjectPoolOthersActive', function () {
      if (this.get('isProjectPoolOthersActive')) {
        return 'bg-box-filter-color hover_over';
      } else {
        return 'display-none';
      }
    }),

    isRepairInProjectOrProjectPoolActive_smallbox: computed('isRepairInProject', 'isProjectPoolActive', 'isProjectPoolOthersActive', function () {
      if (this.get('isRepairInProject') || this.get('isProjectPoolActive') || this.get('isProjectPoolOthersActive')) {
        return 'small-box bg-box-overview-color';
      } else {
        return 'display-none';
      }
    }),
    ///

    isDeviceIsMDMActive_smallbox: computed('modules', function () {
      //let modules = this.get('modules');
      //let myModules = this.get('modules').load();
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-DeviceIsMDM", 1) === "1" || userProperties.getNameByType("AC-Show-DeviceIsMDM", 1) === "cadis") {
        return 'small-box bg-box-overview-color';
      } else {
        return 'display-none';
      }
    }),

    ///

    ovDepots: _ember["default"].computed('aggData', function () {
      console.log('aggData.length: ' + this.get('aggData').length);
      return this.get('aggData').length;
    }),

    ovDepotsOk: _ember["default"].computed('aggData', function () {
      var sumDepotsOk = 0;
      this.get('aggData').forEach(function (record) {
        var DevicesSystems = record.get('DevicesSystems');
        var CradlesSystems = record.get('CradlesSystems');
        var devicesAvailable = record.get('DevicesAvailable');
        var cradlesAvailable = record.get('CradlesAvailable');
        var devicesOk = false;
        var cradlesOk = false;
        var percentDevicesAvailable = 100;
        if (DevicesSystems > 0) {
          percentDevicesAvailable = devicesAvailable * 100 / DevicesSystems;
        }
        if (percentDevicesAvailable >= 97) {
          devicesOk = true;
        }
        var percentCradlesAvailable = 100;
        if (CradlesSystems > 0) {
          percentCradlesAvailable = cradlesAvailable * 100 / CradlesSystems;
        }
        if (percentCradlesAvailable >= 97) {
          cradlesOk = true;
        }
        if (devicesOk && cradlesOk) {
          sumDepotsOk += 1;
        }
      });

      //console.log('depot ok: ' + sumDepotsOk);
      return sumDepotsOk;
    }),
    ovPercentDepotsOk: _ember["default"].computed('ovDepots', 'ovDepotsOk', function () {
      var sumAllDepots = this.get('ovDepots');
      var sumDepotsOk = this.get('ovDepotsOk');
      if (sumAllDepots > 0) {
        return (sumDepotsOk * 100 / sumAllDepots).toFixed(0);
      } else {
        return 0;
      }
    }),

    ovDepotsWarnings: _ember["default"].computed('ovDepots', 'ovDepotsOk', 'ovDepotsCritical', function () {
      var sumAllDepots = this.get('ovDepots');
      var sumDepotsOk = this.get('ovDepotsOk');
      var sumDepotsCritical = this.get('ovDepotsCritical');
      console.log('depotsWarning: ' + (sumAllDepots - sumDepotsCritical - sumDepotsOk));
      return sumAllDepots - sumDepotsCritical - sumDepotsOk;
    }),
    ovPercentDepotsWarnings: _ember["default"].computed('ovDepots', 'ovDepotsWarnings', function () {
      var sumDepotsWithWarning = this.get('ovDepotsWarnings');
      var allDepots = this.get('ovDepots');
      if (allDepots > 0) {
        return (sumDepotsWithWarning * 100 / allDepots).toFixed(1);
      } else {
        return 0;
      }
    }),

    ovDepotsCritical: _ember["default"].computed('aggData', function () {
      var sumDepotsCritical = 0;
      this.get('aggData').forEach(function (record) {
        var DevicesSystems = record.get('DevicesSystems');
        var CradlesSystems = record.get('CradlesSystems');
        var devicesAvailable = record.get('DevicesAvailable');
        var cradlesAvailable = record.get('CradlesAvailable');
        var devicesCritical = false;
        var cradlesCritical = false;

        if (DevicesSystems > 0) {
          var percentDevicesAvailable = devicesAvailable * 100 / DevicesSystems;

          if (percentDevicesAvailable < 90) {
            devicesCritical = true;
          }
        }
        if (CradlesSystems > 0) {
          var percentCradlesAvailable = cradlesAvailable * 100 / CradlesSystems;

          if (percentCradlesAvailable < 90) {
            cradlesCritical = true;
          }
        }
        if (devicesCritical || cradlesCritical) {
          sumDepotsCritical += 1;
        }
      });

      //console.log('depot ok: ' + sumDepotsCritical);
      return sumDepotsCritical;
    }),
    ovPercentDepotsCritical: _ember["default"].computed('ovDepots', 'ovDepotsCritical', function () {
      var sumDepotsCritical = this.get('ovDepotsCritical');
      var allDepots = this.get('ovDepots');
      if (allDepots > 0) {
        return (sumDepotsCritical * 100 / allDepots).toFixed(1);
      } else {
        return 0;
      }
    }),

    ///

    /* --> ovDevicesSumWithoutSiteFilter  
      ovDevicesSum: Ember.computed('isRepairInProject', 'ovDevicesAll', 'ovDevicesInRepair', 'ovDevicesInPool', 'ovDevicesInPoolOthers', function () {
        var devicesSum = 0;
        if (this.get('isRepairInProject')) {
          devicesSum = this.get('ovDevicesAll') + this.get('ovDevicesInRepair') + this.get('ovDevicesInPool') + this.get('ovDevicesInPoolOthers');
        } else {
          devicesSum = this.get('ovDevicesAll') + this.get('ovDevicesInPool') + this.get('ovDevicesInPoolOthers');
        }
        return devicesSum;
      }),
    */
    ovDevicesInPool: _ember["default"].computed('aggPoolData', function () {
      //var devicesInPool = this.get('aggPoolData').toArray().length;
      var devicesInPool = 0;

      if (this.get('isProjectPoolActive')) {

        this.get('aggPoolData').forEach(function (data) {
          //#52044 AGEDISS cradles + SIM // nur PDA
          //4,  --'in pool', 2, --'in repair' 99)  --'other' (Im Rollout, gestohlen, Verlust, Totalschaden, Verlust nicht ersetzt, Clearing)     
          if (data.data.ComponentKindID === 1 && (data.data.ComponentStatusGroupID === 4 || data.data.ComponentStatusGroupID === 2)) {
            devicesInPool = devicesInPool + 1;
          }
        });
      }
      return devicesInPool;
    }),
    ovPercentDevicesInPool: _ember["default"].computed('ovDevicesSumWithoutSiteFilter', 'ovDevicesInPool', function () {
      var sumDevicesSum = this.get('ovDevicesSumWithoutSiteFilter');
      var sumDevicesInPool = this.get('ovDevicesInPool');
      if (sumDevicesSum > 0) {
        return (sumDevicesInPool * 100 / sumDevicesSum).toFixed(0);
      } else {
        return 100;
      }
    }),

    ovDevicesInPoolOthers: _ember["default"].computed('aggPoolData', function () {
      //var devicesInPoolOthers = this.get('aggPoolData').toArray().length;
      var devicesInPoolOthers = 0;

      if (this.get('isProjectPoolOthersActive')) {
        this.get('aggPoolData').forEach(function (data) {
          //#52044 AGEDISS cradles + SIM // nur PDA
          //4,  --'in pool', 2, --'in repair' 99)  --'other' (Im Rollout, gestohlen, Verlust, Totalschaden, Verlust nicht ersetzt, Clearing)     
          if (data.data.ComponentKindID === 1 && data.data.ComponentStatusGroupID === 99) {
            devicesInPoolOthers = devicesInPoolOthers + 1;
          }
        });
      }
      return devicesInPoolOthers;
    }),
    ovPercentDevicesInPoolOthers: _ember["default"].computed('ovDevicesSumWithoutSiteFilter', 'ovDevicesInPoolOthers', function () {
      var sumDevicesSum = this.get('ovDevicesSumWithoutSiteFilter');
      var sumDevicesInPoolOthers = this.get('ovDevicesInPoolOthers');
      if (sumDevicesSum > 0) {
        return (sumDevicesInPoolOthers * 100 / sumDevicesSum).toFixed(0);
      } else {
        return 100;
      }
    }),

    ///

    ovMobileDevicesSet: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.MobileDevices1Available;
      }, 0);
    }),
    ovPercentMobileDevicesSet: _ember["default"].computed('ovMobileDevicesSet', 'ovDevicesAvailable', function () {
      var sumDevicesSystems = this.get('ovDevicesAvailable');
      if (sumDevicesSystems > 0) {
        return (this.get('ovMobileDevicesSet') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovMobileDevicesNotSet: _ember["default"].computed('ovDevicesAll', 'ovMobileDevicesSet', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.MobileDevices0Available;
      }, 0);
    }),
    ovPercentMobileDevicesNotSet: _ember["default"].computed('ovMobileDevicesSet', 'ovMobileDevicesNotSet', function () {
      var sumDevicesSystems = this.get('ovDevicesAvailable');
      if (sumDevicesSystems > 0) {
        return (this.get('ovMobileDevicesNotSet') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    ///

    ovDevicesSystems: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.DevicesSystems;
      }, 0);
    }),

    ovDevicesAll: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.AllDevices;
      }, 0);
    }),
    ovMaxDevicesAll: _ember["default"].computed('ovDevicesAll', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      var sumDevicesAll = this.get('ovDevicesAll');
      if (sumDevicesSystems > 0 && sumDevicesAll * 100 / sumDevicesSystems > 100) {
        //window.console.log((sumDevicesAll * 100 / sumDevicesSystems).toFixed(0))
        return (sumDevicesAll * 100 / sumDevicesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentDevicesAll: _ember["default"].computed('ovDevicesAll', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      var sumDevicesAll = this.get('ovDevicesAll');
      if (sumDevicesSystems > 0) {
        return (sumDevicesAll * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    ovDevicesAvailable: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.DevicesAvailable;
      }, 0);
    }),
    ovMaxDevicesAvailable: _ember["default"].computed('ovDevicesAvailable', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      var sumAvailableDevices = this.get('ovDevicesAvailable');
      if (sumDevicesSystems > 0 && sumAvailableDevices * 100 / sumDevicesSystems > 100) {
        return (sumAvailableDevices * 100 / sumDevicesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentDevicesAvailable: _ember["default"].computed('ovDevicesAvailable', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      if (sumDevicesSystems > 0) {
        return (this.get('ovDevicesAvailable') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovDevicesCalled: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.DevicesCalled;
      }, 0);
    }),
    ovPercentDevicesCalled: _ember["default"].computed('ovDevicesCalled', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      if (sumDevicesSystems > 0) {
        return (this.get('ovDevicesCalled') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovDevicesInRepair: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.DevicesInRepair;
      }, 0);
    }),
    ovPercentDevicesInRepair: _ember["default"].computed('ovDevicesInRepair', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      if (sumDevicesSystems > 0) {
        return (this.get('ovDevicesInRepair') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovDevicesOthers: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.DevicesOther;
      }, 0);
    }),
    ovPercentOthers: _ember["default"].computed('ovDevicesOthers', 'ovDevicesSystems', function () {
      var sumDevicesSystems = this.get('ovDevicesSystems');
      if (sumDevicesSystems > 0) {
        return (this.get('ovDevicesOthers') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    ovCradlesSum: _ember["default"].computed('isRepairInProject', 'ovCradlesAll', 'ovCradleInRepair', function () {
      if (this.get('isRepairInProject')) {
        return this.get('ovCradlesAll') + this.get('ovCradleInRepair');
      } else {
        return this.get('ovCradlesAll');
      }
    }),

    ovCradlesSystems: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.CradlesSystems;
      }, 0);
    }),

    ovCradlesAll: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.AllCradles;
      }, 0);
    }),
    ovMaxAllCradles: _ember["default"].computed('ovCradlesAll', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      var sumAllCradles = this.get('ovCradlesAll');
      if (sumCradlesSystems > 0 && sumAllCradles * 100 / sumCradlesSystems > 100) {
        return (sumAllCradles * 100 / sumCradlesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentAllCradles: _ember["default"].computed('ovCradlesAll', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradlesAll') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovCradleAvailable: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.CradlesAvailable;
      }, 0);
    }),
    ovMaxCradlesAvailable: _ember["default"].computed('ovCradleAvailable', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      var sumAvailableCradles = this.get('ovCradleAvailable');
      if (sumCradlesSystems > 0 && sumAvailableCradles * 100 / sumCradlesSystems > 100) {
        return (sumAvailableCradles * 100 / sumCradlesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentCradleAvailable: _ember["default"].computed('ovCradleAvailable', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradleAvailable') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovCradleCalled: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.CradlesCalled;
      }, 0);
    }),
    ovPercentCradleCalled: _ember["default"].computed('ovCradleCalled', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradleCalled') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovCradleInRepair: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.CradlesInRepair;
      }, 0);
    }),
    ovPercentCradleInRepair: _ember["default"].computed('ovCradleInRepair', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradleInRepair') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovCradleOthers: _ember["default"].computed('aggData', function () {
      return this.get('aggData').reduce(function (a, b) {
        return a + b.CradlesOther;
      }, 0);
    }),
    ovPercentCradleOthers: _ember["default"].computed('ovCradleOthers', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradleOthers') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    // aggDataWithoutSiteFilter

    ovDevicesSumWithoutSiteFilter: _ember["default"].computed('isRepairInProject', 'ovDevicesAllWithoutSiteFilter', 'ovDevicesInRepairWithoutSiteFilter', 'ovDevicesInPool', 'ovDevicesInPoolOthers', function () {
      var devicesSum = 0;
      if (this.get('isRepairInProject')) {
        devicesSum = this.get('ovDevicesAllWithoutSiteFilter') + this.get('ovDevicesInRepairWithoutSiteFilter') + this.get('ovDevicesInPool') + this.get('ovDevicesInPoolOthers');
      } else {
        devicesSum = this.get('ovDevicesAllWithoutSiteFilter') + this.get('ovDevicesInPool') + this.get('ovDevicesInPoolOthers');
      }
      return devicesSum;
    }),

    ovDevicesSystemsWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      return this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.DevicesSystems;
      }, 0);
    }),
    ovDevicesAllWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      return this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.AllDevices;
      }, 0);
    }),
    ovMaxDevicesAllWithoutSiteFilter: _ember["default"].computed('ovDevicesAllWithoutSiteFilter', 'ovDevicesSystemsWithoutSiteFilter', function () {
      var sumDevicesSystems = this.get('ovDevicesSystemsWithoutSiteFilter');
      var sumDevicesAll = this.get('ovDevicesAllWithoutSiteFilter');
      if (sumDevicesSystems > 0 && sumDevicesAll * 100 / sumDevicesSystems > 100) {
        //window.console.log((sumDevicesAll * 100 / sumDevicesSystems).toFixed(0))
        return (sumDevicesAll * 100 / sumDevicesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentDevicesAllWithoutSiteFilter: _ember["default"].computed('ovDevicesAllWithoutSiteFilter', 'ovDevicesSystemsWithoutSiteFilter', function () {
      var sumDevicesSystems = this.get('ovDevicesSystemsWithoutSiteFilter');
      var sumDevicesAll = this.get('ovDevicesAllWithoutSiteFilter');
      if (sumDevicesSystems > 0) {
        return (sumDevicesAll * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    ovDevicesInRepairWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      var devicesInRepairWithoutSiteFilter = this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.DevicesInRepair;
      }, 0);
      return devicesInRepairWithoutSiteFilter;
    }),
    ovPercentDevicesInRepairWithoutSiteFilter: _ember["default"].computed('ovDevicesInRepairWithoutSiteFilter', 'ovDevicesSystemsWithoutSiteFilter', function () {
      var sumDevicesSystems = this.get('ovDevicesSystemsWithoutSiteFilter');
      if (sumDevicesSystems > 0) {
        return (this.get('ovDevicesInRepairWithoutSiteFilter') * 100 / sumDevicesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),

    ovCradlesSumWithoutSiteFilter: _ember["default"].computed('isRepairInProject', 'ovCradlesAllWithoutSiteFilter', 'ovCradleInRepairWithoutSiteFilter', function () {
      if (this.get('isRepairInProject')) {
        return this.get('ovCradlesAllWithoutSiteFilter') + this.get('ovCradleInRepairWithoutSiteFilter');
      } else {
        return this.get('ovCradlesAllWithoutSiteFilter');
      }
    }),
    ovCradlesSystemsWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      return this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.CradlesSystems;
      }, 0);
    }),

    ovCradlesAllWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      return this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.AllCradles;
      }, 0);
    }),
    ovMaxAllCradlesWithoutSiteFilter: _ember["default"].computed('ovCradlesAllWithoutSiteFilter', 'ovCradlesSystems', function () {
      var sumCradlesSystems = this.get('ovCradlesSystemsWithoutSiteFilter');
      var sumAllCradles = this.get('ovCradlesAllWithoutSiteFilter');
      if (sumCradlesSystems > 0 && sumAllCradles * 100 / sumCradlesSystems > 100) {
        return (sumAllCradles * 100 / sumCradlesSystems).toFixed(0);
      } else {
        return 100;
      }
    }),
    ovPercentAllCradlesWithoutSiteFilter: _ember["default"].computed('ovCradlesAllWithoutSiteFilter', 'ovCradlesSystemsWithoutSiteFilter', function () {
      var sumCradlesSystems = this.get('ovCradlesSystems');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradlesAllWithoutSiteFilter') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovCradleInRepairWithoutSiteFilter: _ember["default"].computed('aggDataWithoutSiteFilter', function () {
      return this.get('aggDataWithoutSiteFilter').reduce(function (a, b) {
        return a + b.CradlesInRepair;
      }, 0);
    }),
    ovPercentCradleInRepairWithoutSiteFilter: _ember["default"].computed('ovCradleInRepairWithoutSiteFilter', 'ovCradlesSystemWithoutSiteFilters', function () {
      var sumCradlesSystems = this.get('ovCradlesSystemsWithoutSiteFilter');
      if (sumCradlesSystems > 0) {
        return (this.get('ovCradleInRepairWithoutSiteFilter') * 100 / sumCradlesSystems).toFixed(1);
      } else {
        return 0;
      }
    })

  });
});