define('op/pods/asset/asset-panel/component', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({

    userproperties: service('userproperties'),

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    }),
    isCradleActive_infobox: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'info-box-compact border-radius-none';
      }
    })

  });
});