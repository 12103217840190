define("op/pods/mdm-device-detail/controller", ["exports", "ember", "moment"], function (exports, _ember, _moment) {
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend({

    userproperties: service('userproperties'),

    //#52036 Dateisonderzeichen \/:*?"<>| durch _ ersetzen
    componentTypeImageName: _ember["default"].computed('model.mdmdevicedata', function () {

      var model = this.get('model.mdmdevicedata');
      var componentTypeImageName = model.get('ComponentTypeName') + ".jpg";
      componentTypeImageName = componentTypeImageName.replace("\\", "_");
      componentTypeImageName = componentTypeImageName.replace("/", "_");
      componentTypeImageName = componentTypeImageName.replace(":", "_");
      componentTypeImageName = componentTypeImageName.replace("*", "_");
      componentTypeImageName = componentTypeImageName.replace("?", "_");
      componentTypeImageName = componentTypeImageName.replace("\"", "_");
      componentTypeImageName = componentTypeImageName.replace(">", "_");
      componentTypeImageName = componentTypeImageName.replace("<", "_");
      componentTypeImageName = componentTypeImageName.replace("|", "_");

      return componentTypeImageName;
    }),

    formattedDate: _ember["default"].computed('model.mdmdevicedata', function () {
      var model = this.get('model.mdmdevicedata');
      var ts = model.get('LastCheckInTime');
      return (0, _moment["default"])(ts).format('YYYY-MM-DD HH:mm:ss');
    }),

    isLastLocaleActive_smallbox: computed('formattedDate', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Show-MDM-Location", 1) === "1" || userProperties.getNameByType("AC-Show-MDM-Location", 1) === "cadis") {
        return '"box"';
      } else {
        return 'display-none';
      }
    })

  });
});