define('op/components/day-filter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hourChanged: _ember['default'].observer('hour', function () {
      var hours = this.get('hours');
      this.set('selectedhour', hours[parseInt(this.get('hour'))]);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var dayObject = _ember['default'].Object.extend({
        name: '',
        id: ''
      });
      var i = undefined;
      var hours = _ember['default'].A();
      var hour = dayObject.create({
        name: "whole day",
        id: 0
      });
      hours.pushObject(hour);
      for (i = 1; i < 31; i++) {
        var _hour = dayObject.create({
          name: "".concat(i.toString(), "-", (i + 1).toString()),
          id: i
        });
        hours.pushObject(_hour);
      }
      this.set('hours', hours);
      this.set('selectedhour', hours[parseInt(this.get('hour'))]);
    },
    actions: {
      hourAction: function hourAction(hour) {
        this.attrs.filterHour(hour);
      }
    }
  });
});