define("op/locales/de/translations", ["exports"], function (exports) {
  /**
   * Created by Hofmann on 24.10.2016.
   */
  exports["default"] = {
    "global.tiles": "Kacheln",
    "global.list": "Liste",
    "mdm.dashboard.overview.allDevices": "All Devices",
    "mdm.dashboard.overview.Online": "Online",
    "mdm.dashboard.overview.Offline": "Offline",
    "mdm.dashboard.overview.OfflineXDays": "Offline > 5 days",
    "mdm.dashboard.overview.ConnectionState": "Connection State",
    "mdm.dashboard.overview.WiFiNet": "WiFi",
    "mdm.dashboard.overview.MobileNet": "Mobile Network",
    "mdm.dashboard.overview.OthersNet": "Others",
    "mdm.dashboard.overview.DeviceState": "Device State",
    "mdm.dashboard.overview.BatteryWarning": "Battery warning",
    "mdm.dashboard.overview.MemoryWarning": "Memory warning",
    "mdm.dashboard.overview.StorageWarning": "Storage warning",
    "mdm.dashboard.overview.BatteryCritical": "Battery critical",
    "mdm.dashboard.overview.MemoryCritical": "Memory critical",
    "mdm.dashboard.overview.StorageCritical": "Storage critical",

    "mdm.dashboard.overview.DevicesOk": "Devices ok",
    "mdm.dashboard.overview.DevicesWarning": "Devices warning",
    "mdm.dashboard.overview.DevicesCritical": "Devices critical",
    "mdm.dashboard.overview.DepotsOk": "Depots ok",
    "mdm.dashboard.overview.DepotsWarning": "Depots warning",
    "mdm.dashboard.overview.DepotsCritical": "Depots critical",

    "mdm.dashboard.overview.AllDevices": "AllDevices",
    "mdm.dashboard.overview.DevicesAvailable": "DevicesAvailable"

  };
});