define("op/controllers/login", ["exports", "ember", "ember-cp-validations"], function (exports, _ember, _emberCpValidations) {
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;

  var Validations = (0, _emberCpValidations.buildValidations)({
    identification: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports["default"] = Controller.extend(Validations, {
    session: service('session'),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        //#49635 Trim bei allen Eingaben
        var myTrim = this.get('identification').trim();
        this.set('identification', myTrim);
        myTrim = this.get('password').trim();
        this.set('password', myTrim);

        var credentials = this.getProperties('identification', 'password');
        window.console.log(credentials.identification);

        var authenticator = 'authenticator:jwt';
        this.get('session').authenticate(authenticator, credentials).then(function () {
          _this.sendAction();
        })["catch"](function (reason) {
          _this.set('errorMessage', reason.error);
          _this.set('errorMessageDetails', reason.error_description);
        });
      }
    }

  });
});