define('op/services/componenttypes', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service(),

    userproperties: service('userproperties'),

    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    componentTypes: null,
    loaded: false,
    load: function load() {
      var _this = this;

      if (!this.get('loaded')) {
        return this.get('store').findAll('componenttype').then(function (componenttypes) {
          _this.set('componentTypes', componenttypes);
          _this.set('loaded', true);
          _this.setSelectedProjects([]);
          _this.setSelectedCountries([]);
          _this.setSelectedSites([]);
          _this.setSelectedComponentTypes([]);
        });
      } else {
        return RSVP.resolve();
      }
    },

    ////////////////

    setSelectedProjects: function setSelectedProjects(selectedProjects) {
      this.set('selectedProjects', selectedProjects);
    },
    clearSelectedProjects: function clearSelectedProjects() {
      this.setSelectedProjects([]);
    },
    getProjectFamilyIDs: function getProjectFamilyIDs() {
      return this.get('selectedProjects').mapBy('ProjectFamilyId');
    },
    getProjectFamilyIDsString: function getProjectFamilyIDsString() {
      var tmpProjectFamilyIDsString = '';
      this.get('selectedProjects').mapBy('ProjectFamilyId').every(function (ProjectFamilyId) {
        tmpProjectFamilyIDsString = tmpProjectFamilyIDsString + ProjectFamilyId + ',';
        return true;
      });
      if (tmpProjectFamilyIDsString.length > 0) {
        tmpProjectFamilyIDsString = tmpProjectFamilyIDsString.substr(0, tmpProjectFamilyIDsString.length - 1);
      }
      return tmpProjectFamilyIDsString;
    },
    uniqueProjects: _ember['default'].computed('componentTypes', function () {
      var componentTypes = this.get('componentTypes');

      if (componentTypes !== null) {
        if (componentTypes.uniqBy('ProjectFamily').length === 1) {
          this.setSelectedProjects(componentTypes.uniqBy('ProjectFamily'));
        }
        return componentTypes.uniqBy('ProjectFamily');
      }
    }),

    ////////////////

    setSelectedCountries: function setSelectedCountries(selectedCountries) {
      this.set('selectedCountries', selectedCountries);
    },
    clearSelectedCountries: function clearSelectedCountries() {
      this.setSelectedCountries([]);
    },
    getSiteCCodes: function getSiteCCodes() {
      return this.get('selectedCountries').mapBy('SiteCCode');
    },
    getSiteCCodesString: function getSiteCCodesString() {
      var tmpSiteCCodesString = '';
      this.get('selectedCountries').mapBy('SiteCCode').every(function (SiteCCode) {
        tmpSiteCCodesString = tmpSiteCCodesString + SiteCCode + ',';
        return true;
      });
      if (tmpSiteCCodesString.length > 0) {
        tmpSiteCCodesString = tmpSiteCCodesString.substr(0, tmpSiteCCodesString.length - 1);
      }
      return tmpSiteCCodesString;
    },

    getSiteCountries: function getSiteCountries() {
      return this.get('selectedCountries').mapBy('SiteCountry');
    },
    uniqueCountries: _ember['default'].computed('componentTypes', 'selectedProjects', 'selectedCountries', function () {
      var selectedProjects = this.get('selectedProjects');
      var componentTypes = this.get('componentTypes');

      if (componentTypes !== null) {
        var selectedFilters = componentTypes;

        if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
          var filteredProjects = selectedFilters.filter(function (currentItem) {
            return selectedProjects.any(function (selected) {
              return currentItem.data.ProjectFamilyId === selected.data.ProjectFamilyId;
            });
          });
          selectedFilters = filteredProjects;
        }
        if (selectedFilters.uniqBy('SiteCountry').length === 1) {
          this.setSelectedCountries(selectedFilters.uniqBy('SiteCountry'));
        }
        return selectedFilters.uniqBy('SiteCountry').sortBy('SiteCountry');
      }
    }),

    ////////////////

    setSelectedSites: function setSelectedSites(selectedSites) {
      this.set('selectedSites', selectedSites);
    },
    clearSelectedSites: function clearSelectedSites() {
      this.setSelectedSites([]);
    },
    getSiteNames: function getSiteNames() {
      return this.get('selectedSites').mapBy('SiteName');
    },
    getSiteIDs: function getSiteIDs() {
      return this.get('selectedSites').mapBy('SiteId');
    },
    getSiteIDsString: function getSiteIDsString() {
      var tmpSiteIDsString = '';
      this.get('selectedSites').mapBy('SiteId').every(function (SiteId) {
        tmpSiteIDsString = tmpSiteIDsString + SiteId + ',';
        return true;
      });
      if (tmpSiteIDsString.length > 0) {
        tmpSiteIDsString = tmpSiteIDsString.substr(0, tmpSiteIDsString.length - 1);
      }
      return tmpSiteIDsString;
    },
    uniqueSites: _ember['default'].computed('componentTypes', 'selectedProjects', 'selectedCountries', 'selectedSites', function () {
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var componentTypes = this.get('componentTypes');

      if (componentTypes !== null) {

        var selectedFilters = componentTypes;

        if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
          var filteredProjects = selectedFilters.filter(function (currentItem) {
            return selectedProjects.any(function (selected) {
              return currentItem.data.ProjectFamilyId === selected.data.ProjectFamilyId;
            });
          });
          selectedFilters = filteredProjects;
        }
        if (typeof selectedCountries !== "undefined" && selectedCountries !== null && selectedCountries.length > 0) {
          var filteredCountries = selectedFilters.filter(function (currentItem) {
            return selectedCountries.any(function (selected) {
              return currentItem.data.SiteCCode === selected.data.SiteCCode;
            });
          });
          selectedFilters = filteredCountries;
        }
        if (selectedFilters.uniqBy('SiteName').length === 1) {
          this.setSelectedSites(selectedFilters.uniqBy('SiteName'));
        }
        return selectedFilters.uniqBy('SiteName').sortBy('SiteName');
      }
    }),

    ////////////////

    setSelectedComponentKinds: function setSelectedComponentKinds(selectedComponentKinds) {
      this.set('selectedComponentKinds', selectedComponentKinds);
    },
    clearSelectedComponentKinds: function clearSelectedComponentKinds() {
      this.setSelectedComponentKinds([]);
    },
    getComponentKindNames: function getComponentKindNames() {
      return this.get('selectedComponentKinds').mapBy('ComponentKindName');
    },
    getComponentKindIDs: function getComponentKindIDs() {
      return this.get('selectedComponentKinds').mapBy('ComponentKindID');
    },
    uniqueComponentKinds: _ember['default'].computed('componentTypes', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', function () {
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var componentTypes = this.get('componentTypes');

      if (componentTypes !== null) {

        var selectedFilters = componentTypes;

        //
        var userProperties = this.get('userproperties');
        if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
          selectedFilters = componentTypes.filter(function (currentItem) {
            return currentItem.data.ComponentKindID === 1;
          });
        }

        if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
          var filteredProjects = selectedFilters.filter(function (currentItem) {
            return selectedProjects.any(function (selected) {
              return currentItem.data.ProjectFamilyId === selected.data.ProjectFamilyId;
            });
          });
          selectedFilters = filteredProjects;
        }
        if (typeof selectedCountries !== "undefined" && selectedCountries !== null && selectedCountries.length > 0) {
          var filteredCountries = selectedFilters.filter(function (currentItem) {
            return selectedCountries.any(function (selected) {
              return currentItem.data.SiteCCode === selected.data.SiteCCode;
            });
          });
          selectedFilters = filteredCountries;
        }
        //if (selectedFilters.uniqBy('ComponentKindName').length === 1) {
        //  this.setSelectedComponentKinds(selectedFilters.uniqBy('ComponentKindName'));
        //}
        return selectedFilters.uniqBy('ComponentKindName').sortBy('ComponentKindName');
      }
    }),

    ////////////////

    setSelectedComponentTypes: function setSelectedComponentTypes(selectedComponentTypes) {
      this.set('selectedComponentTypes', selectedComponentTypes);
    },
    clearSelectedComponentTypes: function clearSelectedComponentTypes() {
      this.setSelectedComponentTypes([]);
    },
    /*
      getComponentTypeNames() {
        return this.get('selectedComponentTypes').mapBy('ComponentTypeName');
      },
    */
    getComponentTypeIDs: function getComponentTypeIDs() {
      return this.get('selectedComponentTypes').mapBy('ComponentTypeID');
    },
    getComponentTypeIDsString: function getComponentTypeIDsString() {
      var tmpComponentTypeIDsString = '';
      this.get('selectedComponentTypes').mapBy('ComponentTypeID').every(function (ComponentTypeID) {
        tmpComponentTypeIDsString = tmpComponentTypeIDsString + ComponentTypeID + ',';
        return true;
      });
      if (tmpComponentTypeIDsString.length > 0) {
        tmpComponentTypeIDsString = tmpComponentTypeIDsString.substr(0, tmpComponentTypeIDsString.length - 1);
      }
      return tmpComponentTypeIDsString;
    },
    uniqueComponentTypes: _ember['default'].computed('componentTypes', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', function () {
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var componentTypes = this.get('componentTypes');

      if (componentTypes !== null) {

        var selectedFilters = componentTypes;

        //
        var userProperties = this.get('userproperties');
        if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
          selectedFilters = componentTypes.filter(function (currentItem) {
            return currentItem.data.ComponentKindID === 1;
          });
        }

        if (typeof selectedProjects !== "undefined" && selectedProjects !== null && selectedProjects.length > 0) {
          var filteredProjects = selectedFilters.filter(function (currentItem) {
            return selectedProjects.any(function (selected) {
              return currentItem.data.ProjectFamilyId === selected.data.ProjectFamilyId;
            });
          });
          selectedFilters = filteredProjects;
        }
        if (typeof selectedCountries !== "undefined" && selectedCountries !== null && selectedCountries.length > 0) {
          var filteredCountries = selectedFilters.filter(function (currentItem) {
            return selectedCountries.any(function (selected) {
              return currentItem.data.SiteCCode === selected.data.SiteCCode;
            });
          });
          selectedFilters = filteredCountries;
        }
        if (typeof selectedSites !== "undefined" && selectedSites !== null && selectedSites.length > 0) {
          var filteredSites = selectedFilters.filter(function (currentItem) {
            return selectedSites.any(function (selected) {
              return currentItem.data.SiteId === selected.data.SiteId;
            });
          });
          selectedFilters = filteredSites;
        }
        if (typeof selectedComponentKinds !== "undefined" && selectedComponentKinds !== null && selectedComponentKinds.length > 0) {
          var filteredComponentKinds = selectedFilters.filter(function (currentItem) {
            return selectedComponentKinds.any(function (selected) {
              return currentItem.data.ComponentKindID === selected.data.ComponentKindID;
            });
          });
          selectedFilters = filteredComponentKinds;
        }
        //'ComponentComponentKindName'+'ComponentTypeName'
        if (selectedFilters.uniqBy('ComponentTypeName').length === 1) {
          this.setSelectedComponentTypes(selectedFilters.uniqBy('ComponentTypeName'));
        }
        return selectedFilters.uniqBy('ComponentTypeName').sortBy('ComponentTypeName');
      }
    })

  });
});