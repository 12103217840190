define('op/components/validated-input', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['validated-input'],
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    validation: null,
    showValidations: false,
    didValidate: false,

    notValidating: computed.not('validation.isValidating').readOnly(),
    hasContent: computed.notEmpty('value').readOnly(),
    hasWarnings: computed.notEmpty('validation.warnings').readOnly(),
    isValid: computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    isInvalid: computed.oneWay('validation.isInvalid'),
    showErrorClass: computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),
    showErrorMessage: computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = this.get('valuePath');

      defineProperty(this, 'validation', computed.readOnly('model.validations.attrs.' + valuePath));
      defineProperty(this, 'value', computed.alias('model.' + valuePath));
    },

    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      this.set('showValidations', true);
    }
  });
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */