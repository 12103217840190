define("op/models/mdmdeviceapp", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    AppIdGroup: _emberData["default"].attr(),
    AppId: _emberData["default"].attr(),
    Name: _emberData["default"].attr(),
    Status: _emberData["default"].attr(),
    Version: _emberData["default"].attr(),
    Size: _emberData["default"].attr(),
    InstalledOn: _emberData["default"].attr()
  });
});