define("op/controllers/forgot-password", ["exports", "ember", "ember-cp-validations"], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    userEmail: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      //regex: /^(?=.*[@]).{3,}$/,
      regex: /^[\w\.\-]+@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]{1,})*(\.[a-zA-Z]{2,3}){1,2}$/,
      message: 'Please enter a valid email address.'
    })]
  });

  exports["default"] = _ember["default"].Controller.extend(Validations, {
    applicationController: _ember["default"].inject.controller('application'),
    userLogin: _ember["default"].computed.alias('applicationController.userLogin'),
    ajax: _ember["default"].inject.service(),
    actions: {
      forgotPassword: function forgotPassword() {
        var _this = this;

        console.log('forgot password');
        var ajax = this.get('ajax');

        //#49635 Trim bei allen Eingaben
        var myTrim = this.get('userEmail').trim();
        this.set('userEmail', myTrim);

        var pwdata = this.getProperties('userEmail');

        return ajax.request('/api/password-forgot', {
          method: 'POST',
          data: pwdata
        }).then(function (result) {
          _this.set('userEmail', '');
          console.log(result);
          _this.transitionToRoute('application');
        })["catch"](function (error) {
          _this.set('errors', error.errors);
        });
      }
    }

  });
});