define('op/pods/mdm-device-detail/device-app/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    toolPanelVisible: false,
    filter: null,
    actions: {
      toggleToolPanel: function toggleToolPanel() {
        this.toggleProperty('toolPanelVisible');
        this.get('appGrid').api.showToolPanel(this.get('toolPanelVisible'));
      },
      autoComplete: function autoComplete() {
        this.get('appGrid').api.setQuickFilter(this.get('filter'));
      }
    },
    appGrid: computed('model', function () {
      var _this = this;

      var columnDefs = [{ headerName: "App Group", field: "AppIdGroup" }, { headerName: "App Id", field: "AppId" }, { headerName: "App Name", field: "Name" }, { headerName: "App Version", field: "Version" }, { headerName: "App Size", field: "Size" }, { headerName: "installed at", field: "InstalledOn" }];

      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this.createAppRowData(_this.get('model'));
        }
      };
    }),
    /*
        getContextMenuItems() {
            return [
                'copyWithHeaders',
                'copy'
            ];
        },
    */
    createAppRowData: function createAppRowData(model) {
      if (!model) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      var aggregatedArray = [];

      model.forEach(function (data) {
        aggregatedArray.push(data.data);
      });

      this.get('appGrid').api.setRowData(aggregatedArray.toArray());
      this.get('appGrid').api.sizeColumnsToFit();
    }

  });
});