define("op/controllers/change-password", ["exports", "ember", "ember-cp-validations"], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    userLogin: (0, _emberCpValidations.validator)('presence', true),
    oldPassword: [(0, _emberCpValidations.validator)('presence', true)],
    newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
      message: 'Password must be at least 12 characters long and contain at least one upper case letter, one lower case letter, and a number'
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  exports["default"] = _ember["default"].Controller.extend(Validations, {
    applicationController: _ember["default"].inject.controller('application'),
    userLogin: _ember["default"].computed.alias('applicationController.userLogin'),
    ajax: _ember["default"].inject.service(),
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        console.log('change password');
        var ajax = this.get('ajax');

        //#49635 Trim bei allen Eingaben
        var myTrim = this.get('userLogin').trim();
        this.set('userLogin', myTrim);
        myTrim = this.get('oldPassword').trim();
        this.set('oldPassword', myTrim);
        myTrim = this.get('newPassword').trim();
        this.set('newPassword', myTrim);
        myTrim = this.get('confirmPassword').trim();
        this.set('confirmPassword', myTrim);

        var pwdata = this.getProperties('userLogin', 'oldPassword', 'newPassword');

        return ajax.request('/api/password-change', {
          method: 'POST',
          data: pwdata
        }).then(function (result) {
          _this.set('oldPassword', '');
          _this.set('newPassword', '');
          _this.set('confirmPassword', '');
          console.log(result);
          _this.get('applicationController').send('invalidateSession');
          _this.transitionToRoute('application');
        })["catch"](function (error) {
          _this.set('errors', error.errors);
        });
      }
    }

  });
});