define("op/services/ajax", ["exports", "ember", "ember-ajax/services/ajax"], function (exports, _ember, _emberAjaxServicesAjax) {
  exports["default"] = _emberAjaxServicesAjax["default"].extend({
    session: _ember["default"].inject.service(),
    headers: _ember["default"].computed('session.session.content.authenticated.token', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.session.content.authenticated.token');

        if (authToken) {
          headers['authorization'] = 'Bearer ' + authToken;
        }
        return headers;
      }
    }),
    isSuccess: function isSuccess(status, headers, payload) {
      var isSuccess = this._super.apply(this, arguments);
      if (isSuccess && payload.status) {
        // when status === 200 and payload has status property,
        // check that payload.status is also considered a success request
        return this._super(payload.status);
      }
      return isSuccess;
    }
  });
});