define("op/pods/mdm-device-detail/device-location/component", ["exports", "ember", "moment"], function (exports, _ember, _moment) {
    exports["default"] = _ember["default"].Component.extend({

        formattedDateLastLocation: _ember["default"].computed('model.mdmdevicedata', function () {
            var model = this.get('model.mdmdevicedata');
            var ts = model.get('LastLocationTime');
            return (0, _moment["default"])(ts).format('YYYY-MM-DD HH:mm:ss');
        })

    });
});