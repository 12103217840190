define("op/pods/repair/repair-grid/component", ["exports", "ember", "moment", "op/tools/checkHelper"], function (exports, _ember, _moment, _opToolsCheckHelper) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var selectValueInFilter = _opToolsCheckHelper["default"].selectValueInFilter;
  exports["default"] = Component.extend({
    filterTypeMap: new Map([["filterDeviceAll", "1"], ["filterDeviceRepaired", "1"], ["filterCradleAll", "2"], ["filterCradleRepaired", "2"]]),
    gridReady: false,
    actions: {
      autoComplete: function autoComplete() {
        var grid = this.get('repairGrid');
        grid.api.setQuickFilter(this.get('autoFilter'));
      },
      toggleToolPanel: function toggleToolPanel() {
        var grid = this.get('repairGrid');
        this.toggleProperty('toolPanelVisible');
        grid.api.showToolPanel(this.get('toolPanelVisible'));
        grid.api.sizeColumnsToFit();
      }
    },
    formattedInRecordingDate: function formattedInRecordingDate(params) {
      return (0, _moment["default"])(params.data.InRecordingDate).format('YYYY-MM-DD HH:mm');
    },
    formattedOutRecordingDate: function formattedOutRecordingDate(params) {
      return (0, _moment["default"])(params.data.OutRecordingDate).format('YYYY-MM-DD HH:mm');
    },
    fixed2FailureRate: function fixed2FailureRate(params) {
      return params.data.FailureRate.toFixed(2);
    },

    repairGrid: computed(function () {
      var _this = this;

      var columnDefs = [{ headerName: "Project id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily", width: 20 }, { headerName: "CCode", field: "SiteCCode", width: 5 }, { headerName: "Country", field: "SiteCountry", hide: true }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Site Name", field: "SiteName", width: 25 }, { headerName: "Identifier", field: "SiteIdentifier", width: 10 }, { headerName: "Component TypeNr", field: "ComponentTypeNumber", hide: true }, { headerName: "Component Type", field: "ComponentTypeName", width: 25 }, { headerName: "Component Kind", field: "ComponentKindName", width: 15 }, { headerName: "Kind Group", field: "ComponentKindGroup", width: 10 }, {
        headerName: "Creation Time", field: "InRecordingDate",
        valueGetter: this.get('formattedInRecordingDate'),
        width: 20
      }, {
        headerName: "Completion Date", field: "OutRecordingDate",
        valueGetter: this.get('formattedOutRecordingDate'), hide: true
      }, { headerName: "Ticket Number", field: "TicketNumber", width: 20 }, { headerName: "Order Number", field: "OrderNumber", width: 12 }, { headerName: "System Number", field: "SystemNumber", width: 12 }, { headerName: "In SNR", field: "InSNR", width: 20 }, { headerName: "Status", field: "ComponentStatusText", hide: true, width: 30 }, { headerName: "Status Group", field: "ComponentStatusGroup", hide: true, width: 30 }, { headerName: "Out SNR", field: "OutSNR", width: 20 }, { headerName: "SNR_Exchange", field: "SNR_Exchange", hide: true, width: 30 }, { headerName: "RMA Number", field: "RMANumber", width: 12 },
      ///#52037 UPS Tracking Nummer
      ///{headerName: "Tracking Nummer", field: "TrackingNummer", hide: true, width: 12},
      { headerName: "Order State", field: "OrderState", hide: true }, { headerName: "Order State Group", field: "OrderStateGroup", width: 10 }];
      return {
        columnDefs: columnDefs,
        rowHeight: 25,
        headerHeight: 48,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: true,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this.createRepairRowData(_this.get('data').toArray());
          _this.set('gridReady', true);
        },
        onModelUpdated: function onModelUpdated() {
          _this.updateRepairTotals();
        }

      };
    }),
    /*
        getContextMenuItems() {
            return [
                'copyWithHeaders',
                'copy'
            ];
        },
    */
    createRepairRowData: function createRepairRowData(data) {
      if (!data) {
        return;
      }
      // convert ember array to js array!
      var aggregatedArray = [];
      data.forEach(function (item) {
        aggregatedArray.push(item.data);
      });

      var grid = this.get('repairGrid');
      grid.api.setRowData(aggregatedArray);
      grid.api.sizeColumnsToFit();

      this.set('repairGridRowCount', grid.api.getModel().getRowCount());
      this.set('gridReady', true);
    },

    updateRepairTotals: function updateRepairTotals() {
      if (this.get('repairGrid')) {
        this.set('repairGridRowCount', this.get('repairGrid').api.getModel().getRowCount());
      } else {
        this.set('repairGridRowCount', 0);
      }
      this.incrementProperty('repairGridEvent');
    },

    dataUpdated: _ember["default"].observer('data', function () {
      if (this.get('gridReady')) {
        this.createRepairRowData(this.get('data'));
      }
    }),
    filterUpdated: _ember["default"].observer('filterProperty', 'filterValue', 'filterChanged', 'data', function () {
      if (this.get('gridReady')) {
        var filterProperty = this.get('filterProperty');
        var filterValue = this.get('filterValue');
        var mappedKinds = this.get('mappedKinds');
        var filterMap = this.get('filterTypeMap');
        var value = filterMap.get(filterProperty);

        var grid = this.get('repairGrid');

        if (typeof grid.api !== 'undefined') {
          grid.api.setFilterModel(null);
          switch (filterProperty) {
            case 'filterDeviceRepaired':
            case 'filterCradleRepaired':
            case 'filterDeviceAll':
            case 'filterCradleAll':
              var filterInstance = grid.api.getFilterInstance('ComponentKindGroup');
              filterInstance.selectNothing();
              if (filterValue === true) {
                selectValueInFilter(filterInstance, mappedKinds[value]);
              } else {
                filterInstance.selectEverything();
              }
              //filterInstance.onFilterChanged();
              break;
            default:
              console.log('wrong filter property.');
              break;
          }
          grid.api.onFilterChanged();
        }
      }
    })

  });
});