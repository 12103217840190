define('op/transforms/1-to-true', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === 1) {
        return true;
      } else {
        return false;
      }
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});