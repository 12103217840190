define("op/tools/checkHelper", ["exports"], function (exports) {
  exports.undefinedNoData = undefinedNoData;
  exports.arrayDefinedWithData = arrayDefinedWithData;
  exports.definedWithData = definedWithData;
  exports.selectValueInFilter = selectValueInFilter;
  exports.unselectValueInFilter = unselectValueInFilter;
  /**
   * Created by Hofmann on 22.03.2017.
   */

  function undefinedNoData(valueArray) {
    return typeof valueArray === "undefined" || valueArray === null || valueArray.length === 0;
  }

  function arrayDefinedWithData(valueArray) {
    return typeof valueArray !== "undefined" && valueArray !== null && valueArray.length > 0;
  }

  function definedWithData(object) {
    return typeof object !== "undefined" && object !== null;
  }

  function selectValueInFilter(filterObject, value) {
    if (filterObject.model.availableUniqueValues.indexOf(value) > -1) {
      filterObject.selectValue(value);
    }
  }

  function unselectValueInFilter(filterObject, value) {
    if (filterObject.model.availableUniqueValues.indexOf(value) > -1) {
      filterObject.unselectValue(value);
    }
  }
});