define("op/pods/mdm-device-detail/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var computed = _ember["default"].computed;
  var getOwner = _ember["default"].getOwner;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    session: service('session'),
    ajax: service('ajax'),
    model: function model(transition, params) {
      console.log(params.queryParams);
      return _ember["default"].RSVP.hash({
        mdmdevicedata: this.store.peekRecord('mdm', params.queryParams.ident),
        mdmdeviceapp: this.store.query('mdmdeviceapp', {
          filter: {
            snrId: params.queryParams.snrId
          }
        }),
        mdmbatteryhistory: this.store.query('mdmbatteryhistory', {
          filter: {
            snrId: params.queryParams.snrId
          }
        })
      });
    },

    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    afterModel: function afterModel() {

      var ajax = this.get('ajax');

      this.set('userId', this.get('userId'));
      this.set('subModulId', 4);
      this.set('routeId', 3);
      this.set('description', ''); //"mdm-device-detail");
      var pwdata = this.getProperties('userId', 'subModulId', 'routeId', 'description');

      ajax.request('/api/set-subModule', {
        method: 'POST',
        data: pwdata
      });
    },

    actions: {
      error: function error(_error, transition) {
        console.log(transition);
        alert('The following error occurred: ' + _error);
        this.transitionTo('application');
      }
    }
  });
});