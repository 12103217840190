define('op/routes/init-password', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    queryParams: {
      uid: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (_ember['default'].isPresent(params.uid)) {

        return null;
      }
    }
  });
});