define("op/pods/repair/controller", ["exports", "ember", "op/tools/checkHelper"], function (exports, _ember, _opToolsCheckHelper) {
  exports.checkDate = checkDate;
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;
  var computed = _ember["default"].computed;
  var arrayDefinedWithData = _opToolsCheckHelper["default"].arrayDefinedWithData;
  var definedWithData = _opToolsCheckHelper["default"].definedWithData;

  function checkDate(inDate, startDate) {
    var endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + 1);

    return inDate.getTime() >= startDate.getTime() && inDate.getTime() <= endDate.getTime();
  }

  exports["default"] = Controller.extend({
    updates: service('updates'),
    month: null,

    userproperties: service('userproperties'),

    disabledFilterProjects: false,
    disabledFilterCountries: false,
    disabledFilterSites: false,
    disabledFilterComponentKinds: false,
    disabledFilterComponentTypes: false,
    FilterComponentKindsIsVisible: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'input-group';
      }
    }),
    FilterComponentTypesIsVisible: 'input-group',
    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    init: function init() {
      "use strict";
      this.get('updates').setLastUpdated('RepairIsUpdated');
    },
    actions: {
      filterGrid: function filterGrid(filterProperty, filterValue) {
        this.set('filterProperty', filterProperty);
        this.set('filterValue', filterValue);
        this.incrementProperty('filterChanged');
      },
      selectProjects: function selectProjects(projects) {
        this.set('selectedProjects', projects);
        this.get('selectedCountries').clear();
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.set('filterChanged', 0);
      },
      selectCountries: function selectCountries(countries) {
        this.set('selectedCountries', countries);
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectSites: function selectSites(sites) {
        this.set('selectedSites', sites);
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectComponentKinds: function selectComponentKinds(kinds) {
        this.set('selectedComponentKinds', kinds);
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
      },
      selectComponentTypes: function selectComponentTypes(types) {
        this.set('selectedComponentTypes', types);
        this.incrementProperty('filterChanged');
      },
      drillDownClicked: function drillDownClicked(newDate) {
        this.set('filterDate', newDate);
        this.set('disabledFilterProjects', true);
        this.set('disabledFilterCountries', true);
        this.set('disabledFilterSites', true);
        this.set('disabledFilterComponentKinds', true);
        this.set('disabledFilterComponentTypes', true);
      },
      drillUpClicked: function drillUpClicked() {
        this.set('filterDate', null);
        this.set('disabledFilterProjects', false);
        this.set('disabledFilterCountries', false);
        this.set('disabledFilterSites', false);
        this.set('disabledFilterComponentKinds', false);
        this.set('disabledFilterComponentTypes', false);
      }

    },
    siteIds: computed.mapBy('model.repair', 'SiteId'),
    uniqueSites: computed.uniq('siteIds'),

    repairYearData: computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'RepairIsUpdated', 'filterDate', 'filterChanged', 'model.repairyear', function () {
      var model = this.get('model.repairyear');
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var filterDate = this.get('filterDate');
      //let filterProperty = this.get('filterProperty');
      //let filterValue = this.get('filterValue');
      var data2 = [];
      var data = model.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.get('SiteId'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      } // only date filtered
      if (definedWithData(filterDate)) {
        data2 = data.filter(function (item) {
          var refDate = new Date(item.get('RefDate'));
          return filterDate.getTime() === refDate.getTime();
        });
        data = data2;
      } // only devices or cradles filtered
      return data;
    }),
    componentKindIds: computed.mapBy('model.repair', 'ComponentKindID'),
    uniqueComponentKindIDs: computed.uniq('componentKindIds'),

    mappedKinds: computed('repairData', 'uniqueComponentKindIDs', function () {
      var data = this.get('repairData');
      var uniqueComponentKindIDs = this.get('uniqueComponentKindIDs');
      var mappedData = {};
      uniqueComponentKindIDs.forEach(function (typeId) {
        var filteredData = data.findBy('ComponentKindID', typeId);
        if (definedWithData(filteredData)) {
          mappedData[typeId] = filteredData.data.ComponentKindGroup;
        }
      });
      return mappedData;
    }),
    repairData: computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'RepairIsUpdated', 'filterDate', 'filterChanged', 'model.repair', function () {
      var model = this.get('model.repair');
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var filterDate = this.get('filterDate');
      var data2 = [];
      var data = model.toArray();

      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.data.ProjectFamilyId);
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.data.SiteId);
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.data.ComponentKindID);
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.data.ComponentTypeID);
        });
        data = data2;
      }
      if (definedWithData(filterDate)) {
        data2 = data.filter(function (item) {
          var inDate = new Date(item.data.InRecordingDate);
          return checkDate(inDate, filterDate);
        });
        data = data2;
      }
      return data;
    })

  });
});