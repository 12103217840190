define('op/pods/repairyear/model', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    ProjectId: attr('number'),
    ProjectName: attr(),
    ProjectFamilyId: attr('number'),
    ProjectFamily: attr(),
    SiteId: attr('number'),
    SiteIdDetail: attr('number'),
    SiteName: attr(),
    SiteCCode: attr(),
    SiteCountry: attr(),
    SiteIdentifier: attr(),
    AllDevices: attr('number-null-to-0'),
    AllCradles: attr('number-null-to-0'),
    AllSystems: attr('number-null-to-0'),
    ComponentTypeNumber: attr(),
    ComponentTypeName: attr(),
    ComponentTypeID: attr('number'),
    ComponentKindName: attr(),
    ComponentKindID: attr('number'),
    ComponentKindGroup: attr(),
    RepairedCount: attr('number-null-to-0'),
    RefDate: attr(''),
    FailureRate: attr('number')
  });
});