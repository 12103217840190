define('op/pods/repair/failure-rate-history/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      drillDownClicked: function drillDownClicked(dateString) {
        var newDate = new Date(dateString);
        console.log('date: ' + dateString + ' month: ' + newDate.getMonth());
        this.attrs.drillDownClicked(newDate);
      },
      drillUpClicked: function drillUpClicked() {
        console.log('drilled up');
        this.attrs.drillUpClicked();
      }

    }
  });
});