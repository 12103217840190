define("op/services/updates", ["exports", "ember", "ember-data", "moment"], function (exports, _ember, _emberData, _moment) {
  var service = _ember["default"].inject.service;
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var getOwner = _ember["default"].getOwner;
  var pollInterval = 10000;exports.pollInterval = pollInterval;
  // time in milliseconds

  exports["default"] = _ember["default"].Service.extend({
    session: service('session'),
    store: service(),
    pollboy: service(),
    init: function init() {
      this.set('MDMLastCall', (0, _moment["default"])());
      this.set('AssetLastCall', (0, _moment["default"])());
      this.set('IncidentLastCall', (0, _moment["default"])());
      this.set('RepairLastCall', (0, _moment["default"])());
      this.set('MDMIsUpdated', true);
      this.set('AssetIsUpdated', true);
      this.set('IncidentIsUpdated', true);
      this.set('RepairIsUpdated', true);
      this.load();
    },
    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),
    setLastCall: function setLastCall(module) {
      this.set(module, (0, _moment["default"])());
      this.set(module + '_Display', (0, _moment["default"])().format('YYYY-MM-DD HH:mm'));
    },
    resetLastUpdated: function resetLastUpdated(module) {
      "use strict";
      this.set(module, false);
    },
    setLastUpdated: function setLastUpdated(module) {
      "use strict";
      this.set(module, true);
    },

    getUpdates: function getUpdates() {
      return _emberData["default"].PromiseArray.create({
        promise: this.get('store').findAll('update')
      });
    },

    onPoll: function onPoll() {
      var _this = this;

      return this.getUpdates().then(function (updates) {
        var entry = updates.get('firstObject');
        var updatedAsset = entry.get('AssetUpdated');
        var assetUpdated = (0, _moment["default"])(updatedAsset);
        var assetLastCall = _this.get('AssetLastCall');
        if (assetLastCall.isBefore(assetUpdated)) {
          _this.set('AssetIsUpdated', true);
          //this.get('store').findAll('asset', { reload: true });
          //this.get('store').findAll('device', { reload: true });
          _this.setLastCall('AssetLastCall');
        } else {
          //this.set('AssetIsUpdated', false);
        }
        var updatedMDM = entry.get('MDMUpdated');
        var MDMUpdated = (0, _moment["default"])(updatedMDM);
        var MDMLastCall = _this.get('MDMLastCall');
        if (MDMLastCall.isBefore(MDMUpdated)) {
          _this.set('MDMIsUpdated', true);
          //this.get('store').findAll('mdmoverview', { reload: true });
          _this.setLastCall('MDMLastCall');
        } else {
          //this.set('MDMIsUpdated', false);
        }

        var updatedIncident = entry.get('IncidentUpdated');
        var IncidentUpdated = (0, _moment["default"])(updatedIncident);
        var IncidentLastCall = (0, _moment["default"])(_this.get('IncidentLastCall'));
        if (IncidentLastCall.isBefore(IncidentUpdated)) {
          _this.set('IncidentIsUpdated', true);
          //this.get('store').findAll('incident', { reload: true });
          _this.setLastCall('IncidentLastCall');
        } else {
          //this.set('IncidentIsUpdated', false);
        }

        var updatedRepair = entry.get('RepairUpdated');
        var RepairUpdated = (0, _moment["default"])(updatedRepair);
        var RepairLastCall = (0, _moment["default"])(_this.get('RepairLastCall'));
        if (RepairLastCall.isBefore(RepairUpdated)) {
          _this.set('RepairIsUpdated', true);
          //this.get('store').findAll('Repair', { reload: true });
          _this.setLastCall('RepairLastCall');
        } else {
          //this.set('RepairIsUpdated', false);
        }

        _this.set('update', updates);
      });
    },

    load: function load() {
      var userId = this.get('userId');
      if (!isEmpty(userId)) {
        var updatesPoller = this.get('updatesPoller');

        // Make sure we only create one poller instance. Without this every time onPoll
        // is called afterModel would create a new poller causing us to have a growing list
        // of pollers all polling the same thing (which would result in more frequent polling).
        if (!updatesPoller) {
          updatesPoller = this.get('pollboy').add(this, this.onPoll, pollInterval);
          this.set('updatesPoller', updatesPoller);
        }
      }
    },
    deactivate: function deactivate() {
      var updatesPoller = this.get('updatesPoller');
      this.get('pollboy').remove(updatesPoller);
    }
  });
});