define('op/pods/mdm/model', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    Ident: attr('number'),
    SiteId: attr('number'),
    SiteIdDetail: attr('number'),
    SiteName: attr(),
    SiteCCode: attr(),
    SiteCountry: attr(),
    SiteIdentifier: attr(),
    SiteStreet: attr(),
    SiteStreetNumber: attr(),
    SiteAdditionalAddress: attr(),
    SitePostalCode: attr(),
    SiteCity: attr(),
    SitePhoneNumber: attr(),
    SitePostReceiver: attr(),
    ProjectId: attr('number'),
    ProjectName: attr(),
    ProjectFamilyId: attr('number'),
    ProjectFamily: attr(),
    SystemNumber: attr(),
    ComponentStatusID: _emberData['default'].attr('number'),
    ComponentStatusText: _emberData['default'].attr(),
    ComponentStatusGroup: _emberData['default'].attr(),
    ComponentStatusGroupID: _emberData['default'].attr('number'),
    Snr: attr(),
    SnrId: attr('number'),
    ComponentTypeNumber: attr(),
    ComponentTypeName: attr(),
    ComponentTypeID: attr('number'),
    ComponentKindName: attr(),
    ComponentKindID: attr('number'),
    MDM_Server: attr(),
    MDM_DeviceName: attr(),
    MDM_CT_String: attr(),
    Online: attr('number'),
    BatteryStatus: attr('number'),
    TotalRAM: attr('number'),
    TotalStorage: attr('number'),
    TotalExternalStorage: attr('number'),
    AvailableRAMPercent: attr('number'),
    AvailableStoragePercent: attr('number'),
    AvailableExternalStoragePercent: attr('number'),
    WifiNet: attr('number'),
    MobileNet: attr('number'),
    OthersNet: attr('number'),
    OfflineCritical: attr('number'),
    OnlineWifiConnected: attr('number'),
    OnlineMobileConnected: attr('number'),
    OnlineOthersConnected: attr('number'),
    BatteryWarning: attr('number-null-to-0'),
    BatteryCritical: attr('number-null-to-0'),
    MemoryWarning: attr('number-null-to-0'),
    MemoryCritical: attr('number-null-to-0'),
    AvailableStorageWarning: attr('number-null-to-0'),
    AvailableStorageCritical: attr('number-null-to-0'),
    Ok: attr('number'),
    AnyWarning: attr('number'),
    AnyCritical: attr('number'),
    Offline: attr('number'),
    Type2G: attr('number-null-to-0'),
    Type3G: attr('number-null-to-0'),
    Tyle4G: attr('number-null-to-0'),
    limitForCriticalOffline: attr('number'),
    limitBatteryWarning: attr('number'),
    limitBatteryCritical: attr('number'),
    limitMemoryWarning: attr('number'),
    limitMemoryCritical: attr('number'),
    limitStorageWarning: attr('number'),
    limitStorageCritical: attr('number'),
    IMEI: attr(),
    MAC: attr(),
    PhoneNumber: attr(),
    HostName: attr(),
    ICCID: attr(),
    SSID: attr(),
    DeviceState: attr(),
    ConnectionStateID: attr('number'),
    ConnectionState: attr(),
    DeviceStateID: attr('number'),
    LastCheckInTime: attr(),
    DeviceId: attr(),
    Manufacturer: attr(),
    Model: attr(),
    OSVersion: attr(),
    OEMVersion: attr(),
    AgentVersion: attr(),
    LastLocationTime: _emberData['default'].attr(),
    Latitude: _emberData['default'].attr(),
    Longitude: _emberData['default'].attr(),
    LatitudeLongitude: _emberData['default'].attr(),
    Altitude: _emberData['default'].attr()

  });
});