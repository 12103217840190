define("op/pods/mdm/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var computed = _ember["default"].computed;
  var getOwner = _ember["default"].getOwner;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    session: service('session'),
    updates: service('updates'),
    ajax: service('ajax'),
    model: function model() {
      if (this.get('updates').get('MDMIsUpdated') === true) {
        return _ember["default"].RSVP.hash({
          mdm: this.store.findAll('mdm', { reload: true }), // this.get('updates').get('MDMIsUpdated') }),
          assetsum: this.store.findAll('assetsum', { reload: true }), // this.get('updates').get('MDMIsUpdated') }),
          devicepool: this.store.findAll('devicepool', { reload: true }) });
      } else // this.get('updates').get('MDMIsUpdated') }),
        {
          return {
            mdm: this.store.peekAll('mdm'),
            assetsum: this.store.peekAll('assetsum'),
            devicepool: this.store.peekAll('devicepool')
          };
        }
    },
    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    afterModel: function afterModel() {

      var ajax = this.get('ajax');

      this.set('userId', this.get('userId'));
      this.set('subModulId', 4);
      this.set('routeId', 1);
      this.set('description', ''); //"mdm");
      var pwdata = this.getProperties('userId', 'subModulId', 'routeId', 'description');

      ajax.request('/api/set-subModule', {
        method: 'POST',
        data: pwdata
      });

      if (this.get('updates').get('MDMIsUpdated')) {
        this.get('updates').resetLastUpdated('MDMIsUpdated');
      }
    },
    actions: {
      error: function error(_error, transition) {
        console.log(transition);
        alert('The following error occurred: ' + _error);
        this.transitionTo('application');
      }
    }
  });
});