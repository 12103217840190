define("op/pods/components/incident-site-grid/component", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    filterTypeMap: new Map([["filterTypeAll", "type,all"], ["filterTypeSW", "type"], ["filterTypeHW", "type"], ["filterTypeEvents", "type"], ["filterTypeOthers", "type"], ["filterTypeRequests", "type"], ["filterPrioAll", "prio,all"], ["filterPrioHigh", "prio"], ["filterPrioMedium", "prio"], ["filterPrioLow", "prio"], ["filterPrioNone", "prio"], ["filterStateAll", "state,all"], ["filterStateOpen", "state"], ["filterStateClosed", "state"]]),
    filterMap: new Map([["filterTypeSW", "AllTypeIncidentSW"], ["filterTypeHW", "AllTypeIncidentHW"], ["filterTypeEvents", "AllTypeIncidentProactive"], ["filterTypeOthers", "AllTypeOther"], ["filterTypeRequests", "AllTypeServiceRequest"], ["filterPrioHigh", "AllPrioHigh"], ["filterPrioMedium", "AllPrioMedium"], ["filterPrioLow", "AllPrioLow"], ["filterPrioNone"], ["filterStateOpen", "AllTicketOpen"], ["filterStateClosed", "AllTicketClosed"]]),
    gridReady: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      autoComplete: function autoComplete() {
        var grid = this.get('incidentGrid');
        grid.api.setQuickFilter(this.get('autoFilter'));
      },
      toggleToolPanel: function toggleToolPanel() {
        var grid = this.get('incidentGrid');
        this.toggleProperty('toolPanelVisible');
        grid.api.showToolPanel(this.get('toolPanelVisible'));
        grid.api.sizeColumnsToFit();
      }
    },

    mappedData: computed('data', 'uniqueSites', function () {
      var data = this.get('data');
      var uniqueSites = this.get('uniqueSites');

      var mappedData = _ember["default"].Map.create();
      uniqueSites.forEach(function (siteId) {
        mappedData.set(siteId, data.filterBy('SiteId', siteId));
      });
      return mappedData;
    }),
    aggregatedData: computed('mappedData', function () {
      var aggregatedArray = [];
      var ii = 1;

      this.get('mappedData').forEach(function (valueArray) {
        if (valueArray.length > 0) {
          var first = valueArray.get('firstObject');
          var color = 'bg-green';
          var AllTickets = valueArray.length;
          var AllTypeServiceRequest = valueArray.reduce(function (a, b) {
            return a + b.data.TypeServiceRequest;
          }, 0);
          var AllTypeIncident = valueArray.reduce(function (a, b) {
            return a + b.data.TypeIncident;
          }, 0);
          var AllTypeIncidentSW = valueArray.reduce(function (a, b) {
            return a + b.data.TypeIncidentSW;
          }, 0);
          var AllTypeIncidentHW = valueArray.reduce(function (a, b) {
            return a + b.data.TypeIncidentHW;
          }, 0);
          var AllTypeIncidentProactive = valueArray.reduce(function (a, b) {
            return a + b.data.TypeIncidentProactive;
          }, 0);
          var AllTypeUnclassified = valueArray.reduce(function (a, b) {
            return a + b.data.TypeUnclassified;
          }, 0);
          var AllTypeOther = valueArray.reduce(function (a, b) {
            return a + b.data.TypeOther;
          }, 0);
          var AllTicketOpen = valueArray.reduce(function (a, b) {
            return a + b.data.TicketOpen;
          }, 0);
          var AllTicketClosed = valueArray.reduce(function (a, b) {
            return a + b.data.TicketClosed;
          }, 0);
          var AllPrioHigh = valueArray.reduce(function (a, b) {
            return a + b.data.PrioHigh;
          }, 0);
          var AllPrioMedium = valueArray.reduce(function (a, b) {
            return a + b.data.PrioMedium;
          }, 0);
          var AllPrioLow = valueArray.reduce(function (a, b) {
            return a + b.data.PrioLow;
          }, 0);
          var AllPrioNone = valueArray.reduce(function (a, b) {
            return a + b.data.PrioNone;
          }, 0);
          var AllTypeServiceRequestPercent = 0;
          var AllTypeIncidentPercent = 0;
          var AllTypeIncidentSWPercent = 0;
          var AllTypeIncidentHWPercent = 0;
          var AllTypeIncidentProactivePercent = 0;
          var AllTypeUnclassifiedPercent = 0;
          var AllTypeOtherPercent = 0;
          var AllTicketOpenPercent = 0;
          var AllTicketClosedPercent = 0;
          var AllPrioHighPercent = 0;
          var AllPrioMediumPercent = 0;
          var AllPrioLowPercent = 0;
          var AllPrioNonePercent = 0;

          if (AllTickets > 0) {
            AllTypeServiceRequestPercent = (AllTypeServiceRequest * 100 / AllTickets).toFixed(1);
            AllTypeIncidentPercent = (AllTypeIncident * 100 / AllTickets).toFixed(1);
            AllTypeIncidentSWPercent = (AllTypeIncidentSW * 100 / AllTickets).toFixed(1);
            AllTypeIncidentHWPercent = (AllTypeIncidentHW * 100 / AllTickets).toFixed(1);
            AllTypeIncidentProactivePercent = (AllTypeIncidentProactive * 100 / AllTickets).toFixed(1);
            AllTypeUnclassifiedPercent = (AllTypeUnclassified * 100 / AllTickets).toFixed(1);
            AllTypeOtherPercent = (AllTypeOther * 100 / AllTickets).toFixed(1);
            AllTicketOpenPercent = (AllTicketOpen * 100 / AllTickets).toFixed(1);
            AllTicketClosedPercent = (AllTicketClosed * 100 / AllTickets).toFixed(1);
            AllPrioHighPercent = (AllPrioHigh * 100 / AllTickets).toFixed(1);
            AllPrioMediumPercent = (AllPrioMedium * 100 / AllTickets).toFixed(1);
            AllPrioLowPercent = (AllPrioLow * 100 / AllTickets).toFixed(1);
            AllPrioNonePercent = (AllPrioNone * 100 / AllTickets).toFixed(1);
          }

          var TicketObject = _ember["default"].Object.create({
            id: ii,
            //UserLogin: first.get('UserLogin'),
            ProjectName: first.get('ProjectName'),
            ProjectId: first.get('ProjectId'),
            ProjectFamily: first.get('ProjectFamily'),
            ProjectFamilyId: first.get('ProjectFamilyId'),
            SiteIdDetail: first.get('SiteIdDetail'),
            SiteName: first.get('SiteName'),
            SiteCCode: first.get('SiteCCode'),
            SiteCountry: first.get('SiteCountry'),
            SiteIdentifier: first.get('SiteIdentifier'),
            SiteId: first.get('SiteId'),
            AllTickets: AllTickets,
            AllTypeServiceRequest: AllTypeServiceRequest,
            AllTypeIncident: AllTypeIncident,
            AllTypeIncidentSW: AllTypeIncidentSW,
            AllTypeIncidentHW: AllTypeIncidentHW,
            AllTypeIncidentProactive: AllTypeIncidentProactive,
            AllTypeUnclassified: AllTypeUnclassified,
            AllTypeOther: AllTypeOther,
            AllTicketOpen: AllTicketOpen,
            AllTicketClosed: AllTicketClosed,
            AllPrioHigh: AllPrioHigh,
            AllPrioMedium: AllPrioMedium,
            AllPrioLow: AllPrioLow,
            AllPrioNone: AllPrioNone,
            AllTypeServiceRequestPercent: AllTypeServiceRequestPercent,
            AllTypeIncidentPercent: AllTypeIncidentPercent,
            AllTypeIncidentSWPercent: AllTypeIncidentSWPercent,
            AllTypeIncidentHWPercent: AllTypeIncidentHWPercent,
            AllTypeIncidentProactivePercent: AllTypeIncidentProactivePercent,
            AllTypeUnclassifiedPercent: AllTypeUnclassifiedPercent,
            AllTypeOtherPercent: AllTypeOtherPercent,
            AllTicketOpenPercent: AllTicketOpenPercent,
            AllTicketClosedPercent: AllTicketClosedPercent,
            AllPrioHighPercent: AllPrioHighPercent,
            AllPrioMediumPercent: AllPrioMediumPercent,
            AllPrioLowPercent: AllPrioLowPercent,
            AllPrioNonePercent: AllPrioNonePercent,
            bgColorTicketAvailable: _ember["default"].String.htmlSafe(color),
            bgColor: _ember["default"].String.htmlSafe('bg-green')
          });
          aggregatedArray.pushObject(TicketObject);
          ii += 1;
        }
      });
      //this.sendAction('onAggregatedDataChanged', aggregatedArray);

      return aggregatedArray;
    }),

    incidentGrid: computed(function () {
      var _this = this;

      var columnDefs = [{ headerName: "Project id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }]
      }, { headerName: "Requests", field: "AllTypeServiceRequest", filter: "number" }, {
        headerName: "Incidents", headerClass: "centeredHeader", marryChildren: true, openByDefault: true,
        children: [{ headerName: "All", field: "AllTypeIncident", filter: "number" }, { headerName: "SW", field: "AllTypeIncidentSW", filter: "number" }, { headerName: "HW", field: "AllTypeIncidentHW", filter: "number" }]
      },
      //{headerName: "Events", field: "AllTypeIncidentProactive", filter: "number"},
      { headerName: "Unclassified", field: "AllTypeUnclassified", filter: "number", hide: true }, { headerName: "Others", field: "AllTypeOther", filter: "number" }, {
        headerName: "Priorities",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "High", field: "AllPrioHigh", filter: "number" }, { headerName: "Medium", field: "AllPrioMedium", filter: "number" }, { headerName: "Low", field: "AllPrioLow", filter: "number" }, { headerName: "None", field: "AllPrioNone", filter: "number" }]
      }, {
        headerName: "States",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Open", field: "AllTicketOpen", filter: "number" }, { headerName: "Closed", field: "AllTicketClosed", filter: "number" }]
      }];

      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: false,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        suppressHorizontalScroll: true,
        slaveGrids: [],
        onGridReady: function onGridReady() {
          _this.get('incidentGrid').slaveGrids.push(_this.get('incidentGridBottom'));
          _this.get('incidentGridBottom').slaveGrids.push(_this.get('incidentGrid'));

          _this.createIncidentRowData(_this.get('aggregatedData').toArray());
          _this.set('gridReady', true);
        },
        onModelUpdated: function onModelUpdated() {
          //this.updateIncidentTotals();
        }
      };
    }),
    /*
        getContextMenuItems() {
            return [
                'copyWithHeaders',
                'copy'
            ];
        },
    */
    createIncidentRowData: function createIncidentRowData(aggregatedArray) {
      if (!aggregatedArray) {
        return;
      }
      var grid = this.get('incidentGrid');
      grid.api.setRowData(aggregatedArray);
      grid.api.sizeColumnsToFit();
      grid.api.refreshView();
      this.set('incidentGridRowCount', grid.api.getModel().getRowCount());
      this.set('incidentGridReady', true);
    },
    incidentGridBottom: computed(function () {
      var _this2 = this;

      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }]
      }, { headerName: "Requests", field: "AllTypeServiceRequest", filter: "number" }, {
        headerName: "Incidents",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "All incidents", field: "AllTypeIncident", filter: "number" }, { headerName: "SW", field: "AllTypeIncidentSW", filter: "number" }, { headerName: "HW", field: "AllTypeIncidentHW", filter: "number" }]
      },
      //{headerName: "Events", field: "AllTypeIncidentProactive", filter: "number"},
      { headerName: "Unclassified", field: "AllTypeUnclassified", filter: "number", hide: true }, { headerName: "Others", field: "AllTypeOther", filter: "number" }, {
        headerName: "Priorities",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "High", field: "AllPrioHigh", filter: "number" }, { headerName: "Medium", field: "AllPrioMedium", filter: "number" }, { headerName: "Low", field: "AllPrioLow", filter: "number" }, { headerName: "None", field: "AllPrioNone", filter: "number" }]
      }, {
        headerName: "States",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Open", field: "AllTicketOpen", filter: "number" }, { headerName: "Closed", field: "AllTicketClosed", filter: "number" }]
      }];

      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: false,
        suppressCellSelection: true,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        rowClass: 'ag-bold-row',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        headerHeight: 0,
        slaveGrids: [],
        onGridReady: function onGridReady() {
          _this2.createIncidentRowDataBottom(_this2.get('aggregatedData').toArray());
          _this2.set('gridReady', true);
        },
        onModelUpdated: function onModelUpdated() {
          _this2.updateIncidentTotals();
        }

      };
    }),
    updateIncidentTotals: function updateIncidentTotals() {
      if (this.get('incidentGrid')) {
        this.set('incidentGridRowCount', this.get('incidentGrid').api.getModel().getRowCount());
      } else {
        this.set('incidentGridRowCount', 0);
      }
      this.incrementProperty('incidentGridEvent');
    },

    createIncidentRowDataBottom: function createIncidentRowDataBottom() {
      var _this3 = this;

      var grid = this.get('incidentGrid');
      var gridBottom = this.get('incidentGridBottom');
      this.set('dataForBottomGrid', [{
        Project: 'Total',
        SiteName: '-',
        SiteCCode: '-',
        SiteCountry: '-',
        SiteIdentifier: '-',
        AllTypeServiceRequest: 0,
        AllTypeIncident: 0,
        AllTypeIncidentSW: 0,
        AllTypeIncidentHW: 0,
        AllTypeIncidentProactive: 0,
        AllTypeUnclassified: 0,
        AllTypeOther: 0,
        AllPrioHigh: 0,
        AllPrioMedium: 0,
        AllPrioLow: 0,
        AllPrioNone: 0,
        AllTicketOpen: 0,
        AllTicketClosed: 0
      }]);

      grid.api.forEachNodeAfterFilter(function (node) {
        var data = _this3.get('dataForBottomGrid');

        if (node.data) {
          //console.log(index + ' -> data: ' + node.data.AllTypeServiceRequest);
          data[0].AllTypeServiceRequest += node.data.AllTypeServiceRequest;
          data[0].AllTypeIncident += node.data.AllTypeIncident;
          data[0].AllTypeIncidentSW += node.data.AllTypeIncidentSW;
          data[0].AllTypeIncidentHW += node.data.AllTypeIncidentHW;
          data[0].AllTypeIncidentProactive += node.data.AllTypeIncidentProactive;
          data[0].AllTypeUnclassified += node.data.AllTypeUnclassified;
          data[0].AllTypeOther += node.data.AllTypeOther;
          data[0].AllPrioHigh += node.data.AllPrioHigh;
          data[0].AllPrioMedium += node.data.AllPrioMedium;
          data[0].AllPrioLow += node.data.AllPrioLow;
          data[0].AllPrioNone += node.data.AllPrioNone;
          data[0].AllTicketOpen += node.data.AllTicketOpen;
          data[0].AllTicketClosed += node.data.AllTicketClosed;
        }
      });

      grid.api.sizeColumnsToFit();
      gridBottom.api.setRowData(this.get('dataForBottomGrid'));
    },

    dataUpdated: _ember["default"].observer('data', function () {
      if (this.get('gridReady')) {
        this.createIncidentRowData(this.get('aggregatedData').toArray());
        this.createIncidentRowDataBottom();
      }
    }),
    filterUpdated: _ember["default"].observer('filterProperty', 'filterValue', function () {
      if (this.get('gridReady')) {
        var filterValue = this.get('filterValue');
        var filterProperty = this.get('filterProperty');
        var filterTypeMap = this.get('filterTypeMap');
        var filterMap = this.get('filterMap');
        var filterType = filterTypeMap.get(filterProperty);
        var filterTypeArr = filterType.split(",");
        var gridColumn = filterMap.get(filterProperty);
        var grid = this.get('incidentGrid');
        if (typeof grid.api !== 'undefined') {
          var filterInstance = null;
          switch (filterTypeArr[0]) {
            case 'type':
              // reset all filter from type channel
              grid.api.getFilterInstance('AllTypeIncidentSW').setFilter(null);
              grid.api.getFilterInstance('AllTypeIncidentHW').setFilter(null);
              grid.api.getFilterInstance('AllTypeIncidentProactive').setFilter(null);
              grid.api.getFilterInstance('AllTypeOther').setFilter(null);
              grid.api.getFilterInstance('AllTypeServiceRequest').setFilter(null);
              break;
            case 'prio':
              grid.api.getFilterInstance('AllPrioHigh').setFilter(null);
              grid.api.getFilterInstance('AllPrioMedium').setFilter(null);
              grid.api.getFilterInstance('AllPrioLow').setFilter(null);
              grid.api.getFilterInstance('AllPrioNone').setFilter(null);
              break;
            case 'state':
              grid.api.getFilterInstance('AllTicketOpen').setFilter(null);
              grid.api.getFilterInstance('AllTicketClosed').setFilter(null);
              break;
            default:
              console.log('wrong mapped filter value.');
              break;
          }

          if (filterTypeArr.length <= 1) {
            filterInstance = grid.api.getFilterInstance(gridColumn);
            if (filterInstance !== null) {
              if (filterValue === true) {
                filterInstance.setModel({
                  type: 'greaterThan',
                  filter: 0
                });
              }
            }
          }
          grid.api.onFilterChanged();
          this.createIncidentRowDataBottom();
          grid.api.sizeColumnsToFit();
        }
      }
    })

  });
});