define('op/pods/asset/mixins/reset-asset-properties', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    resetProperties: function resetProperties() {
      this.setProperties({
        filterDepotsOk: false,
        filterDepotsCritical: false,
        filterDepotsWarning: false,
        filterDevicesAll: false,
        filterDevicesAvailable: false,
        filterDevicesCalled: false,
        filterDevicesInRepair: false,
        filterDevicesOthers: false,
        filterCradlesAvailable: false,
        filterCradlesCalled: false,
        filterCradlesInRepair: false,
        filterCradlesOthers: false,
        colorDepots: 'bg-box-filter-color',
        colorOk: 'bg-box-filter-color',
        colorCritical: 'bg-box-filter-color',
        colorWarning: 'bg-box-filter-color',
        colorDevicesSum: 'bg-box-filter-color',
        colorDevicesInPool: 'bg-box-filter-color',
        colorDevicesInPoolOthers: 'bg-box-filter-color',
        colorMobileDevicesSet: 'bg-box-filter-color',
        colorMobileDevicesNotSet: 'bg-box-filter-color',
        colorDevicesSystems: 'bg-box-filter-color',
        colorDevicesAll: 'bg-box-filter-color',
        colorDevicesAvailable: 'bg-box-filter-color',
        colorDevicesCalled: 'bg-box-filter-color',
        colorDevicesInRepair: 'bg-box-filter-color',
        colorDevicesOthers: 'bg-box-filter-color',
        colorCradlesSum: 'bg-box-filter-color',
        colorCradlesSystems: 'bg-box-filter-color',
        colorCradlesAll: 'bg-box-filter-color',
        colorCradlesAvailable: 'bg-box-filter-color',
        colorCradlesCalled: 'bg-box-filter-color',
        colorCradlesInRepair: 'bg-box-filter-color',
        colorCradlesOthers: 'bg-box-filter-color'
      });
    },
    resetDepotsProperties: function resetDepotsProperties() {
      this.setProperties({
        filterDepots: false,
        filterDepotsOk: false,
        filterDepotsCritical: false,
        filterDepotsWarning: false,
        colorDepots: 'bg-box-filter-color',
        colorOk: 'bg-box-filter-color',
        colorCritical: 'bg-box-filter-color',
        colorWarning: 'bg-box-filter-color'
      });
    },

    resetRepairInProjectProperties: function resetRepairInProjectProperties() {
      this.setProperties({
        filterDevicesInRepair: false,
        colorDevicesInRepair: 'bg-box-filter-color',
        filterCradlesInRepair: false,
        colorCradlesInRepair: 'bg-box-filter-color'
      });
    },
    resetDevicesInPoolProperties: function resetDevicesInPoolProperties() {
      this.setProperties({
        filterDevicesInPool: false,
        colorDevicesInPool: 'bg-box-filter-color'
      });
    },
    resetDevicesInPoolOthersProperties: function resetDevicesInPoolOthersProperties() {
      this.setProperties({
        filterDevicesInPoolOthers: false,
        colorDevicesInPoolOthers: 'bg-box-filter-color'
      });
    },

    resetMobileDevicesProperties: function resetMobileDevicesProperties() {
      this.setProperties({
        filterMobileDevices: false,
        filterMobileDevicesSet: false,
        colorMobileDevicesSet: 'bg-box-filter-color',
        filterMobileDevicesNotSet: false,
        colorMobileDevicesNotSet: 'bg-box-filter-color'
      });
    },

    resetDevicesProperties: function resetDevicesProperties() {
      this.setProperties({
        filterDevicesAll: false,
        filterDevicesAvailable: false,
        filterDevicesCalled: false,
        filterDevicesInRepair: false,
        filterDevicesOthers: false,
        colorDevicesAll: 'bg-box-filter-color',
        colorDevicesAvailable: 'bg-box-filter-color',
        colorDevicesCalled: 'bg-box-filter-color',
        colorDevicesInRepair: 'bg-box-filter-color',
        colorDevicesOthers: 'bg-box-filter-color'
      });
    },
    resetCradlesProperties: function resetCradlesProperties() {
      this.setProperties({
        filterCradlesAll: false,
        filterCradlesAvailable: false,
        filterCradlesCalled: false,
        filterCradlesInRepair: false,
        filterCradlesOthers: false,
        colorCradlesAll: 'bg-box-filter-color',
        colorCradlesAvailable: 'bg-box-filter-color',
        colorCradlesCalled: 'bg-box-filter-color',
        colorCradlesInRepair: 'bg-box-filter-color',
        colorCradlesOthers: 'bg-box-filter-color'
      });
    },

    setDevicePoolProperty: function setDevicePoolProperty(newValue) {
      this.setDevicePoolProperty_(newValue);
      this.setMobileDevicesProperty_(!newValue);
      this.setDepotProperty_(!newValue);
      this.setDeviceProperty_(!newValue);
      this.setCradleProperty_(!newValue);
    },
    setDevicePoolProperty_: function setDevicePoolProperty_(newValue) {
      this.setProperties({
        filterDevicesInPool: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDevicesInPool: color
      });
    },

    setDevicePoolOthersProperty: function setDevicePoolOthersProperty(newValue) {
      this.setDevicePoolOthersProperty_(newValue);
      this.setMobileDevicesProperty_(!newValue);
      this.setDepotProperty_(!newValue);
      this.setDeviceProperty_(!newValue);
      this.setCradleProperty_(!newValue);
    },
    setDevicePoolOthersProperty_: function setDevicePoolOthersProperty_(newValue) {
      this.setProperties({
        filterDevicesInPoolOthers: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDevicesInPoolOthers: color
      });
    },

    setMobileDevicesProperty: function setMobileDevicesProperty(newValue) {
      this.setDevicePoolProperty_(!newValue);
      this.setDevicePoolOthersProperty_(!newValue);
      this.setMobileDevicesProperty_(newValue);
      this.setDepotProperty_(!newValue);
      this.setDeviceProperty_(!newValue);
      this.setCradleProperty_(!newValue);
    },
    setMobileDevicesProperty_: function setMobileDevicesProperty_(newValue) {
      this.setProperties({
        filterMobileDevices: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorMobileDevices: color
      });
    },

    setDepotProperty: function setDepotProperty(newValue) {
      this.setDepotProperty_(newValue);
      this.setMobileDevicesProperty_(!newValue);
      this.setDevicePoolProperty_(!newValue);
      this.setDevicePoolOthersProperty_(!newValue);
      this.setDeviceProperty_(!newValue);
      this.setCradleProperty_(!newValue);
    },
    setDepotProperty_: function setDepotProperty_(newValue) {
      this.setProperties({
        filterDepots: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDepots: color
      });
    },

    setDeviceProperty: function setDeviceProperty(newValue) {
      this.setDeviceProperty_(newValue);
      this.setMobileDevicesProperty_(!newValue);
      this.setDevicePoolProperty_(!newValue);
      this.setDevicePoolOthersProperty_(!newValue);
      this.setDepotProperty_(!newValue);
      this.setCradleProperty_(!newValue);
    },
    setDeviceProperty_: function setDeviceProperty_(newValue) {
      this.setProperties({
        filterDevicesAll: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDevicesAll: color
      });
    },
    setCradleProperty: function setCradleProperty(newValue) {
      this.setCradleProperty_(newValue);
      this.setMobileDevicesProperty_(!newValue);
      this.setDevicePoolProperty_(!newValue);
      this.setDevicePoolOthersProperty_(!newValue);
      this.setDepotProperty_(!newValue);
      this.setDeviceProperty_(!newValue);
    },
    setCradleProperty_: function setCradleProperty_(newValue) {
      this.setProperties({
        filterCradlesAll: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorCradlesAll: color
      });
    },

    toggleFilterProperty: function toggleFilterProperty(propertyName) {
      switch (propertyName) {
        case 'filterDevicesInPool':
          this.toggleProperty('filterDevicesInPool');
          if (this.get('filterDevicesInPool') === true) {
            this.setProperties({
              filterMobileDevices: false,
              filterDepots: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'select-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesAll', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesInPoolOthers':
          this.toggleProperty('filterDevicesInPoolOthers');
          if (this.get('filterDevicesInPoolOthers') === true) {
            this.setProperties({
              filterMobileDevices: false,
              filterDepots: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPoolOthers: 'select-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesAll', 'bg-box-filter-color');
          }
          break;
        case 'filterMobileDevices':
          this.toggleProperty('filterMobileDevices');
          if (this.get('filterMobileDevices') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterDepots: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('filterMobileDevices', 'bg-box-filter-color');
          }
          break;
        case 'filterMobileDevicesSet':
          this.toggleProperty('filterMobileDevicesSet');
          if (this.get('filterMobileDevicesSet') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterMobileDevicesNotSet: false,
              filterDepots: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorMobileDevicesSet: 'select-color',
              colorMobileDevicesNotSet: 'bg-box-filter-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('filterMobileDevicesSet', 'bg-box-filter-color');
          }
          break;
        case 'filterMobileDevicesNotSet':
          this.toggleProperty('filterMobileDevicesNotSet');
          if (this.get('filterMobileDevicesNotSet') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterMobileDevicesSet: false,
              filterDepots: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorMobileDevicesSet: 'bg-box-filter-color',
              colorMobileDevicesNotSet: 'select-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('filterMobileDevicesNotSet', 'bg-box-filter-color');
          }
          break;
        case 'filterDepots':
          this.toggleProperty('filterDepots');
          if (this.get('filterDepots') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterMobileDevices: false,
              filterDevicesAll: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorDepots: 'select-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDepots', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesAll':
          this.toggleProperty('filterDevicesAll');
          if (this.get('filterDevicesAll') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterMobileDevices: false,
              filterDepots: false,
              filterCradlesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'select-color',
              colorCradlesAll: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesAll', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesAll':
          this.toggleProperty('filterCradlesAll');
          if (this.get('filterCradlesAll') === true) {
            this.setProperties({
              filterDevicesInPool: false,
              filterDevicesInPoolOthers: false,
              filterMobileDevices: false,
              filterDepots: false,
              filterDevicesAll: false,
              colorDevicesInPool: 'bg-box-filter-color',
              colorDevicesInPoolOthers: 'bg-box-filter-color',
              colorDepots: 'bg-box-filter-color',
              colorDevicesAll: 'bg-box-filter-color',
              colorCradlesAll: 'select-color'
            });
          } else {
            this.set('colorCradlesAll', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsOk':
          this.toggleProperty('filterDepotsOk');
          if (this.get('filterDepotsOk') === true) {
            this.setProperties({
              filterDepotsCritical: false,
              filterDepotsWarning: false,
              colorOk: 'normal-color',
              colorCritical: 'bg-box-filter-color',
              colorWarning: 'bg-box-filter-color'
            });
          } else {
            this.set('colorOk', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsCritical':
          this.toggleProperty('filterDepotsCritical');
          if (this.get('filterDepotsCritical') === true) {
            this.setProperties({
              filterDepotsOk: false,
              colorOk: 'bg-box-filter-color',
              colorCritical: 'critical-color'
            });
          } else {
            this.set('colorCritical', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsWarning':
          this.toggleProperty('filterDepotsWarning');
          if (this.get('filterDepotsWarning') === true) {
            this.setProperties({
              filterDepotsOk: false,
              colorOk: 'bg-box-filter-color',
              colorWarning: 'warning-color'
            });
          } else {
            this.set('colorWarning', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesAll':
          this.toggleProperty('filterDevicesAll');
          if (this.get('filterDevicesAll') === true) {
            this.setProperties({
              filterDevicesAvailable: false,
              filterDevicesCalled: false,
              filterDevicesInRepair: false,
              filterDevicesOthers: false,
              colorDevicesAll: 'select-color',
              colorDevicesAvailable: 'bg-box-filter-color',
              colorDevicesCalled: 'bg-box-filter-color',
              colorDevicesInRepair: 'bg-box-filter-color',
              colorDevicesOthers: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesAvailable', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesAvailable':
          this.toggleProperty('filterDevicesAvailable');
          if (this.get('filterDevicesAvailable') === true) {
            this.setProperties({
              filterDevicesAll: false,
              filterDevicesCalled: false,
              filterDevicesInRepair: false,
              filterDevicesOthers: false,
              colorDevicesAll: 'bg-box-filter-color',
              colorDevicesAvailable: 'select-color',
              colorDevicesCalled: 'bg-box-filter-color',
              colorDevicesInRepair: 'bg-box-filter-color',
              colorDevicesOthers: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesAvailable', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesCalled':
          this.toggleProperty('filterDevicesCalled');
          if (this.get('filterDevicesCalled') === true) {
            this.setProperties({
              filterDevicesAll: false,
              filterDevicesAvailable: false,
              colorDevicesAll: 'bg-box-filter-color',
              colorDevicesAvailable: 'bg-box-filter-color',
              colorDevicesCalled: 'select-color'
            });
          } else {
            this.set('colorDevicesCalled', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesInRepair':
          this.toggleProperty('filterDevicesInRepair');
          if (this.get('filterDevicesInRepair') === true) {
            this.setProperties({
              filterDevicesAll: false,
              filterDevicesAvailable: false,
              colorDevicesAll: 'bg-box-filter-color',
              colorDevicesAvailable: 'bg-box-filter-color',
              colorDevicesInRepair: 'select-color'
            });
          } else {
            this.set('colorDevicesInRepair', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesOthers':
          this.toggleProperty('filterDevicesOthers');
          if (this.get('filterDevicesOthers') === true) {
            this.setProperties({
              filterDevicesAll: false,
              filterDevicesAvailable: false,
              colorDevicesAll: 'bg-box-filter-color',
              colorDevicesAvailable: 'bg-box-filter-color',
              colorDevicesOthers: 'select-color'
            });
          } else {
            this.set('colorDevicesOthers', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesAll':
          this.toggleProperty('filterCradlesAll');
          if (this.get('filterCradlesAll') === true) {
            this.setProperties({
              filterCradlesAvailable: false,
              filterCradlesCalled: false,
              filterCradlesInRepair: false,
              filterCradlesOthers: false,
              colorCradlesAll: 'select-color',
              colorCradlesAvailable: 'bg-box-filter-color',
              colorCradlesCalled: 'bg-box-filter-color',
              colorCradlesInRepair: 'bg-box-filter-color',
              colorCradlesOthers: 'bg-box-filter-color'
            });
          } else {
            this.set('colorCradlesAvailable', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesAvailable':
          this.toggleProperty('filterCradlesAvailable');
          if (this.get('filterCradlesAvailable') === true) {
            this.setProperties({
              filterCradlesAll: false,
              filterCradlesCalled: false,
              filterCradlesInRepair: false,
              filterCradlesOthers: false,
              colorCradlesAll: 'bg-box-filter-color',
              colorCradlesAvailable: 'select-color',
              colorCradlesCalled: 'bg-box-filter-color',
              colorCradlesInRepair: 'bg-box-filter-color',
              colorCradlesOthers: 'bg-box-filter-color'
            });
          } else {
            this.set('colorCradlesAvailable', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesCalled':

          this.toggleProperty('filterCradlesCalled');
          if (this.get('filterCradlesCalled') === true) {
            this.setProperties({
              filterCradlesAll: false,
              filterCradlesAvailable: false,
              colorCradlesAll: 'bg-box-filter-color',
              colorCradlesAvailable: 'bg-box-filter-color',
              colorCradlesCalled: 'select-color'
            });
          } else {
            this.set('colorCradlesCalled', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesInRepair':

          this.toggleProperty('filterCradlesInRepair');
          if (this.get('filterCradlesInRepair') === true) {
            this.setProperties({
              filterCradlesAll: false,
              filterCradlesAvailable: false,
              colorCradlesAll: 'bg-box-filter-color',
              colorCradlesAvailable: 'bg-box-filter-color',
              colorCradlesInRepair: 'select-color'
            });
          } else {
            this.set('colorCradlesInRepair', 'bg-box-filter-color');
          }
          break;
        case 'filterCradlesOthers':

          this.toggleProperty('filterCradlesOthers');
          if (this.get('filterCradlesOthers') === true) {
            this.setProperties({
              filterCradlesAll: false,
              filterCradlesAvailable: false,
              colorCradlesAll: 'bg-box-filter-color',
              colorCradlesAvailable: 'bg-box-filter-color',
              colorCradlesOthers: 'select-color'
            });
          } else {
            this.set('colorCradlesOthers', 'bg-box-filter-color');
          }
          break;

      }
    },

    filterDevicesInPool: false,
    filterDevicesInPoolOthers: false,
    filterMobileDevices: false,
    filterMobileDevicesSet: false,
    filterMobileDevicesNotSet: false,
    filterDepots: false,
    filterDepotsOk: false,
    filterDepotsCritical: false,
    filterDepotsWarning: false,
    filterDevicesAll: false,
    filterDevicesAvailable: false,
    filterDevicesCalled: false,
    filterDevicesInRepair: false,
    filterDevicesOthers: false,
    filterCradlesAll: false,
    filterCradlesAvailable: false,
    filterCradlesCalled: false,
    filterCradlesInRepair: false,
    filterCradlesOthers: false,

    colorDepots: 'bg-box-filter-color',
    colorOk: 'bg-box-filter-color',
    colorCritical: 'bg-box-filter-color',
    colorWarning: 'bg-box-filter-color',

    colorDevicesSum: 'bg-box-filter-color',
    colorDevicesInPool: 'bg-box-filter-color',
    colorDevicesInPoolOthers: 'bg-box-filter-color',
    colorDevicesSet: 'bg-box-filter-color',
    colorDevicesNotSet: 'bg-box-filter-color',
    colorDevicesSystems: 'bg-box-filter-color',
    colorDevicesAll: 'bg-box-filter-color',
    colorDevicesAvailable: 'bg-box-filter-color',
    colorDevicesCalled: 'bg-box-filter-color',
    colorDevicesInRepair: 'bg-box-filter-color',
    colorDevicesOthers: 'bg-box-filter-color',
    colorCradlesSum: 'bg-box-filter-color',
    colorCradlesSystems: 'bg-box-filter-color',
    colorCradlesAll: 'bg-box-filter-color',
    colorCradlesAvailable: 'bg-box-filter-color',
    colorCradlesCalled: 'bg-box-filter-color',
    colorCradlesInRepair: 'bg-box-filter-color',
    colorCradlesOthers: 'bg-box-filter-color'

  });
});