define("op/models/componenttype", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
  exports["default"] = _emberData["default"].Model.extend({
    ComponentTypeID: _emberData["default"].attr('number'),
    ComponentTypeNumber: _emberData["default"].attr(),
    ComponentTypeName: _emberData["default"].attr(),
    ComponentKindID: _emberData["default"].attr('number'),
    ComponentKindName: _emberData["default"].attr(),
    ProjectName: _emberData["default"].attr(),
    ProjectFamily: _emberData["default"].attr(),
    SiteName: _emberData["default"].attr(),
    SiteCCode: _emberData["default"].attr('string'),
    SiteCountry: _emberData["default"].attr('string'),
    ProjectId: _emberData["default"].attr('number'),
    ProjectFamilyId: _emberData["default"].attr('number'),
    SiteIdDetail: _emberData["default"].attr('number'),
    SiteId: _emberData["default"].attr('number'),

    SiteCountryLabel: _ember["default"].computed('SiteCountry', 'SiteCCode', function () {
      return this.data.SiteCountry + ' (' + this.data.SiteCCode + ')';
    })
  });
});