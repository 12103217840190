define('op/components/component-type-filter', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    componenttypes: service(),
    //projects: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var componentTypes = this.get('componenttypes');
      componentTypes.load(this);
      this.runOnce();
    },
    runOnce: function runOnce() {
      var _this = this;

      _ember['default'].run.schedule('actions', function () {
        var componentTypes = _this.get('componenttypes');
        _this.attrs.filterProjects(componentTypes.getProjectFamilyIDs());
        _this.attrs.filterCountries(componentTypes.getSiteCCodes());
        _this.attrs.filterSites(componentTypes.getSiteIDs());
        _this.attrs.filterKinds(componentTypes.getComponentKindIDs());
        _this.attrs.filterTypes(componentTypes.getComponentTypeIDs());

        _this.rerender();
      });
    },
    actions: {
      projectaction: function projectaction() {
        var componentTypes = this.get('componenttypes');
        componentTypes.clearSelectedCountries();
        componentTypes.clearSelectedSites();
        componentTypes.clearSelectedComponentKinds();
        componentTypes.clearSelectedComponentTypes();
        this.attrs.filterProjects(componentTypes.getProjectFamilyIDs());
      },
      countryaction: function countryaction() {
        var componentTypes = this.get('componenttypes');
        componentTypes.clearSelectedSites();
        componentTypes.clearSelectedComponentKinds();
        componentTypes.clearSelectedComponentTypes();
        this.attrs.filterCountries(componentTypes.getSiteCCodes());
      },
      siteaction: function siteaction() {
        var componentTypes = this.get('componenttypes');
        componentTypes.clearSelectedComponentKinds();
        componentTypes.clearSelectedComponentTypes();
        this.attrs.filterSites(componentTypes.getSiteIDs());
      },
      componentkindaction: function componentkindaction() {
        var componentTypes = this.get('componenttypes');
        componentTypes.clearSelectedComponentTypes();
        this.attrs.filterKinds(componentTypes.getComponentKindIDs());
      },
      componenttypeaction: function componenttypeaction() {
        var componentTypes = this.get('componenttypes');
        this.attrs.filterTypes(componentTypes.getComponentTypeIDs());
      }
    }
  });
});