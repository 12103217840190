define("op/app", ["exports", "ember", "op/resolver", "ember-load-initializers", "op/config/environment"], function (exports, _ember, _opResolver, _emberLoadInitializers, _opConfigEnvironment) {

  var App = undefined;

  _ember["default"].MODEL_FACTORY_INJECTIONS = true;

  App = _ember["default"].Application.extend({
    modulePrefix: _opConfigEnvironment["default"].modulePrefix,
    podModulePrefix: _opConfigEnvironment["default"].podModulePrefix,
    rootElement: '#op',
    Resolver: _opResolver["default"]
  });

  (0, _emberLoadInitializers["default"])(App, _opConfigEnvironment["default"].modulePrefix);

  exports["default"] = App;
});