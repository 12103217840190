define("op/pods/sitehistory/model", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    //UserLogin: DS.attr(),
    // ProjectName: DS.attr(),
    // ProjectFamilyId: attr(),
    // ProjectFamily: DS.attr(),
    //	SiteName: DS.attr(),
    //	SiteCCode: DS.attr(),
    //	SiteCountry: DS.attr(),
    //	SiteIdentifier: DS.attr(),
    //	SiteNumber: DS.attr(),
    AllDevices: _emberData["default"].attr(),
    DevicesAvailable: _emberData["default"].attr(),
    AllCradles: _emberData["default"].attr(),
    CradlesAvailable: _emberData["default"].attr(),
    AvailabilityDay: _emberData["default"].attr()
  });
});