define('op/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = Controller.extend({
    session: inject.service('session'),
    modules: inject.service('modules'),
    userproperties: inject.service('userproperties'),
    duration: 500,
    sessionData: computed('session.session.content.authenticated', function () {
      return JSON.stringify(this.get('session.session.content.authenticated'), null, '\t');
    }),

    userLogin: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userLogin;
    }),
    mainheader: computed(function () {
      return 'main-header';
    }),
    ajax: _ember['default'].inject.service(),
    actions: {
      invalidateSession: function invalidateSession() {
        var _this = this;

        var ajax = this.get('ajax');

        //#49635 Trim bei allen Eingaben
        var myTrim = this.get('userLogin').trim();
        this.set('userLogin', myTrim);

        var pwdata = this.getProperties('userLogin');

        ajax.request('/api/logout', {
          method: 'POST',
          data: pwdata
        }).then(function (result) {
          console.log(result);
        })['catch'](function (error) {
          _this.set('errors', error.errors);
        });

        this.get('session').invalidate();
      }
    }

  });
});