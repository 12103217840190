define('op/pods/mdm-sites/controller', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Controller.extend({
    updates: service('updates'),
    mdmController: _ember['default'].inject.controller('mdm'),
    mdmDeviceData: _ember['default'].computed.reads('mdmController.deviceData'),
    mdmAggregatedData: _ember['default'].computed.reads('mdmController.aggregatedData'),
    queryParams: ['siteId', 'hour'],
    siteId: null,
    hour: 0,
    init: function init() {
      var deviceData = this.get('mdmDeviceData');
      console.log(deviceData.SiteId);
      //this.set('hour',0);
    },
    siteIdChanged: _ember['default'].observer('siteId', function () {
      // deal with the change
      //console.log(`siteId changed to: ${this.get('siteId')}`);
      var aggregatedData = this.get('mdmAggregatedData');
      var Id = this.get('siteId');
      //console.log('siteId: ' + Id);
      var result = aggregatedData.filter(function (item) {
        return item.SiteId === Number(Id);
      });

      this.set('mdmSiteData', result[0]);
    }),
    actions: {
      selectHour: function selectHour(hour) {
        //console.log(hour.name);
        this.set('hour', hour.id);
        //this.set('hourSelected',hour.id);
      },
      chartClicked: function chartClicked(ident, snrId) {
        //console.log(ident, snrId);

        this.transitionToRoute('mdm-device-detail', {
          queryParams: {
            ident: ident,
            snrId: snrId,
            hourDay: this.get('hour')
          }
        });
      }
    }
  });
});