define("op/transforms/date-to-boolean", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Transform.extend({

    deserialize: function deserialize(serialized) {
      var currentDate = new Date();
      if (Math.abs(serialized.getTime() - currentDate.getTime()) > 0) {
        return true;
      } else {
        return false;
      }
    }

  });
});