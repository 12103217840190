define('op/services/modules', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service(),
    init: function init() {
      this.load();
    },
    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    load: function load() {
      var _this = this;

      var userId = this.get('userId');
      if (!isEmpty(userId)) {
        return this.get('store').findRecord('module', userId).then(function (modules) {
          _this.set('modules', modules);
        });
      } else {
        return RSVP.resolve();
      }
    }

  });
});