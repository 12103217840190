define("op/pods/mdm/mdm-overview-panel/component", ["exports", "ember", "op/pods/mdm/mixins/reset-mdm-properties", "op/tools/checkHelper", "moment"], function (exports, _ember, _opPodsMdmMixinsResetMdmProperties, _opToolsCheckHelper, _moment) {
  var service = _ember["default"].inject.service;
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var arrayDefinedWithData = _opToolsCheckHelper["default"].arrayDefinedWithData;
  var selectValueInFilter = _opToolsCheckHelper["default"].selectValueInFilter;
  exports["default"] = Component.extend(_opPodsMdmMixinsResetMdmProperties["default"], {
    i18n: _ember["default"].inject.service(),

    userproperties: service('userproperties'),

    disabledFilterProjects: false,
    disabledFilterCountries: false,
    disabledFilterSites: false,
    disabledFilterComponentKinds: false,
    disabledFilterComponentTypes: false,
    FilterComponentKindsIsVisible: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'input-group';
      }
    }),
    FilterComponentTypesIsVisible: 'input-group',

    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    siteGridInit: false,
    siteGridReady: false,
    deviceGridInit: false,
    deviceGridReady: false,
    filterValue: 'filterDepots',
    filterTypeMap: new Map([["filterDepots", "all"], ["filterDepotsOk", "ok"], ["filterDepotsCritical", "critical"], ["filterDepotsWarning", "warning"], ["filterConnectionState", "all"], ["filterConnectionStateOnline", "online"], ["filterConnectionStateOffline", "1"], ["filterConnectionStateOfflineCritical", "2"], ["filterConnectionType", "online"], ["filterConnectionTypeWifi", "4"], ["filterConnectionTypeMobile", "5"], ["filterConnectionTypeOthers", "99"], ["filterDevicesState", "online"], ["filterDevicesStateOk", "3"], ["filterDevicesStateWarning", "1"], ["filterDevicesStateCritical", "2"]]),
    toolPanelVisible: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      updateFilter: function updateFilter(filter) {
        this.attrs.updateFilter(filter);
      },
      filterDepots: function filterDepots() {
        this.resetProperties();
        this.toggleFilterProperty('filterDepots');
        this.get('filterGrid')('filterDepots', this);
      },
      filterOk: function filterOk() {
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsOk');
        this.get('filterGrid')('filterDepotsOk', this);
      },
      filterCritical: function filterCritical() {
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsCritical');
        this.get('filterGrid')('filterDepotsCritical', this);
      },
      filterWarning: function filterWarning() {
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setDepotProperty(true);
        this.toggleFilterProperty('filterDepotsWarning');
        this.get('filterGrid')('filterDepotsWarning', this);
      },
      filterConnectionState: function filterConnectionState() {
        this.resetProperties();
        this.toggleFilterProperty('filterConnectionState');
        this.get('filterGrid')('filterConnectionState', this);
      },
      filterConnectionStateOnline: function filterConnectionStateOnline() {
        this.resetDepotsProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setConnectionStateProperty(true);
        this.toggleFilterProperty('filterConnectionStateOnline');
        this.get('filterGrid')('filterConnectionStateOnline', this);
      },
      filterConnectionStateOffline: function filterConnectionStateOffline() {
        this.resetDepotsProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setConnectionStateProperty(true);
        this.toggleFilterProperty('filterConnectionStateOffline');
        this.get('filterGrid')('filterConnectionStateOffline', this);
      },
      filterConnectionStateOfflineCritical: function filterConnectionStateOfflineCritical() {
        this.resetDepotsProperties();
        this.resetConnectionTypeProperties();
        this.resetDevicesStateProperties();
        this.setConnectionStateProperty(true);
        this.toggleFilterProperty('filterConnectionStateOfflineCritical');
        this.get('filterGrid')('filterConnectionStateOfflineCritical', this);
      },
      filterConnectionType: function filterConnectionType() {
        this.resetProperties();
        this.toggleFilterProperty('filterConnectionType');
        this.get('filterGrid')('filterConnectionType', this);
      },
      filterConnectionTypeWifi: function filterConnectionTypeWifi() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetDevicesStateProperties();
        this.setConnectionTypeProperty(true);
        this.toggleFilterProperty('filterConnectionTypeWifi');
        this.get('filterGrid')('filterConnectionTypeWifi', this);
      },
      filterConnectionTypeMobile: function filterConnectionTypeMobile() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetDevicesStateProperties();
        this.setConnectionTypeProperty(true);
        this.toggleFilterProperty('filterConnectionTypeMobile');
        this.get('filterGrid')('filterConnectionTypeMobile', this);
      },
      filterConnectionTypeOthers: function filterConnectionTypeOthers() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetDevicesStateProperties();
        this.setConnectionTypeProperty(true);
        this.toggleFilterProperty('filterConnectionTypeOthers');
        this.get('filterGrid')('filterConnectionTypeOthers', this);
      },

      filterDevicesState: function filterDevicesState() {
        this.resetProperties();
        this.toggleFilterProperty('filterDevicesState');
        this.get('filterGrid')('filterDevicesState', this);
      },
      filterDevicesStateOk: function filterDevicesStateOk() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.setDeviceStateProperty(true);
        this.toggleFilterProperty('filterDevicesStateOk');
        this.get('filterGrid')('filterDevicesStateOk', this);
      },
      filterDevicesStateWarning: function filterDevicesStateWarning() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.setDeviceStateProperty(true);
        this.toggleFilterProperty('filterDevicesStateWarning');
        this.get('filterGrid')('filterDevicesStateWarning', this);
      },
      filterDevicesStateCritical: function filterDevicesStateCritical() {
        this.resetDepotsProperties();
        this.resetConnectionStateProperties();
        this.resetConnectionTypeProperties();
        this.setDeviceStateProperty(true);
        this.toggleFilterProperty('filterDevicesStateCritical');
        this.get('filterGrid')('filterDevicesStateCritical', this);
      },
      selectProjects: function selectProjects(projects) {
        this.set('selectedProjects', projects);
        //console.log(this.get('selectedProjects'));
        this.get('selectedCountries').clear();
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.set('filterChanged', 0);
        if (!this.get('tileTab')) {
          if (this.get('siteGridReady')) {
            this.createSiteRowData(this.get('aggregatedData').toArray());
          } else {
            this.send('activateListTab');
          }
          if (this.get('deviceGridReady')) {
            this.createDeviceRowData(this.get('deviceData2').toArray());
          } else {
            this.send('activateListTab');
          }
        }
      },
      selectCountries: function selectCountries(countries) {
        this.set('selectedCountries', countries);
        this.get('selectedSites').clear();
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTab')) {
          if (this.get('siteGridReady')) {
            this.createSiteRowData(this.get('aggregatedData').toArray());
          } else {
            this.send('activateListTab');
          }
          if (this.get('deviceGridReady')) {
            this.createDeviceRowData(this.get('deviceData2').toArray());
          } else {
            this.send('activateListTab');
          }
        }
      },
      selectSites: function selectSites(sites) {
        this.set('selectedSites', sites);
        //console.log(this.get('selectedSites'));
        this.get('selectedComponentKinds').clear();
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTab')) {
          if (this.get('siteGridReady')) {
            this.createSiteRowData(this.get('aggregatedData').toArray());
          } else {
            this.send('activateListTab');
          }
          if (this.get('deviceGridReady')) {
            this.createDeviceRowData(this.get('deviceData2').toArray());
          } else {
            this.send('activateListTab');
          }
        }
      },
      selectComponentKinds: function selectComponentKinds(kinds) {
        this.set('selectedComponentKinds', kinds);
        this.get('selectedComponentTypes').clear();
        this.incrementProperty('filterChanged');
        if (!this.get('tileTab')) {
          if (this.get('siteGridReady')) {
            this.createSiteRowData(this.get('aggregatedData').toArray());
          } else {
            this.send('activateListTab');
          }
          if (this.get('deviceGridReady')) {
            this.createDeviceRowData(this.get('deviceData2').toArray());
          } else {
            this.send('activateListTab');
          }
        }
      },
      selectComponentTypes: function selectComponentTypes(types) {
        this.set('selectedComponentTypes', types);
        this.incrementProperty('filterChanged');
        if (!this.get('tileTab')) {
          if (this.get('siteGridReady')) {
            this.createSiteRowData(this.get('aggregatedData').toArray());
          } else {
            this.send('activateListTab');
          }
          if (this.get('deviceGridReady')) {
            this.createDeviceRowData(this.get('deviceData2').toArray());
          } else {
            this.send('activateListTab');
          }
        }
      },
      activateTileTab: function activateTileTab() {
        this.setProperties({
          tileTab: true
        });
      },
      activateListTab: function activateListTab() {
        this.setProperties({
          tileTab: false
        });
        var grid = null;
        var filter = this.get('filterValue');
        if (filter.includes("Depots")) {
          grid = this.get('siteGrid');
        } else {
          grid = this.get('deviceGrid');
        }
        if (typeof grid.api !== 'undefined') {
          grid.api.sizeColumnsToFit();
        }
      },
      toggleToolPanel: function toggleToolPanel() {
        var grid = null;
        var filter = this.get('filterValue');
        if (filter.includes("Depots")) {
          grid = this.get('siteGrid');
        } else {
          grid = this.get('deviceGrid');
        }
        this.toggleProperty('toolPanelVisible');
        grid.api.showToolPanel(this.get('toolPanelVisible'));
        grid.api.sizeColumnsToFit();
      },
      autoComplete: function autoComplete(autofilter) {
        var grid = null;
        var filter = this.get('filterValue');
        if (filter.includes("Depots")) {
          grid = this.get('siteGrid');
        } else {
          grid = this.get('deviceGrid');
        }
        grid.api.setQuickFilter(autofilter);
      }
    },
    filterGrid: function filterGrid(filter, this_) {
      this_.set('filterValue', filter);

      if (!this_.get('tileTab')) {

        var filterMap = this_.get('filterTypeMap');
        var value = filterMap.get(filter);
        var grid = null;
        if (filter.includes("Depots")) {
          grid = this_.get('siteGrid');
          if (this_.get('siteGridInit') === false) {
            grid.api.sizeColumnsToFit();
            this_.set('siteGridInit', true);
          }
        } else {
          grid = this_.get('deviceGrid');
          if (this_.get('deviceGridInit') === false) {
            grid.api.sizeColumnsToFit();
            this_.set('deviceGridInit', true);
          }
        }

        var nameFilterInstance = null;
        var mappedStates = this_.get('mappedStates');
        var mappedDeviceStates = this_.get('mappedDeviceStates');

        grid.api.setFilterModel(null);

        if (value.includes('all')) {
          /*
          value === 'online' &&
                            this_.filterConnectionType === false &&
                            this_.filterDevicesState === false &&
                            this_.filterConnectionState === false ) {
          */
          // reset all filter
          grid.api.setFilterModel(null);
        } else {
          if (filter.includes("Depots")) {
            nameFilterInstance = grid.api.getFilterInstance('SiteState');
            if (this_.filterDepotsOk === true || this_.filterDepotsCritical === true || this_.filterDepotsWarning === true) {
              nameFilterInstance.selectNothing();
              if (this_.filterDepotsOk === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsOk'));
              }
              if (this_.filterDepotsCritical === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsCritical'));
              }
              if (this_.filterDepotsWarning === true) {
                selectValueInFilter(nameFilterInstance, filterMap.get('filterDepotsWarning'));
              }
            } else {
              nameFilterInstance.selectEverything();
            }
            grid.api.onFilterChanged();
          } else if (filter.includes("ConnectionState")) {
            grid.api.setFilterModel(null);
            grid.api.onFilterChanged();
            nameFilterInstance = grid.api.getFilterInstance('ConnectionState');
            nameFilterInstance.selectNothing();
            if (this_.filterConnectionStateOnline === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeWifi')]);
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeMobile')]);
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeOthers')]);
            } else if (this_.filterConnectionStateOffline === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionStateOffline')]);
            } else if (this_.filterConnectionStateOfflineCritical === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionStateOfflineCritical')]);
            } else {
              nameFilterInstance.selectEverything();
            }
            grid.api.onFilterChanged();
          } else if (filter.includes("ConnectionType")) {
            grid.api.setFilterModel(null);
            grid.api.onFilterChanged();
            nameFilterInstance = grid.api.getFilterInstance('ConnectionState');
            nameFilterInstance.selectNothing();
            if (this_.filterConnectionTypeWifi === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeWifi')]);
            } else if (this_.filterConnectionTypeMobile === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeMobile')]);
            } else if (this_.filterConnectionTypeOthers === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeOthers')]);
            } else if (this_.filterConnectionType === true) {
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeWifi')]);
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeMobile')]);
              selectValueInFilter(nameFilterInstance, mappedStates[filterMap.get('filterConnectionTypeOthers')]);
            } else {
              nameFilterInstance.selectEverything();
            }
            grid.api.onFilterChanged();
          } else if (filter.includes("DevicesState")) {
            grid.api.setFilterModel(null);
            grid.api.onFilterChanged();
            nameFilterInstance = grid.api.getFilterInstance('DeviceState');
            nameFilterInstance.selectNothing();
            if (this_.filterDevicesStateOk === true) {
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateOk')]);
            } else if (this_.filterDevicesStateCritical === true) {
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateCritical')]);
            } else if (this_.filterDevicesStateWarning === true) {
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateWarning')]);
            } else if (this_.filterDevicesState === true) {
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateOk')]);
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateCritical')]);
              selectValueInFilter(nameFilterInstance, mappedDeviceStates[filterMap.get('filterDevicesStateWarning')]);
            } else {
              nameFilterInstance.selectEverything();
            }
            grid.api.onFilterChanged();
          }
        }
      }
    },
    isTileTabActive: _ember["default"].computed('tileTab', function () {
      return this.get('tileTab');
    }),
    formattedLastLocationTime: function formattedLastLocationTime(params) {
      return (0, _moment["default"])(params.data.LastLocationTime).format('YYYY-MM-DD HH:mm');
    },
    formattedLastCheckInTime: function formattedLastCheckInTime(params) {
      return (0, _moment["default"])(params.data.LastCheckInTime).format('YYYY-MM-DD HH:mm');
    },

    deviceGrid: computed(function () {
      var _this = this;

      var columnDefs = [];
      var userProperties = this.get('userproperties');

      columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }]
      }, { headerName: "TypeNr", field: "ComponentTypeNumber", hide: true }, { headerName: "Type", field: "ComponentTypeName" }, { headerName: "System Number", field: "SystemNumber" }, { headerName: "Serial Number", field: "Snr" }, { headerName: "Serial Number Id", field: "SnrId", hide: true }, { headerName: "Asset Status", field: "ComponentStatusText", hide: true }, { headerName: "Asset Status Group", field: "ComponentStatusGroup", hide: true }, { headerName: "Asset Status Group Id", field: "ComponentStatusGroupID", hide: true }];

      /*    
          if (userProperties.getNameByType("AC-Support", 1) === "1" ||
            userProperties.getNameByType("AC-Support", 1) === "cadis") {
            columnDefs.push(
              { headerName: "MDM_Server", field: "MDM_Server", hide: true },
              { headerName: "MDM_DeviceName", field: "MDM_DeviceName", hide: true }
            );
          }
      */
      columnDefs.push({ headerName: "MDM_Server", field: "MDM_Server", hide: true }, { headerName: "MDM_DeviceName", field: "MDM_DeviceName", hide: true }, { headerName: "IMEI", field: "IMEI" }, { headerName: "MAC", field: "MAC", hide: true }, { headerName: "DeviceId", field: "DeviceId", hide: true }, { headerName: "ICCID", field: "ICCID", hide: true }, { headerName: "Phone Number", field: "PhoneNumber", hide: true }, { headerName: "Manufacturer", field: "Manufacturer", hide: true }, { headerName: "Model", field: "Model", hide: true });

      /*    
      if (userProperties.getNameByType("AC-Support", 1) === "1" ||
        userProperties.getNameByType("AC-Support", 1) === "cadis") {
        columnDefs.push(
          { headerName: "Phone Number", field: "PhoneNumber", hide: true },
          { headerName: "Manufacturer", field: "Manufacturer", hide: true },
          { headerName: "Model", field: "Model", hide: true }
        );
      }
      */

      columnDefs.push({ headerName: "OSVersion", field: "OSVersion", hide: true }, { headerName: "OEMVersion", field: "OEMVersion", hide: true }, { headerName: "AgentVersion", field: "AgentVersion", hide: true });

      if (userProperties.getNameByType("AC-Show-MDM-SSID", 1) === "1" || userProperties.getNameByType("AC-Show-MDM-SSID", 1) === "cadis") {
        columnDefs.push({
          headerName: "State",
          headerClass: "centeredHeader",
          marryChildren: true,
          openByDefault: false,
          children: [{ headerName: "Connection", field: "ConnectionState" }, { headerName: "last CT-Type", field: "MDM_CT_String", hide: true }, { headerName: "IP address", field: "HostName", hide: true }, { headerName: "SSID", field: "SSID", hide: true, width: 50 }, { headerName: "Online", field: "DeviceState" }, { headerName: "Battery", field: "BatteryStatus", hide: true }]
        });
      } else {
        columnDefs.push({
          headerName: "State",
          headerClass: "centeredHeader",
          marryChildren: true,
          openByDefault: false,
          children: [{ headerName: "Connection", field: "ConnectionState" }, { headerName: "last CT-Type", field: "MDM_CT_String", hide: true }, { headerName: "IP address", field: "HostName", hide: true }, { headerName: "Online", field: "DeviceState" }, { headerName: "Battery", field: "BatteryStatus", hide: true }]
        });
      }

      columnDefs.push({ headerName: "Online", field: "Online", hide: true }, { headerName: "Online Wifi Connected", field: "OnlineWifiConnected", hide: true }, { headerName: "Online Mobile Connected", field: "OnlineMobileConnected", hide: true }, { headerName: "Online Others Connected", field: "OnlineOthersConnected", hide: true }, {
        headerName: "Available [%]",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: false,
        children: [{ headerName: "RAM", field: "AvailableRAMPercent", hide: true }, { headerName: "Storage", field: "AvailableStoragePercent", hide: true }, { headerName: "External Storage", field: "AvailableExternalStoragePercent", hide: true }]
      }, {
        headerName: "Warning",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: false,
        children: [{ headerName: "Battery", field: "BatteryWarning", hide: true }, { headerName: "Memory", field: "MemoryWarning", hide: true }, { headerName: "Available Storage", field: "AvailableStorageWarning", hide: true }]
      }, {
        headerName: "Critical", headerClass: "centeredHeader", marryChildren: true, openByDefault: false,
        children: [{ headerName: "Battery", field: "BatteryCritical", hide: true }, { headerName: "Memory", field: "MemoryCritical", hide: true }, { headerName: "Available Storage", field: "AvailableStorageCritical", hide: true }]
      }, {
        headerName: "Last checkIn time", field: "LastCheckInTime",
        valueGetter: this.get('formattedLastCheckInTime'), minWidth: 130
      });

      if (userProperties.getNameByType("AC-Show-MDM-Location", 1) === "1" || userProperties.getNameByType("AC-Show-MDM-Location", 1) === "cadis") {
        columnDefs.push({
          headerName: "Last location",
          headerClass: "centeredHeader",
          marryChildren: true,
          openByDefault: false,
          hide: true,
          children: [{
            headerName: "Last location time", field: "LastLocationTime",
            valueGetter: this.get('formattedLastLocationTime'), minWidth: 130, hide: false
          }, { headerName: "Latitude", field: "Latitude", hide: true }, { headerName: "Longitude", field: "Longitude", hide: true }, { headerName: "Latitude,Longitude", field: "LatitudeLongitude", hide: false }, { headerName: "Altitude", field: "Altitude", hide: true }]
        });
      }

      //columnDefs.print
      //getColumnState()	Gets the state of the columns. Typically used when saving column state.
      //setColumnState(columnState)	Sets the state of the columns from a previous state. Returns false if one or more columns could not be found.

      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: true,
        suppressCellSelection: false,
        suppressRowClickSelection: false,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this.createDeviceRowData(_this.get('deviceData2').toArray());
        },
        onModelUpdated: function onModelUpdated() {
          _this.updateDeviceTotals();
        },
        onRowDoubleClicked: function onRowDoubleClicked() {
          _this.onDeviceRowDoubleClicked();
        }

      };
    }),
    /*
          getContextMenuItems() {
            return [
                'copyWithHeaders',
                'copy'
            ]
        },
    */
    onDeviceRowDoubleClicked: function onDeviceRowDoubleClicked() {
      if (this.get('deviceGridReady')) {
        var selectedRows = this.get('deviceGrid').api.getSelectedRows();
        this.sendAction('deviceRowDoubleClicked', selectedRows[0].Ident, selectedRows[0].SnrId);
        //this.get('router').transitionTo('mdm-device-detail', null,{rowData: selectedRows[0]});
      }
    },

    siteIds: computed.mapBy('model.mdm', 'SiteId'),
    uniqueSites: computed.uniq('siteIds'),
    deviceData: _ember["default"].computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'MDMIsUpdated', 'model.mdm', function () {
      var model = this.get('model.mdm');
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = model.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.get('SiteId'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      }
      this.sendAction('onDeviceDataChanged', data);
      return data;
    }),
    mappedData: computed('deviceData', 'uniqueSites', function () {
      var dataArr = this.get('deviceData');
      var uniqueSites = this.get('uniqueSites');
      var mappedData = _ember["default"].Map.create();

      uniqueSites.forEach(function (siteId) {
        mappedData.set(siteId, dataArr.filterBy('SiteId', siteId));
      });
      return mappedData;
    }),
    aggregatedData: computed('mappedData', function () {
      var aggregatedArray = [];
      var ii = 1;

      this.get('mappedData').forEach(function (valueArray) {
        if (valueArray.length > 0) {
          var first = valueArray.get('firstObject');
          var color = 'bg-green';

          var ProjectNamesMapped = valueArray.mapBy('ProjectName').uniq('ProjectName').sort();
          var ProjectNamesString = '';
          ProjectNamesMapped.every(function (ProjectName) {
            ProjectNamesString = ProjectNamesString + ProjectName + ' , ';
            return true;
          });
          if (ProjectNamesString.length > 0) {
            ProjectNamesString = ProjectNamesString.substr(0, ProjectNamesString.length - 3);
          }

          var ProjectIdsMapped = valueArray.mapBy('ProjectId').uniq('ProjectId').sort();
          var ProjectIdsString = '';
          ProjectIdsMapped.every(function (ProjectId) {
            ProjectIdsString = ProjectIdsString + ProjectId + ',';
            return true;
          });
          if (ProjectIdsString.length > 0) {
            ProjectIdsString = ProjectIdsString.substr(0, ProjectIdsString.length - 1);
          }

          var ProjectFamiliesMapped = valueArray.mapBy('ProjectFamily').uniq('ProjectFamily').sort();
          var ProjectFamiliesString = '';
          ProjectFamiliesMapped.every(function (ProjectFamily) {
            ProjectFamiliesString = ProjectFamiliesString + ProjectFamily + ' , ';
            return true;
          });
          if (ProjectFamiliesString.length > 0) {
            ProjectFamiliesString = ProjectFamiliesString.substr(0, ProjectFamiliesString.length - 3);
          }

          var ProjectFamilyIdsMapped = valueArray.mapBy('ProjectFamilyId').uniq('ProjectFamilyId').sort();
          var ProjectFamilyIdsString = '';
          ProjectFamilyIdsMapped.every(function (ProjectFamilyId) {
            ProjectFamilyIdsString = ProjectFamilyIdsString + ProjectFamilyId + ',';
            return true;
          });
          if (ProjectFamilyIdsString.length > 0) {
            ProjectFamilyIdsString = ProjectFamilyIdsString.substr(0, ProjectFamilyIdsString.length - 1);
          }

          var AllDevices = valueArray.length;

          var AllOnline = valueArray.reduce(function (a, b) {
            return a + b.data.Online;
          }, 0);
          var AllCriticalOffline = valueArray.reduce(function (a, b) {
            return a + b.data.OfflineCritical;
          }, 0);
          var AllOffline = valueArray.reduce(function (a, b) {
            return a + b.data.Offline;
          }, 0);
          var AllWifiNetworks = valueArray.reduce(function (a, b) {
            return a + b.data.OnlineWifiConnected;
          }, 0);
          var AllMobileNetworks = valueArray.reduce(function (a, b) {
            return a + b.data.OnlineMobileConnected;
          }, 0);
          var AllOthersNetworks = valueArray.reduce(function (a, b) {
            return a + b.data.OnlineOthersConnected;
          }, 0);
          var AllCriticalBatteries = valueArray.reduce(function (a, b) {
            return a + b.data.BatteryCritical;
          }, 0);
          var AllWarningBatteries = valueArray.reduce(function (a, b) {
            return a + b.data.BatteryWarning;
          }, 0);
          var AllCriticalRAM = valueArray.reduce(function (a, b) {
            return a + b.data.MemoryCritical;
          }, 0);
          var AllWarningRAM = valueArray.reduce(function (a, b) {
            return a + b.data.MemoryWarning;
          }, 0);
          var AllCriticalStorage = valueArray.reduce(function (a, b) {
            return a + b.data.AvailableStorageCritical;
          }, 0);
          var AllWarningStorage = valueArray.reduce(function (a, b) {
            return a + b.data.AvailableStorageWarning;
          }, 0);
          var AnyWarning = valueArray.reduce(function (a, b) {
            return a + b.data.AnyWarning;
          }, 0);
          var AnyCritical = valueArray.reduce(function (a, b) {
            return a + b.data.AnyCritical;
          }, 0);
          var AllOnlinePercent = Number(0).toFixed(1);
          var AllCriticalOfflinePercent = Number(0).toFixed(1);
          var AllOfflinePercent = Number(0).toFixed(1);
          var AllWifiNetworksPercent = Number(0).toFixed(1);
          var AllMobileNetworksPercent = Number(0).toFixed(1);
          var AllOthersNetworksPercent = Number(0).toFixed(1);
          var AllWarningBatteriesPercent = Number(0).toFixed(1);
          var AllCriticalBatteriesPercent = Number(0).toFixed(1);
          var AllWarningRAMPercent = Number(0).toFixed(1);
          var AllCriticalRAMPercent = Number(0).toFixed(1);
          var AllWarningStoragePercent = Number(0).toFixed(1);
          var AllCriticalStoragePercent = Number(0).toFixed(1);
          var AnyWarningPercent = Number(0).toFixed(1);
          var AnyCriticalPercent = Number(0).toFixed(1);
          var SiteState = 'ok';
          if (AnyWarning > 0) {
            SiteState = 'warning';
            color = 'bg-yellow';
          }
          if (AnyCritical > 0) {
            SiteState = 'critical';
            color = 'bg-red';
          }
          //let ConnectionState = 'not set';
          var ConnectionState = '';
          if (AllWifiNetworks > 0) {
            ConnectionState = ConnectionState + 'Wifi' + ' , ';
          }
          if (AllMobileNetworks > 0) {
            ConnectionState = ConnectionState + 'Mobile Network' + ' , ';
          }
          if (AllOthersNetworks > 0) {
            ConnectionState = ConnectionState + 'Others' + ' , ';
          }
          if (AllOffline > 0) {
            ConnectionState = ConnectionState + 'Offline' + ' , ';
          }
          if (AllCriticalOffline > 0) {
            ConnectionState = ConnectionState + 'critical Offline' + ' , ';
          }

          if (ConnectionState.length > 0) {
            ConnectionState = ConnectionState.substr(0, ConnectionState.length - 3);
          }

          var DevicesState = 'not set';

          if (AllDevices > 0) {
            AllOnlinePercent = (AllOnline * 100 / AllDevices).toFixed(1);
            AllCriticalOfflinePercent = (AllCriticalOffline * 100 / AllDevices).toFixed(1);
            AllOfflinePercent = (AllOffline * 100 / AllDevices).toFixed(1);
          }
          var AllOk = 0;
          if (AllOnline > 0) {
            AllOk = AllOnline - AnyWarning - AnyCritical;
            AllWifiNetworksPercent = (AllWifiNetworks * 100 / AllOnline).toFixed(1);
            AllMobileNetworksPercent = (AllMobileNetworks * 100 / AllOnline).toFixed(1);
            AllOthersNetworksPercent = (AllOthersNetworks * 100 / AllOnline).toFixed(1);
            AllWarningBatteriesPercent = (AllWarningBatteries * 100 / AllOnline).toFixed(1);
            AllCriticalBatteriesPercent = (AllCriticalBatteries * 100 / AllOnline).toFixed(1);
            AllWarningRAMPercent = (AllWarningRAM * 100 / AllOnline).toFixed(1);
            AllCriticalRAMPercent = (AllCriticalRAM * 100 / AllOnline).toFixed(1);
            AllWarningStoragePercent = (AllWarningStorage * 100 / AllOnline).toFixed(1);
            AllCriticalStoragePercent = (AllCriticalStorage * 100 / AllOnline).toFixed(1);
            AnyWarningPercent = (AnyWarning * 100 / AllOnline).toFixed(1);
            AnyCriticalPercent = (AnyCritical * 100 / AllOnline).toFixed(1);
          }

          var SiteObject = _ember["default"].Object.create({
            id: ii,
            //UserLogin: first.get('UserLogin'),

            //ProjectName: first.get('ProjectName'),
            //ProjectId: first.get('ProjectId'),
            //ProjectFamily: first.get('ProjectFamily'),
            //ProjectFamilyId: first.get('ProjectFamilyId'),
            ProjectName: ProjectNamesString,
            ProjectId: ProjectIdsString,
            ProjectFamily: ProjectFamiliesString,
            ProjectFamilyId: ProjectFamilyIdsString,

            SiteIdDetail: first.get('SiteIdDetail'),
            SiteName: first.get('SiteName'),
            SiteCCode: first.get('SiteCCode'),
            SiteCountry: first.get('SiteCountry'),
            SiteIdentifier: first.get('SiteIdentifier'),
            SiteAdditionalAddress: first.get('SiteAdditionalAddress'),
            SiteStreet: first.get('SiteStreet'),
            SiteStreetNumber: first.get('SiteStreetNumber'),
            SitePostalCode: first.get('SitePostalCode'),
            SiteCity: first.get('SiteCity'),
            SitePostReceiver: first.get('SitePostReceiver'),
            SiteId: first.get('SiteId'),
            AllDevices: AllDevices,
            AllOnline: AllOnline,
            AllOffline: AllOffline,
            AllCriticalOffline: AllCriticalOffline,
            AllWifiNetworks: AllWifiNetworks,
            AllMobileNetworks: AllMobileNetworks,
            AllOthersNetworks: AllOthersNetworks,
            AllCriticalBatteries: AllCriticalBatteries,
            AllWarningBatteries: AllWarningBatteries,
            AllCriticalRAM: AllCriticalRAM,
            AllWarningRAM: AllWarningRAM,
            AllCriticalStorage: AllCriticalStorage,
            AllWarningStorage: AllWarningStorage,
            AllOnlinePercent: AllOnlinePercent,
            AllOfflinePercent: AllOfflinePercent,
            AllCriticalOfflinePercent: AllCriticalOfflinePercent,
            AllWifiNetworksPercent: AllWifiNetworksPercent,
            AllMobileNetworksPercent: AllMobileNetworksPercent,
            AllOthersNetworksPercent: AllOthersNetworksPercent,
            AllWarningBatteriesPercent: AllWarningBatteriesPercent,
            AllCriticalBatteriesPercent: AllCriticalBatteriesPercent,
            AllWarningRAMPercent: AllWarningRAMPercent,
            AllCriticalRAMPercent: AllCriticalRAMPercent,
            AllWarningStoragePercent: AllWarningStoragePercent,
            AllCriticalStoragePercent: AllCriticalStoragePercent,
            AnyWarning: AnyWarning,
            AnyCritical: AnyCritical,
            AllOk: AllOk,
            AnyWarningPercent: AnyWarningPercent,
            AnyCriticalPercent: AnyCriticalPercent,
            bgColorDeviceAvailable: _ember["default"].String.htmlSafe(color),
            bgColor: _ember["default"].String.htmlSafe('bg-green'),
            SiteState: SiteState,
            ConnectionState: ConnectionState,
            DevicesState: DevicesState
          });
          aggregatedArray.pushObject(SiteObject);
          ii += 1;
        }
      });
      this.sendAction('onAggregatedDataChanged', aggregatedArray);

      return aggregatedArray;
    }),

    siteGrid: computed(function () {
      var _this2 = this;

      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }, { headerName: "AdditionalAddressInfo", field: "SiteAdditionalAddress", hide: true }, { headerName: "Street", field: "SiteStreet", hide: true }, { headerName: "StreetNumber", field: "SiteStreetNumber", hide: true }, { headerName: "PostalCode", field: "SitePostalCode", hide: true }, { headerName: "City", field: "SiteCity", hide: true }, { headerName: "PostReceiver", field: "SitePostReceiver", hide: true }, { headerName: "Site State", field: "SiteState" }]
      }, {
        headerName: "Connection state",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "AllDevices", field: "AllDevices" }, { headerName: "Connection State", field: "ConnectionState" }, { headerName: "AllOnline", field: "AllOnline", hide: true }, { headerName: "AllOffline", field: "AllOffline", hide: true }, { headerName: "AllCriticalOffline", field: "AllCriticalOffline", hide: true }, { headerName: "AllWifi", field: "AllWifiNetworks", hide: true }, { headerName: "AllMobileNetworks", field: "AllMobileNetworks", hide: true }, { headerName: "AllOthers", field: "AllOthersNetworks", hide: true }]
      }, {
        headerName: "Online state",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Device State", field: "DeviceState" }, { headerName: "AnyWarning", field: "AnyWarning", hide: true }, { headerName: "AnyCritical", field: "AnyCritical" }, { headerName: "AllWarningBatteries", field: "AllWarningBatteries", hide: true }, { headerName: "AllCriticalBatteries", field: "AllCriticalBatteries" }, { headerName: "AllWarningRAM", field: "AllWarningRAM", hide: true }, { headerName: "AllCriticalRAM", field: "AllCriticalRAM" }, { headerName: "AllWarningStorage", field: "AllWarningStorage", hide: true }, { headerName: "AllCriticalStorage", field: "AllCriticalStorage" }]
      }];
      return {
        columnDefs: columnDefs,
        rowHeight: 30,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: true,
        valueAggSubMenu: true,
        suppressCellSelection: false,
        suppressRowClickSelection: false,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this2.createSiteRowData(_this2.get('aggregatedData').toArray());
        },
        onModelUpdated: function onModelUpdated() {
          _this2.updateSiteTotals();
        },
        onRowDoubleClicked: function onRowDoubleClicked() {
          _this2.onSiteRowDoubleClicked();
        }

      };
    }),
    onSiteRowDoubleClicked: function onSiteRowDoubleClicked() {
      if (this.get('siteGridReady')) {
        var selectedRows = this.get('siteGrid').api.getSelectedRows();
        this.sendAction('siteRowDoubleClicked', selectedRows[0].SiteId);
        //this.get('router').transitionTo('mdm-device-detail', null,{rowData: selectedRows[0]});
      }
    },

    deviceData2: _ember["default"].computed('deviceData', function () {
      var aggregatedArray = [];

      this.get('deviceData').forEach(function (data) {
        aggregatedArray.push(data.data);
      });
      return aggregatedArray;
    }),

    ovDepots: _ember["default"].computed('aggregatedData', function () {
      return this.get('aggregatedData').length;
    }),
    ovDepotsCritical: _ember["default"].computed('aggregatedData', function () {
      var sumDepotsCritical = 0;
      this.get('aggregatedData').forEach(function (model) {
        if (model.AnyCritical > 0) {
          sumDepotsCritical += 1;
        }
      });
      return sumDepotsCritical;
    }),
    ovPercentDepotsCritical: _ember["default"].computed('ovDepots', 'ovDepotsCritical', function () {
      var sumDepotsCritical = this.get('ovDepotsCritical');
      var allDepots = this.get('ovDepots');
      if (allDepots > 0) {
        return (sumDepotsCritical * 100 / allDepots).toFixed(1);
      } else {
        Number(0).toFixed(1);
      }
    }),
    ovDepotsWarnings: _ember["default"].computed('aggregatedData', function () {
      var sumDepotsWarning = 0;
      this.get('aggregatedData').forEach(function (model) {
        if (model.AnyWarning > 0 && (typeof model.AnyCritical === 'undefined' || model.AnyCritical === 0)) {
          sumDepotsWarning += 1;
        }
      });
      return sumDepotsWarning;
    }),

    ovPercentDepotsWarnings: _ember["default"].computed('ovDepots', 'ovDepotsWarnings', function () {
      var sumDepotsWithWarning = this.get('ovDepotsWarnings');
      var allDepots = this.get('ovDepots');
      if (allDepots > 0) {
        return (sumDepotsWithWarning * 100 / allDepots).toFixed(1);
      } else {
        Number(0).toFixed(1);
      }
    }),
    ovDepotsOk: _ember["default"].computed('ovDepots', 'ovDepotsCritical', 'ovDepotsWarnings', function () {
      return this.get('ovDepots') - this.get('ovDepotsCritical') - this.get('ovDepotsWarnings');
    }),

    ovPercentDepotsOk: computed('ovDepots', 'ovDepotsOk', function () {
      var sumAllDepots = this.get('ovDepots');
      var sumDepotsOk = this.get('ovDepotsOk');
      if (sumAllDepots > 0) {
        return (sumDepotsOk * 100 / sumAllDepots).toFixed(0);
      } else {
        Number(0).toFixed(1);
      }
    }),

    allDevices: computed('deviceData2', function () {
      return this.get('deviceData2').length;
    }),

    /*
    //online: computed.mapBy('deviceData', 'Online'),
    sumOnline:
      computed('deviceData', function () {
        return this.get('deviceData').reduce((a, b) => a + b.data.Online, 0);
      }),
    percentOnline:
      computed('sumOnline', 'allDevices', function () {
        if (this.get('allDevices') > 0) {
          return (this.get('sumOnline') * 100 / this.get('allDevices')).toFixed(1);
        } else {
          Number(0).toFixed(1);
        }
      }),
    */
    //offlineCritical: computed.mapBy('deviceData', 'OfflineCritical'),
    cntCriticalOffline: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.OfflineCritical;
      }, 0);
    }),
    percentCriticalOffline: computed('cntCriticalOffline', 'allDevices', function () {
      if (this.get('allDevices') > 0) {
        return (this.get('cntCriticalOffline') * 100 / this.get('allDevices')).toFixed(1);
      } else {
        Number(0).toFixed(1);
      }
    }),
    cntOffline: computed('allDevices', 'sumOnline', 'cntCriticalOffline', function () {
      return this.get('allDevices') - this.get('sumOnline') - this.get('cntCriticalOffline');
    }),
    percentOffline: computed('cntOffline', 'allDevices', function () {
      if (this.get('allDevices') > 0) {
        return (this.get('cntOffline') * 100 / this.get('allDevices')).toFixed(1);
      } else {
        Number(0).toFixed(1);
      }
    }),
    //allOnline: computed.mapBy('deviceData', 'OnlineOnlineConnected'),
    sumOnline: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.Online;
      }, 0);
    }),
    percentOnline: computed('sumOnline', 'allDevices', function () {
      if (this.get('allDevices') > 0) {
        return (this.get('sumOnline') * 100 / this.get('allDevices')).toFixed(1);
      } else {
        Number(0).toFixed(1);
      }
    }),
    //allWifiNetworks: computed.mapBy('deviceData', 'OnlineWifiConnected'),
    sumWifiNetworks: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.OnlineWifiConnected;
      }, 0);
    }),
    percentWifiNetworks: computed('sumWifiNetworks', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('sumWifiNetworks') * 100.0 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    //allMobileNetworks: computed.mapBy('deviceData', 'MobileNetwork'),
    sumMobileNetworks: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.OnlineMobileConnected;
      }, 0);
    }),
    percentMobileNetworks: computed('sumMobileNetworks', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('sumMobileNetworks') * 100.0 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    //allOthersNetworks: computed.mapBy('deviceData', 'OnlineOthersConnected'),
    sumOthersNetworks: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.OnlineOthersConnected;
      }, 0);
    }),
    percentOthersNetworks: computed('sumOthersNetworks', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('sumOthersNetworks') * 100.0 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),

    //okDevices: computed.mapBy('deviceData','Ok'),
    devicesOk: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.Ok;
      }, 0);
    }),
    percentDevicesOk: computed('devicesOk', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('devicesOk') * 100 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    //criticalDevices: computed.mapBy('deviceData','AnyCritical'),
    devicesCritical: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.AnyCritical;
      }, 0);
    }),
    percentDevicesCritical: computed('devicesCritical', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('devicesCritical') * 100 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    //warningDevices: computed.mapBy('deviceData','AnyWarning'),
    devicesWarning: computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.AnyWarning;
      }, 0);
    }),
    percentDevicesWarning: computed('devicesWarning', 'sumOnline', function () {
      if (this.get('sumOnline') > 0) {
        return (this.get('devicesWarning') * 100 / this.get('sumOnline')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),

    ///
    ovDevicesInPool: _ember["default"].computed('devicePoolData', function () {
      //const myValue = this.get('devicePoolData').toArray().length;
      var devicesInPool = 0;

      if (this.get('isProjectPoolActive')) {
        this.get('devicePoolData').forEach(function (data) {
          //4,  --'in pool', 2, --'in repair' 99)  --'other' (Im Rollout, gestohlen, Verlust, Totalschaden, Verlust nicht ersetzt, Clearing)     
          if (data.data.ComponentStatusGroupID === 4 || data.data.ComponentStatusGroupID === 2) {
            devicesInPool = devicesInPool + 1;
          }
        });
      }
      return devicesInPool;
    }),

    ovDevicesInPoolOthers: _ember["default"].computed('devicePoolData', function () {
      //const myValue = this.get('devicePoolData').toArray().length;
      var devicesInPoolOthers = 0;

      if (this.get('isProjectPoolOthersActive')) {
        this.get('devicePoolData').forEach(function (data) {
          //4,  --'in pool', 2, --'in repair' 99)  --'other' (Im Rollout, gestohlen, Verlust, Totalschaden, Verlust nicht ersetzt, Clearing)     
          if (data.data.ComponentStatusGroupID === 99) {
            devicesInPoolOthers = devicesInPoolOthers + 1;
          }
        });
      }
      return devicesInPoolOthers;
    }),

    ///

    ovAllSystemsMDE: _ember["default"].computed('assetSumData', function () {
      var myValue = this.get('assetSumData').reduce(function (a, b) {
        return a + b.data.DevicesSystems;
      }, 0);
      return myValue;
    }),

    ovDevicesAll: _ember["default"].computed('assetSumData', function () {
      var myValue = this.get('assetSumData').reduce(function (a, b) {
        return a + b.data.AllDevices;
      }, 0);
      return myValue;
    }),
    ovPercentDevicesAll: _ember["default"].computed('ovDevicesAll', 'ovAllSystemsMDE', function () {
      var sumAllSystemsMDE = this.get('ovAllSystemsMDE');
      if (sumAllSystemsMDE > 0) {
        return (this.get('ovDevicesAll') * 100 / this.get('ovAllSystemsMDE')).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovMaxDevicesAll: _ember["default"].computed('ovDevicesAll', 'ovDevicesSystems', function () {
      var sumAllSystemsMDE = this.get('ovAllSystemsMDE');
      var sumDevicesAll = this.get('ovDevicesAll');
      if (sumAllSystemsMDE > 0 && sumDevicesAll * 100 / sumAllSystemsMDE > 100) {
        //window.console.log((sumDevicesAll * 100 / sumDevicesSystems).toFixed(0))
        return (sumDevicesAll * 100 / sumAllSystemsMDE).toFixed(0);
      } else {
        return 100;
      }
    }),

    ovDevicesAvailable: _ember["default"].computed('assetSumData', function () {
      var myValue = this.get('assetSumData').reduce(function (a, b) {
        return a + b.data.DevicesAvailable;
      }, 0);
      return myValue;
    }),
    ovPercentDevicesAvailable: _ember["default"].computed('ovDevicesAvailable', 'ovAllSystemsMDE', function () {
      var sumAllSystemsMDE = this.get('ovAllSystemsMDE');
      if (sumAllSystemsMDE > 0) {
        return (this.get('ovDevicesAvailable') * 100 / this.get('ovAllSystemsMDE')).toFixed(1);
      } else {
        return 0;
      }
    }),
    ovMaxDevicesAvailable: _ember["default"].computed('ovDevicesAvailable', 'ovDevicesSystems', function () {
      var sumAllSystemsMDE = this.get('ovAllSystemsMDE');
      var sumAvailableDevices = this.get('ovDevicesAvailable');
      if (sumAllSystemsMDE > 0 && sumAvailableDevices * 100 / sumAllSystemsMDE > 100) {
        return (sumAvailableDevices * 100 / sumAllSystemsMDE).toFixed(0);
      } else {
        return 100;
      }
    }),

    ovDevicesOnline: _ember["default"].computed('deviceData', function () {
      return this.get('deviceData').reduce(function (a, b) {
        return a + b.data.Online;
      }, 0);
    }),
    ovPercentDevicesOnline: _ember["default"].computed('ovDevicesOnline', 'ovAllSystemsMDE', function () {
      var sumAllSystemsMDE = this.get('ovAllSystemsMDE');
      if (sumAllSystemsMDE > 0) {
        return (this.get('ovDevicesOnline') * 100 / this.get('ovAllSystemsMDE')).toFixed(1);
      } else {
        return 0;
      }
    }),

    createDeviceRowData: function createDeviceRowData(data) {
      if (!data) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }

      this.get('deviceGrid').api.setRowData(data);
      //this.filter2();
      this.get('deviceGrid').api.sizeColumnsToFit();
      this.set('deviceGridRowCount', this.get('deviceGrid').api.getModel().getRowCount());
      this.set('deviceGridReady', true);
    },
    createSiteRowData: function createSiteRowData(model) {
      if (!model) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      var aggregatedArray = [];

      model.forEach(function (data) {
        aggregatedArray.push(data);
      });

      this.get('siteGrid').api.setRowData(aggregatedArray.toArray());
      this.get('siteGrid').api.sizeColumnsToFit();
      this.set('siteGridRowCount', this.get('siteGrid').api.getModel().getRowCount());
      this.set('siteGridReady', true);
    },
    updateSiteTotals: function updateSiteTotals() {
      if (this.get('siteGridReady')) {
        this.set('siteGridRowCount', this.get('siteGrid').api.getModel().getRowCount());
      } else {
        this.set('siteGridRowCount', 0);
      }
      this.incrementProperty('siteGridEvent');
    },
    updateDeviceTotals: function updateDeviceTotals() {
      if (this.get('deviceGridReady')) {
        this.set('deviceGridRowCount', this.get('deviceGrid').api.getModel().getRowCount());
      } else {
        this.set('deviceGridRowCount', 0);
      }
      this.incrementProperty('deviceGridEvent');
    },
    siteGridIsVisible: _ember["default"].computed('filterValue', function () {
      if (this.get('filterValue').indexOf("Depots") > -1) {
        return 'display-inline';
      } else {
        return 'display-none';
      }
    }),
    deviceGridIsVisible: _ember["default"].computed('filterValue', function () {
      if (this.get('filterValue').indexOf("Depots") > -1) {
        return 'display-none';
      } else {
        return 'display-inline';
      }
    }),

    assetSumData: _ember["default"].computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'MDMIsUpdated', 'model.assetsum', function () {
      var model = this.get('model.assetsum');
      var selectedProjects = this.get('selectedProjects');
      var selectedCountries = this.get('selectedCountries');
      var selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = model.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      if (arrayDefinedWithData(selectedCountries)) {
        data2 = data.filter(function (item) {
          return selectedCountries.includes(item.get('SiteCCode'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedSites)) {
        data2 = data.filter(function (item) {
          return selectedSites.includes(item.get('SiteId'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      }
      return data;
    }),

    //devicePoolData: Ember.computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'model.devicepool', function () {
    devicePoolData: _ember["default"].computed('filterChanged', 'selectedProjects', 'selectedCountries', 'selectedSites', 'selectedComponentKinds', 'selectedComponentTypes', 'model.devicepool', function () {
      var filterDevicePoolModel = this.get('model.devicepool');

      var selectedProjects = this.get('selectedProjects');
      //let selectedCountries = this.get('selectedCountries');
      //let selectedSites = this.get('selectedSites');
      var selectedComponentKinds = this.get('selectedComponentKinds');
      var selectedComponentTypes = this.get('selectedComponentTypes');
      var data2 = [];
      var data = filterDevicePoolModel; //.toArray();
      // only projects filtered
      if (arrayDefinedWithData(selectedProjects)) {
        data2 = data.filter(function (item) {
          return selectedProjects.includes(item.get('ProjectFamilyId'));
        });
        data = data2;
      } // only sites filtered
      //if (arrayDefinedWithData(selectedCountries)) {
      //  data2 = data.filter(function (item) {
      //    return (selectedCountries.includes(item.get('SiteCCode')));
      //  });
      //  data = data2;
      //}
      //if (arrayDefinedWithData(selectedSites)) {
      //  data2 = data.filter(function (item) {
      //    return (selectedSites.includes(item.get('SiteId')));
      //  });
      //  data = data2;
      //}
      if (arrayDefinedWithData(selectedComponentKinds)) {
        data2 = data.filter(function (item) {
          return selectedComponentKinds.includes(item.get('ComponentKindID'));
        });
        data = data2;
      }
      if (arrayDefinedWithData(selectedComponentTypes)) {
        data2 = data.filter(function (item) {
          return selectedComponentTypes.includes(item.get('ComponentTypeID'));
        });
        data = data2;
      }
      return data;
    })

  });
});