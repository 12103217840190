define('op/models/site', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    UserLogin: _emberData['default'].attr(),
    ProjectName: _emberData['default'].attr(),
    ProjectFamilyId: _emberData['default'].attr('number'),
    ProjectFamily: _emberData['default'].attr(),
    SiteIdDetail: _emberData['default'].attr('number'),
    SiteName: _emberData['default'].attr(),
    SiteCCode: _emberData['default'].attr(),
    SiteCountry: _emberData['default'].attr(),
    SiteNumber: _emberData['default'].attr(),
    SiteIdentifier: _emberData['default'].attr(),

    DevicesSystems: _emberData['default'].attr('number-null-to-0'),
    AllDevices: _emberData['default'].attr('number-null-to-0'),
    DevicesAvailable: _emberData['default'].attr('number-null-to-0'),
    DevicesCalled: _emberData['default'].attr('number-null-to-0'),
    DevicesInRepair: _emberData['default'].attr('number-null-to-0'),
    DevicesOther: _emberData['default'].attr('number-null-to-0'),

    CradlesSystems: _emberData['default'].attr('number-null-to-0'),
    AllCradles: _emberData['default'].attr('number-null-to-0'),
    CradlesAvailable: _emberData['default'].attr('number-null-to-0'),
    CradlesCalled: _emberData['default'].attr('number-null-to-0'),
    CradlesInRepair: _emberData['default'].attr('number-null-to-0'),
    CradlesOther: _emberData['default'].attr('number-null-to-0'),

    AvailabilityDay: _emberData['default'].attr(),
    SiteStreet: _emberData['default'].attr(),
    SiteAdditionalAddress: _emberData['default'].attr(),
    SiteStreetNumber: _emberData['default'].attr(),
    SitePostalCode: _emberData['default'].attr(),
    SiteCity: _emberData['default'].attr(),
    SitePhoneNumber: _emberData['default'].attr(),
    SitePostReceiver: _emberData['default'].attr()
  });
});