define("op/router", ["exports", "ember", "op/config/environment"], function (exports, _ember, _opConfigEnvironment) {

  //The const declares read only variable
  var Router = _ember["default"].Router.extend({
    location: _opConfigEnvironment["default"].locationType,
    rootURL: _opConfigEnvironment["default"].rootURL
  });

  Router.map(function () {
    this.route('asset');
    this.route('asset-sites');
    this.route('login');
    this.route('change-password');
    this.route('forgot-password');
    this.route('init-password');
    this.route('mdm');
    this.route('mdm-sites');
    this.route('imprint');
    this.route('privacy-statement');
    this.route('mdm-device-detail');
    this.route('updates');
    this.route('incident');
    this.route('repair');
  });

  exports["default"] = Router;
});