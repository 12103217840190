define("op/pods/repair/overview-panel/component", ["exports", "ember", "op/pods/repair/mixins/reset-repair-properties"], function (exports, _ember, _opPodsRepairMixinsResetRepairProperties) {
  var service = _ember["default"].inject.service;
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_opPodsRepairMixinsResetRepairProperties["default"], {
    selectedProjects: [],
    selectedComponentCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],

    userproperties: service('userproperties'),

    actions: {
      updateFilter: function updateFilter(filter) {
        this.attrs.updateFilter(filter);
      },
      filterDeviceAll: function filterDeviceAll() {
        this.resetDeviceProperties();
        this.toggleFilterProperty('filterDeviceAll');
        this.attrs.doFilter('filterDeviceAll', this.get('filterDeviceAll'));
      },
      filterDeviceRepaired: function filterDeviceRepaired() {
        //this.setDeviceAll();
        this.toggleFilterProperty('filterDeviceRepaired');
        this.attrs.doFilter('filterDeviceRepaired', this.get('filterDeviceRepaired'));
      },
      filterCradleAll: function filterCradleAll() {
        this.resetCradleProperties();
        this.toggleFilterProperty('filterCradleAll');
        this.attrs.doFilter('filterCradleAll', this.get('filterCradleAll'));
      },
      filterCradleRepaired: function filterCradleRepaired() {
        //this.setCradleAll();
        this.toggleFilterProperty('filterCradleRepaired');
        this.attrs.doFilter('filterCradleRepaired', this.get('filterCradleRepaired'));
      }

    },
    allSites: computed('uniqueSites', function () {
      return this.get('uniqueSites').length;
    }),
    allDevices: computed('data', function () {
      var data = this.get('data');
      if (data.length > 0) {
        var lastRefDate = data[data.length - 1].get('RefDate');
        var lastMonthData = data.filterBy('RefDate', lastRefDate);
        var devices = lastMonthData.filterBy('ComponentKindID', 1);
        return devices.reduce(function (a, b) {
          return a + b.data.AllDevices;
        }, 0);
      } else {
        return 0;
      }
    }),
    allCradles: computed('data', function () {
      var data = this.get('data');
      if (data.length > 0) {
        var lastRefDate = data[data.length - 1].get('RefDate');
        var lastMonthData = data.filterBy('RefDate', lastRefDate);
        var cradles = lastMonthData.filterBy('ComponentKindID', 2);
        return cradles.reduce(function (a, b) {
          return a + b.data.AllCradles;
        }, 0);
      } else {
        return 0;
      }
    }),
    allRepairedDevices: computed('data', function () {
      var repairedDevices = this.get('data').reduce(function (a, b) {
        if (b.data.ComponentKindID === 1) {
          return a + b.data.RepairedCount;
        } else {
          return a;
        }
      }, 0);
      console.log(repairedDevices);
      return repairedDevices;
    }),
    percentAllRepairedDevices: computed('allRepairedDevices', 'allDevices', function () {
      if (this.get('allDevices') > 0) {
        return (this.get('allRepairedDevices') * 100 / this.get('allDevices')).toFixed(2);
      } else {
        return Number(0).toFixed(2);
      }
    }),

    allRepairedCradles: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        if (b.data.ComponentKindID === 2) {
          return a + b.data.RepairedCount;
        } else {
          return a;
        }
      }, 0);
    }),
    percentAllRepairedCradles: computed('allRepairedCradles', 'allCradles', function () {
      if (this.get('allCradles') > 0) {
        return (this.get('allRepairedCradles') * 100 / this.get('allCradles')).toFixed(2);
      } else {
        return Number(0).toFixed(2);
      }
    }),

    isCradleActive: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return false;
      } else {
        return true;
      }
    }),
    isCradleActive_col6: computed('userproperties', function () {
      var userProperties = this.get('userproperties');
      if (userProperties.getNameByType("AC-Hide-Cradles", 1) === "1") {
        return 'display-none';
      } else {
        return 'col-6';
      }
    })

  });
});