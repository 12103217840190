define('op/components/login-form', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    session: service('session'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password');
        var authenticator = 'authenticator:jwt';
        this.get('session').authenticate(authenticator, credentials).then(function (value) {
          console.log(value);
          _this.sendAction();
        })['catch'](function (reason) {
          _this.set('errorMessage', reason.error);
          _this.set('errorMessageDetails', reason.error_description);
        });
      }
    }
  });
});