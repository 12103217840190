define('op/initializers/pollboy', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    app.inject('route', 'pollboy', 'service:pollboy');
  }

  exports['default'] = {
    name: 'pollboy',
    initialize: initialize
  };
});