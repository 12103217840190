define("op/pods/incident/incident-overview-panel/component", ["exports", "ember", "op/pods/incident/mixins/reset-incident-properties"], function (exports, _ember, _opPodsIncidentMixinsResetIncidentProperties) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_opPodsIncidentMixinsResetIncidentProperties["default"], {
    i18n: _ember["default"].inject.service(),
    selectedProjects: [],
    selectedCountries: [],
    selectedSites: [],
    selectedComponentKinds: [],
    selectedComponentTypes: [],
    siteGridInit: false,
    siteGridReady: false,
    deviceGridInit: false,
    deviceGridReady: false,
    toolPanelVisible: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      updateFilter: function updateFilter(filter) {
        this.attrs.updateFilter(filter);
      },
      filterStateAll: function filterStateAll() {
        this.resetStateProperties();
        //this.toggleFilterProperty('filterStateAll');
        this.attrs.doFilter('filterStateAll', this.get('filterStateAll'));
      },
      filterStateOpen: function filterStateOpen() {
        this.toggleFilterProperty('filterStateOpen');
        //this.setStateAllProperty(true);
        this.attrs.doFilter('filterStateOpen', this.get('filterStateOpen'));
      },
      filterStateClosed: function filterStateClosed() {
        this.toggleFilterProperty('filterStateClosed');
        //this.setStateAllProperty(true);
        this.attrs.doFilter('filterStateClosed', this.get('filterStateClosed'));
      },
      filterPrioAll: function filterPrioAll() {
        this.resetPrioProperties();
        //this.toggleFilterProperty('filterPrioAll');
        this.attrs.doFilter('filterPrioAll', this.get('filterPrioAll'));
      },
      filterPrioHigh: function filterPrioHigh() {
        this.toggleFilterProperty('filterPrioHigh');
        //this.setPrioAllProperty(true);
        this.attrs.doFilter('filterPrioHigh', this.get('filterPrioHigh'));
      },
      filterPrioMedium: function filterPrioMedium() {
        this.toggleFilterProperty('filterPrioMedium');
        //this.setPrioAllProperty(true);
        this.attrs.doFilter('filterPrioMedium', this.get('filterPrioMedium'));
      },
      filterPrioLow: function filterPrioLow() {
        this.toggleFilterProperty('filterPrioLow');
        //this.setPrioAllProperty(true);
        this.attrs.doFilter('filterPrioLow', this.get('filterPrioLow'));
      },
      filterPrioNone: function filterPrioNone() {
        this.toggleFilterProperty('filterPrioNone');
        //this.setPrioAllProperty(true);
        this.attrs.doFilter('filterPrioNone', this.get('filterPrioNone'));
      },

      filterTypeAll: function filterTypeAll() {
        this.resetTypeProperties();
        //this.toggleFilterProperty('filterTypeAll');
        this.attrs.doFilter('filterTypeAll', this.get('filterTypeAll'));
      },
      filterTypeSW: function filterTypeSW() {
        this.toggleFilterProperty('filterTypeSW');
        //this.setTypeAllProperty(true);
        this.attrs.doFilter('filterTypeSW', this.get('filterTypeSW'));
      },
      filterTypeHW: function filterTypeHW() {
        this.toggleFilterProperty('filterTypeHW');
        //this.setTypeAllProperty(true);
        this.attrs.doFilter('filterTypeHW', this.get('filterTypeHW'));
      },
      //    filterTypeEvents() {
      //      this.toggleFilterProperty('filterTypeEvents');
      //      //this.setTypeAllProperty(true);
      //      this.attrs.doFilter('filterTypeEvents', this.get('filterTypeEvents'));
      //    },
      filterTypeOthers: function filterTypeOthers() {
        this.toggleFilterProperty('filterTypeOthers');
        //this.setTypeAllProperty(true);
        this.attrs.doFilter('filterTypeOthers', this.get('filterTypeOthers'));
      },
      filterTypeRequests: function filterTypeRequests() {
        this.toggleFilterProperty('filterTypeRequests');
        //this.setTypeAllProperty(true);
        this.attrs.doFilter('filterTypeRequests', this.get('filterTypeRequests'));
      }
    },

    ovAllIncidentsSW: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TypeIncidentSW;
      }, 0);
    }),
    percentAllIncidentsSW: computed('ovAllIncidentsSW', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllIncidentsSW') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllIncidentsHW: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TypeIncidentHW;
      }, 0);
    }),
    percentAllIncidentsHW: computed('ovAllIncidentsHW', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllIncidentsHW') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllEvents: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TypeIncidentProactive;
      }, 0);
    }),
    percentAllEvents: computed('ovAllEvents', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllEvents') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllIncidentOthers: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TypeOther;
      }, 0);
    }),
    percentAllIncidentOthers: computed('ovAllIncidentOthers', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllIncidentOthers') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllRequests: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TypeServiceRequest;
      }, 0);
    }),
    percentAllRequests: computed('ovAllRequests', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllRequests') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllTickets: _ember["default"].computed('ovAllIncidentsSW', 'ovAllIncidentsHW', 'ovAllEvents', 'ovAllIncidentOthers', 'ovAllRequests', function () {
      return this.get('ovAllIncidentsSW') + this.get('ovAllIncidentsHW') + this.get('ovAllEvents') + this.get('ovAllIncidentOthers') + this.get('ovAllRequests');
    }),

    ovAllPrioHigh: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.PrioHigh;
      }, 0);
    }),
    percentAllPrioHigh: computed('ovAllPrioHigh', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllPrioHigh') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllPrioMedium: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.PrioMedium;
      }, 0);
    }),
    percentAllPrioMedium: computed('ovAllPrioMedium', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllPrioMedium') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllPrioLow: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.PrioLow;
      }, 0);
    }),
    percentAllPrioLow: computed('ovAllPrioLow', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllPrioLow') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllPrioNone: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.PrioNone;
      }, 0);
    }),
    percentAllPrioNone: computed('ovAllPrioNone', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllPrioNone') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),

    ovAllOpen: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TicketOpen;
      }, 0);
    }),
    percentAllOpen: computed('ovAllOpen', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllOpen') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    }),
    ovAllClosed: computed('data', function () {
      return this.get('data').reduce(function (a, b) {
        return a + b.data.TicketClosed;
      }, 0);
    }),
    percentAllClosed: computed('ovAllClosed', 'ovAllTickets', function () {
      if (this.get('ovAllTickets') > 0) {
        return (this.get('ovAllClosed') * 100 / this.get('ovAllTickets')).toFixed(1);
      } else {
        return Number(0).toFixed(1);
      }
    })
  });
});