define('op/components/site-all-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    grid1: computed('model', function () {
      var _this = this;

      var data = this.get('model').toArray();

      var columnDefs = [{ headerName: "Project Id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, { headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" },
      /*
      {headerName: "AdditionalAddressInfo", field: "SiteAdditionalAddress", hide: true},
      {headerName: "Street", field: "SiteStreet", hide: true},
      {headerName: "StreetNumber", field: "SiteStreetNumber", hide: true},
      {headerName: "PostalCode", field: "SitePostalCode", hide: true},
      {headerName: "City", field: "SiteCity", hide: true},
      {headerName: "PostReceiver", field: "SitePostReceiver", hide: true},
      */
      { headerName: "Site State", field: "siteState", filter: "set" }, { headerName: "Devices#", field: "AllDevices" }, { headerName: "Devices Available#", field: "DevicesAvailable" }, { headerName: "Devices In Repair#", field: "DevicesInRepair" }, { headerName: "Devices Others#", field: "DevicesOther" }, { headerName: "Cradles#", field: "AllCradles" }, { headerName: "Cradles Available#", field: "CradlesAvailable" }, { headerName: "Cradles In Repair#", field: "CradlesInRepair" }, { headerName: "Cradles Others#", field: "CradlesOther" }, { headerName: "Devices Available[%]", field: "PercentDevicesAvailable" }, { headerName: "Devices In Repair[%]", field: "PercentDevicesInRepair" }, { headerName: "Devices Others[%]", field: "PercentDevicesOthers" }, { headerName: "Cradles Available[%]", field: "PercentCradlesAvailable" }, { headerName: "Cradles In Repair[%]", field: "PercentCradlesInRepair" }, { headerName: "Cradles Others[%]", field: "PercentCradlesOthers" }];

      var gridOptions = {
        columnDefs: columnDefs,
        rowData: data,
        rowHeight: 30,
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        suppressCellSelection: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        isExternalFilterPresent: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        enableStatusBar: true,
        onGridReady: function onGridReady() {
          //this.api.checkGridSize();
          _this.createNewRowData1(data);
          //this.api.setRowData(data);
          //this.api.sizeColumnsToFit();
          //this.api.refreshView();
        },
        columnVisible: function columnVisible() {
          this.api.sizeColumnsToFit();
          this.api.refreshView();
        },
        modelUpdated: function modelUpdated() {
          this.api.sizeColumnsToFit();
          this.api.refreshView();
        },
        viewportChanged: function viewportChanged() {
          this.api.sizeColumnsToFit();
          this.api.refreshView();
        }
      };

      return gridOptions;
    }),
    createNewRowData1: function createNewRowData1(data) {
      if (!data) {
        // in case user selected 'onPageSizeChanged()' before the json was loaded
        return;
      }
      this.get('grid1').api.setRowData(data);
    }
  });
});