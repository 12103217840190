define('op/services/userproperties', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service(),
    init: function init() {
      this.load();
    },

    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    userLogin: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userLogin;
    }),

    load: function load() {
      var _this = this;

      var userId = this.get('userId');
      if (!isEmpty(userId)) {
        //return this.get('store').findRecord('userproperty', userId).then((userproperties) => {
        return this.get('store').findAll('userproperty').then(function (userproperties) {
          _this.set('userproperties', userproperties);
        });
      } else {
        return RSVP.resolve();
      }
    },

    getNameByType: function getNameByType(pType, pCheckIsCadis) {
      if (pCheckIsCadis === 1) {

        var myarr = ["cadis", "g300", "g310", "g320", "g330", "g340", "g370", "g380", "g390", "g450"];
        var userLogin = this.get('userLogin');
        if (myarr.indexOf(userLogin.toLowerCase()) > -1) {
          return "cadis";
        }
      }

      var model = this.get('userproperties');
      var result = model.find(function (myProperty) {
        if (myProperty.get('PropertyType') === pType) {
          return true;
        }
      });
      if (result === undefined) {
        return null;
      } else {
        return result.get('PropertyName');
      }
    }

  });
});