define('op/pods/mdm-sites/route', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    session: service('session'),
    ajax: service('ajax'),
    model: function model(transition, params) {
      console.log('siteId: ' + params.queryParams.siteId);
      return _ember['default'].RSVP.hash({
        mdmsitebatteryhistory: this.store.query('mdmsitebatteryhistory', {
          filter: {
            siteId: params.queryParams.siteId
          }
        }),
        mdmsitebatteryhistoryday: this.store.query('mdmsitebatteryhistoryday', {
          filter: {
            siteId: params.queryParams.siteId
          }
        }),
        mdmsitebatterydetail: this.store.query('mdmsitebatterydetail', {
          filter: {
            siteId: params.queryParams.siteId
          }
        }),
        mdmsitebatterydetailday: this.store.query('mdmsitebatterydetailday', {
          filter: {
            siteId: params.queryParams.siteId
          }
        })
      });
    },

    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    afterModel: function afterModel() {

      var ajax = this.get('ajax');

      this.set('userId', this.get('userId'));
      this.set('subModulId', 4);
      this.set('routeId', 2);
      this.set('description', ''); //"mdm-sites");
      var pwdata = this.getProperties('userId', 'subModulId', 'routeId', 'description');

      ajax.request('/api/set-subModule', {
        method: 'POST',
        data: pwdata
      });
    },

    actions: {
      error: function error(_error, transition) {
        console.log(transition);
        alert('The following error occurred: ' + _error);
        this.transitionTo('application');
      }
    }
  });
});