define("op/pods/components/incident-details-grid/component", ["exports", "ember", "moment", "op/tools/checkHelper"], function (exports, _ember, _moment, _opToolsCheckHelper) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var selectValueInFilter = _opToolsCheckHelper["default"].selectValueInFilter;
  exports["default"] = Component.extend({

    filterTypeMap: new Map([["filterTypeAll", "all"], ["filterTypeSW", "2"], ["filterTypeHW", "3"], ["filterTypeEvents", "-1"], ["filterTypeOthers", "1"], ["filterTypeRequests", "4"], ["filterPrioAll", "all"], ["filterPrioHigh", "1"], ["filterPrioMedium", "2"], ["filterPrioLow", "3"], ["filterPrioNone", "4"], ["filterStateAll", "all"], ["filterStateOpen", "0"], ["filterStateClosed", "1"]]),

    /*
      filterTypeMap: new Map([["filterTypeAll", "all"], ["filterTypeSW", "1"], ["filterTypeHW", "4"],
        ["filterTypeEvents", "3"], ["filterTypeOthers", "5"], ["filterTypeRequests", "2"],
        ["filterPrioAll", "all"], ["filterPrioHigh", "1"], ["filterPrioMedium", "2"], ["filterPrioLow", "3"], ["filterPrioNone", "4"],
        ["filterStateAll", "all"], ["filterStateOpen", "0"], ["filterStateClosed", "1"]]),
    */

    gridReady: false,
    actions: {
      autoComplete: function autoComplete() {
        var grid = this.get('incidentGrid');
        grid.api.setQuickFilter(this.get('autoFilter'));
      },
      toggleToolPanel: function toggleToolPanel() {
        var grid = this.get('incidentGrid');
        this.toggleProperty('toolPanelVisible');
        grid.api.showToolPanel(this.get('toolPanelVisible'));
        grid.api.sizeColumnsToFit();
      }
    },
    formattedDate: function formattedDate(params) {
      return (0, _moment["default"])(params.data.CreationTime).format('YYYY-MM-DD HH:mm');
    },
    incidentGrid: computed(function () {
      var _this = this;

      var columnDefs = [{ headerName: "ID", field: "Ident", hide: true }, { headerName: "TN", field: "TN" }, {
        headerName: "Subject", field: "Title", filter: "text",
        cellStyle: {
          'white-space': 'normal'
        }
      }, {
        headerName: "Ticket",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "Type", field: "TicketTypeGroup" }, { headerName: "Type detail", field: "TicketType", hide: true }, { headerName: "Priority", field: "TicketPriorityGroup" }, { headerName: "Priority detail", field: "TicketPriority", hide: true }, { headerName: "State", field: "TicketState" }, { headerName: "Category", field: "TicketCategory", hide: true }]
      }, { headerName: "Solution", field: "FinalText" }, { headerName: "Creation Time", field: "CreationTime", valueGetter: this.get('formattedDate') }, { headerName: "Project id", field: "ProjectId", hide: true }, { headerName: "Project", field: "ProjectFamily" }, {
        headerName: "Site",
        headerClass: "centeredHeader",
        marryChildren: true,
        openByDefault: true,
        children: [{ headerName: "CCode", field: "SiteCCode", width: 10 }, { headerName: "Country", field: "SiteCountry", hide: true, width: 30 }, { headerName: "Site Id", field: "SiteIdDetail", hide: true }, { headerName: "Name", field: "SiteName" }, { headerName: "Identifier", field: "SiteIdentifier" }]
      }];
      return {
        columnDefs: columnDefs,
        rowHeight: 25,
        //			getContextMenuItems: this.get('getContextMenuItems'),
        enableSorting: true,
        enableColResize: true,
        enableFilter: true,
        enableStatusBar: true,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        rowSelection: 'single',
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        suppressMenuHide: true,
        onGridReady: function onGridReady() {
          _this.createIncidentRowData(_this.get('data').toArray());
          _this.set('gridReady', true);
        },
        onModelUpdated: function onModelUpdated() {
          _this.updateIncidentTotals();
        }
      };
    }),
    /*
        getContextMenuItems() {
            return [
                'copyWithHeaders',
                'copy'
            ];
        },
    */
    createIncidentRowData: function createIncidentRowData(data) {
      if (!data) {
        return;
      }
      var aggregatedArray = [];
      data.forEach(function (item) {
        aggregatedArray.push(item.data);
      });
      var grid = this.get('incidentGrid');
      grid.api.setRowData(aggregatedArray);
      grid.api.sizeColumnsToFit();
      grid.columnApi.setColumnWidth('Title', 500);
      this.set('incidentGridRowCount', grid.api.getModel().getRowCount());
      this.set('gridReady', true);
    },

    updateIncidentTotals: function updateIncidentTotals() {
      if (this.get('incidentGrid')) {
        this.set('incidentGridRowCount', this.get('incidentGrid').api.getModel().getRowCount());
      } else {
        this.set('incidentGridRowCount', 0);
      }
      this.incrementProperty('incidentGridEvent');
    },

    dataUpdated: _ember["default"].observer('data', function () {
      if (this.get('gridReady')) {
        this.createIncidentRowData(this.get('data'));
      }
    }),
    filterUpdated: _ember["default"].observer('filterProperty', 'filterValue', function () {
      if (this.get('gridReady')) {
        var filterProperty = this.get('filterProperty');
        var filterValue = this.get('filterValue');
        var mappedTypes = this.get('mappedTypes');
        var mappedPriorities = this.get('mappedPriorities');
        var mappedStates = this.get('mappedStates');
        var filterMap = this.get('filterTypeMap');
        var value = filterMap.get(filterProperty);

        var grid = this.get('incidentGrid');

        if (typeof grid.api !== 'undefined') {
          switch (filterProperty) {
            case 'filterTypeAll':
            case 'filterTypeSW':
            case 'filterTypeHW':
            case 'filterTypeEvents':
            case 'filterTypeOthers':
            case 'filterTypeRequests':
              {
                var filterInstance = grid.api.getFilterInstance('TicketTypeGroup');
                filterInstance.selectNothing();
                if (filterValue === true) {
                  selectValueInFilter(filterInstance, mappedTypes[value]);
                } else {
                  filterInstance.selectEverything();
                }
                //filterInstance.onFilterChanged();
              }
              break;
            case 'filterPrioAll':
            case 'filterPrioHigh':
            case 'filterPrioMedium':
            case 'filterPrioLow':
            case 'filterPrioNone':
              {
                var filterInstance = grid.api.getFilterInstance('TicketPriorityGroup');
                filterInstance.selectNothing();
                if (filterValue === true) {
                  selectValueInFilter(filterInstance, mappedPriorities[value]);
                } else {
                  filterInstance.selectEverything();
                }
                //filterInstance.onFilterChanged();
              }
              break;
            case 'filterStateAll':
            case 'filterStateOpen':
            case 'filterStateClosed':
              {
                var filterInstance = grid.api.getFilterInstance('TicketState');
                filterInstance.selectNothing();
                if (filterValue === true) {
                  selectValueInFilter(filterInstance, mappedStates[value]);
                } else {
                  filterInstance.selectEverything();
                }
                //filterInstance.onFilterChanged();
              }
              break;

            default:
              console.log('wrong filter property.');
          }
          grid.api.onFilterChanged();
        }
      }
    })

  });
});