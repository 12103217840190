define("op/pods/mdm/controller", ["exports", "ember", "op/tools/checkHelper"], function (exports, _ember, _opToolsCheckHelper) {
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;
  var computed = _ember["default"].computed;
  var definedWithData = _opToolsCheckHelper["default"].definedWithData;
  exports["default"] = Controller.extend({
    updates: service('updates'),
    queryParams: ['tileTab'],
    tileTab: true,
    init: function init() {
      "use strict";
      this.get('updates').setLastUpdated('MDMIsUpdated');
    },
    actions: {
      onDeviceDataChanged: function onDeviceDataChanged(data) {
        this.set('deviceData', data);
      },
      onAggregatedDataChanged: function onAggregatedDataChanged(data) {
        this.set('aggregatedData', data);
      },
      siteRowDoubleClicked: function siteRowDoubleClicked(siteId) {
        this.transitionToRoute('mdm-sites', { queryParams: { siteId: siteId } });
      },
      deviceRowDoubleClicked: function deviceRowDoubleClicked(ident, snrId) {
        this.transitionToRoute('mdm-device-detail', { queryParams: { ident: ident, snrId: snrId } });
      }
    },
    connectionStateIds: computed.mapBy('model.mdm', 'ConnectionStateID'),
    uniqueConnectionStateIds: computed.uniq('connectionStateIds'),

    mappedStates: computed('model.mdm', 'uniqueConnectionStateIds', function () {
      var uniqueConnectionStateIds = this.get('uniqueConnectionStateIds');
      var data = this.get('model.mdm');
      var mappedData = {};
      uniqueConnectionStateIds.forEach(function (id) {
        var filteredData = data.findBy('ConnectionStateID', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.ConnectionState;
        }
      });
      return mappedData;
    }),
    deviceStateIds: computed.mapBy('model.mdm', 'DeviceStateID'),
    uniqueDeviceStateIds: computed.uniq('deviceStateIds'),

    mappedDeviceStates: computed('model.mdm', 'uniqueDeviceStateIds', function () {
      var uniqueDeviceStateIds = this.get('uniqueDeviceStateIds');
      var data = this.get('model.mdm');
      var mappedData = {};
      uniqueDeviceStateIds.forEach(function (id) {
        var filteredData = data.findBy('DeviceStateID', id);
        if (definedWithData(filteredData)) {
          mappedData[id] = filteredData.data.DeviceState;
        }
      });
      return mappedData;
    })

  });
});