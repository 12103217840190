define("op/pods/mdm-sites/mdm-site-battery-history/chart-battery-history/component", ["exports", "ember", "ember-highcharts/components/high-charts"], function (exports, _ember, _emberHighchartsComponentsHighCharts) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _emberHighchartsComponentsHighCharts["default"].extend({

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    click: function click(item) {
      var chart = this.chart;
      if (typeof chart.options !== 'undefined' && typeof chart.options.drilledDown !== 'undefined' && chart.options.drilledDown === true) {
        var model = this.get('mdmDeviceData');
        var filteredData = model.filterBy('Snr', item.originalEvent.point.name);
        var ident = filteredData[0].get('Ident');
        var snrId = filteredData[0].get('SnrId');
        //let data = { queryParams: { Ident: ident, SnrId: snrId }};
        this.get('action')(ident, snrId);
      }
    },

    chartOptions_: {
      chart: {
        type: 'column',
        events: {
          drilldown: function drilldown(e) {
            if (!e.seriesOptions) {
              var id = e.point.drilldown.toString();

              var chart = this;
              chart.setTitle({ text: 'Devices with critical battery charge state on ' + e.point.name });
              this.yAxis[0].setTitle({ text: 'Count of critical battery charge states' });
              var options = chart.options;
              var groups = chart.options.groups;
              var drilldowns = _defineProperty({}, id, {
                name: 'Count of critical battery charge states',
                tooltip: {
                  headerFormat: '<span style="font-size:11px;">{series.name}</span><br>',
                  pointFormat: '<span style="color:{point.color};">{point.name}</span>: device was ' + '<b>{point.y}</b> times below the critical battery charge state!<br/>'
                },
                data: groups[id]
              }),
                  series = drilldowns[e.point.name];
              chart.addSeriesAsDrilldown(e.point, series);
              options.drilledDown = true;
            }
          },
          drillup: function drillup() {
            this.options.drilledDown = false;
            this.setTitle({ text: 'Number of devices with critical battery charge state' });
            this.yAxis[0].setTitle({ text: 'Device count with critical battery state of charge' });
          }
        }
      },
      title: {
        text: 'Number of devices with critical battery charge state'
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Device count with critical battery state of charge'
        },
        allowDecimals: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px;">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color};">{point.name}</span>: ' + '<b>{point.y}</b> devices with critical battery state of charge<br/>'
      },
      drilldown: {
        series: []
      }
    },
    chartOptions: _ember["default"].computed('chartOptions_', function () {
      var opt = this.get('chartOptions_');

      var hour = this.get('hour');
      var group_to_values = undefined;
      if (hour === 0) {
        var mdmsitebatterydetailday = this.get('model.mdmsitebatterydetailday');
        var arr = mdmsitebatterydetailday.toArray();
        group_to_values = arr.reduce(function (obj, item) {
          obj[item.data.Day] = obj[item.data.Day] || [];
          var data = [item.data.Snr, item.data.CountBatteryCritical];
          obj[item.data.Day].push(data);

          return obj;
        }, {});
      } else {
        var mdmsitebatterydetail = this.get('model.mdmsitebatterydetail');
        var arr = mdmsitebatterydetail.toArray();
        group_to_values = arr.reduce(function (obj, item) {
          if (hour === item.data.Hour) {
            obj[item.data.Day] = obj[item.data.Day] || [];
            var data = [item.data.Snr, item.data.CountBatteryCritical];
            obj[item.data.Day].push(data);
          }
          return obj;
        }, {});
      }
      opt["groups"] = group_to_values;
      opt["drilledDown"] = false;
      return opt;
    }),

    content: _ember["default"].computed('model.mdmsitebatterydetail', 'model.mdmsitebatterydetailday', 'model.mdmsitebatteryhistory', 'model.mdmsitebatteryhistoryday', 'hour', function () {
      var _this = this;

      var chart = this.get('chart');
      if (typeof chart !== 'undefined' && chart !== null) {
        (function () {
          var opt = chart.options;
          var hour = _this.get('hour');
          var group_to_values = undefined;
          if (hour === 0) {
            var mdmsitebatterydetailday = _this.get('model.mdmsitebatterydetailday');
            var arr = mdmsitebatterydetailday.toArray();
            group_to_values = arr.reduce(function (obj, item) {
              obj[item.data.Day] = obj[item.data.Day] || [];
              var data = [item.data.Snr, item.data.CountBatteryCritical];
              obj[item.data.Day].push(data);
              return obj;
            }, {});
          } else {
            var mdmsitebatterydetail = _this.get('model.mdmsitebatterydetail');
            var arr = mdmsitebatterydetail.toArray();
            group_to_values = arr.reduce(function (obj, item) {
              if (hour === item.data.Hour) {
                obj[item.data.Day] = obj[item.data.Day] || [];
                var data = [item.data.Snr, item.data.CountBatteryCritical];
                obj[item.data.Day].push(data);
              }
              return obj;
            }, {});
          }
          opt["groups"] = group_to_values;
          //opt["drilledDown"] = false;

          if (chart.options.drilledDown === true) {
            if (chart.drilldownLevels.length > 0) {
              chart.drillUp();
            }
          }
        })();
      }
      var _chartData = [{
        name: 'device count',
        colorByPoint: false,
        color: '#258BE2',
        data: []
      }];
      var hour = this.get('hour');
      var mdmsitebatteryhistory = this.get('model.mdmsitebatteryhistory');
      if (hour === 0) {
        var mdmsitebatteryhistoryday = this.get('model.mdmsitebatteryhistoryday');
        //let arr = mdmsitebatteryhistoryday.toArray();
        mdmsitebatteryhistoryday.forEach(function (model) {

          var entry = {
            name: model.get('Day'),
            y: model.get('CountSNRCritical'),
            drilldown: model.get('Day')
          };
          _chartData[0].data.pushObject(entry);
        });
      } else {
        mdmsitebatteryhistory.forEach(function (model) {
          if (hour === model.get('Hour')) {
            var entry = {
              name: model.get('Day'),
              y: model.get('CountSNRCritical'),
              drilldown: model.get('Day')
            };
            _chartData[0].data.pushObject(entry);
          }
        });
      }
      return _chartData;
    })
  });
});