define('op/pods/incident/mixins/reset-incident-properties', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    resetProperties: function resetProperties() {
      this.setProperties({
        filterTypeAll: false,
        filterTypeSW: false,
        filterTypeHW: false,
        //      filterTypeEvents: false,
        filterTypeOthers: false,
        filterTypeRequests: false,

        filterPrioAll: false,
        filterPrioHigh: false,
        filterPrioMedium: false,
        filterPrioLow: false,
        filterPrioNone: false,

        filterStateAll: false,
        filterStateOpen: false,
        filterStateClosed: false,

        colorTypeAll: 'bg-box-filter-color',
        colorTypeSW: 'bg-box-filter-color',
        colorTypeHW: 'bg-box-filter-color',
        //      colorTypeEvents: 'bg-box-filter-color',
        colorTypeOthers: 'bg-box-filter-color',
        colorTypeRequests: 'bg-box-filter-color',

        colorPrioAll: 'bg-box-filter-color',
        colorPrioHigh: 'bg-box-filter-color',
        colorPrioMedium: 'bg-box-filter-color',
        colorPrioLow: 'bg-box-filter-color',
        colorPrioNone: 'bg-box-filter-color',

        colorStateAll: 'bg-box-filter-color',
        colorStateOpen: 'bg-box-filter-color',
        colorStateClosed: 'bg-box-filter-color'
      });
    },
    resetTypeProperties: function resetTypeProperties() {
      this.setProperties({
        filterTypeAll: false,
        filterTypeSW: false,
        filterTypeHW: false,
        //      filterTypeEvents: false,
        filterTypeOthers: false,
        filterTypeRequests: false,
        colorTypeAll: 'bg-box-filter-color',
        colorTypeSW: 'bg-box-filter-color',
        colorTypeHW: 'bg-box-filter-color',
        //      colorTypeEvents: 'bg-box-filter-color',
        colorTypeOthers: 'bg-box-filter-color',
        colorTypeRequests: 'bg-box-filter-color'
      });
    },
    resetPrioProperties: function resetPrioProperties() {
      this.setProperties({
        filterPrioAll: false,
        filterPrioHigh: false,
        filterPrioMedium: false,
        filterPrioLow: false,
        filterPrioNone: false,
        colorPrioAll: 'bg-box-filter-color',
        colorPrioHigh: 'bg-box-filter-color',
        colorPrioMedium: 'bg-box-filter-color',
        colorPrioLow: 'bg-box-filter-color',
        colorPrioNone: 'bg-box-filter-color'
      });
    },
    resetStateProperties: function resetStateProperties() {
      this.setProperties({
        filterStateAll: false,
        filterStateOpen: false,
        filterStateClosed: false,
        colorStateAll: 'bg-box-filter-color',
        colorStateOpen: 'bg-box-filter-color',
        colorStateClosed: 'bg-box-filter-color'
      });
    },
    toggleFilterProperty: function toggleFilterProperty(propertyName) {
      var colorName = propertyName.replace("filter", "color");
      var channel = propertyName.slice(6, 10);
      this.toggleProperty(propertyName);
      if (this.get(propertyName) === true) {
        switch (channel) {
          case 'Type':
            this.resetTypeProperties();
            break;
          case 'Prio':
            this.resetPrioProperties();
            break;
          case 'Stat':
            this.resetStateProperties();
            break;
          default:
            console.log('wrong channel supplied!');
            break;
        }
        this.set(propertyName, true);
        this.set(colorName, 'select-color');
      } else {
        this.set(colorName, 'bg-box-filter-color');
      }
    },
    filterTypeAll: false,
    filterTypeSW: false,
    filterTypeHW: false,
    //  filterTypeEvents: false,
    filterTypeOthers: false,
    filterTypeRequests: false,

    filterPrioAll: false,
    filterPrioHigh: false,
    filterPrioMedium: false,
    filterPrioLow: false,
    filterPrioNone: false,

    filterStateAll: false,
    filterStateOpen: false,
    filterStateClosed: false,

    colorTypeAll: 'bg-box-filter-color',
    colorTypeSW: 'bg-box-filter-color',
    colorTypeHW: 'bg-box-filter-color',
    //  colorTypeEvents: 'bg-box-filter-color',
    colorTypeOthers: 'bg-box-filter-color',
    colorTypeRequests: 'bg-box-filter-color',

    colorPrioAll: 'bg-box-filter-color',
    colorPrioHigh: 'bg-box-filter-color',
    colorPrioMedium: 'bg-box-filter-color',
    colorPrioLow: 'bg-box-filter-color',
    colorPrioNone: 'bg-box-filter-color',

    colorStateAll: 'bg-box-filter-color',
    colorStateOpen: 'bg-box-filter-color',
    colorStateClosed: 'bg-box-filter-color'

  });
});