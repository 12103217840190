define('op/pods/mdm/mixins/reset-mdm-properties', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    resetProperties: function resetProperties() {
      this.setProperties({
        filterDepotsOk: false,
        filterDepotsCritical: false,
        filterDepotsWarning: false,

        filterConnectionStateOnline: false,
        filterConnectionStateOffline: false,
        filterConnectionStateOfflineCritical: false,

        filterConnectionTypeWifi: false,
        filterConnectionTypeMobile: false,
        filterConnectionTypeOthers: false,

        filterDevicesStateOk: false,
        filterDevicesStateWarning: false,
        filterDevicesStateCritical: false,

        colorDepots: 'bg-box-filter-color',
        colorOk: 'bg-box-filter-color',
        colorWarning: 'bg-box-filter-color',
        colorCritical: 'bg-box-filter-color',

        colorAllSystemsMDE: 'bg-box-filter-color',
        colorDevicesAll: 'bg-box-filter-color',
        colorDevicesAvailable: 'bg-box-filter-color',
        //colorDevicesState: 'bg-box-filter-color',

        colorConnectionState: 'bg-box-filter-color',
        colorOnline: 'bg-box-filter-color',
        colorOffline: 'bg-box-filter-color',
        colorOfflineCritical: 'bg-box-filter-color',

        colorConnectionType: 'bg-box-filter-color',
        colorWifiNet: 'bg-box-filter-color',
        colorMobileNet: 'bg-box-filter-color',
        colorOthersNet: 'bg-box-filter-color',

        colorDevicesState: 'bg-box-filter-color',
        colorDevicesOk: 'bg-box-filter-color',
        colorDevicesWarning: 'bg-box-filter-color',
        colorDevicesCritical: 'bg-box-filter-color'
      });
    },
    resetDepotsProperties: function resetDepotsProperties() {
      this.setProperties({
        filterDepots: false,
        filterDepotsOk: false,
        filterDepotsCritical: false,
        filterDepotsWarning: false,
        colorDepots: 'bg-box-filter-color',
        colorOk: 'bg-box-filter-color',
        colorCritical: 'bg-box-filter-color',
        colorWarning: 'bg-box-filter-color'
      });
    },

    resetConnectionStateProperties: function resetConnectionStateProperties() {
      this.setProperties({
        filterConnectionState: false,
        filterConnectionStateOnline: false,
        filterConnectionStateOffline: false,
        filterConnectionStateOfflineCritical: false,
        colorConnectionState: 'bg-box-filter-color',
        colorOnline: 'bg-box-filter-color',
        colorOffline: 'bg-box-filter-color',
        colorOfflineCritical: 'bg-box-filter-color'
      });
    },

    resetConnectionTypeProperties: function resetConnectionTypeProperties() {
      this.setProperties({
        filterConnectionType: false,
        filterConnectionTypeWifi: false,
        filterConnectionTypeMobile: false,
        filterConnectionTypeOthers: false,
        colorConnectionType: 'bg-box-filter-color',
        colorWifiNet: 'bg-box-filter-color',
        colorMobileNet: 'bg-box-filter-color',
        colorOthersNet: 'bg-box-filter-color'
      });
    },

    resetDevicesStateProperties: function resetDevicesStateProperties() {
      this.setProperties({
        filterDevicesState: false,
        filterDevicesStateOk: false,
        filterDevicesStateWarning: false,
        filterDevicesStateCritical: false,
        colorDevicesState: 'bg-box-filter-color',
        colorDevicesOk: 'bg-box-filter-color',
        colorDevicesWarning: 'bg-box-filter-color',
        colorDevicesCritical: 'bg-box-filter-color'
      });
    },
    setDepotProperty: function setDepotProperty(newValue) {
      this.setDepotProperty_(newValue);
      this.setConnectionStateProperty_(!newValue);
      this.setConnectionTypeProperty(!newValue);
      this.setDeviceStateProperty_(!newValue);
    },
    setDepotProperty_: function setDepotProperty_(newValue) {
      this.setProperties({
        filterDepots: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDepots: color
      });
    },

    setConnectionStateProperty: function setConnectionStateProperty(newValue) {
      this.setDepotProperty_(!newValue);
      this.setConnectionStateProperty_(newValue);
      this.setConnectionTypeProperty(!newValue);
      this.setDeviceStateProperty_(!newValue);
    },
    setConnectionStateProperty_: function setConnectionStateProperty_(newValue) {
      this.setProperties({
        filterConnectionState: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorConnectionState: color
      });
    },
    setConnectionTypeProperty: function setConnectionTypeProperty(newValue) {
      this.setDepotProperty_(!newValue);
      this.setConnectionStateProperty_(!newValue);
      this.setConnectionTypeProperty_(newValue);
      this.setDeviceStateProperty_(!newValue);
    },
    setConnectionTypeProperty_: function setConnectionTypeProperty_(newValue) {
      this.setProperties({
        filterConnectionType: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorConnectionType: color
      });
    },
    setDeviceStateProperty: function setDeviceStateProperty(newValue) {
      this.setDepotProperty_(!newValue);
      this.setConnectionStateProperty_(!newValue);
      this.setConnectionTypeProperty_(!newValue);
      this.setDeviceStateProperty_(newValue);
    },
    setDeviceStateProperty_: function setDeviceStateProperty_(newValue) {
      this.setProperties({
        filterDevicesState: newValue
      });
      var color = newValue === true ? 'select-color' : 'bg-box-filter-color';
      this.setProperties({
        colorDevicesState: color
      });
    },
    toggleFilterProperty: function toggleFilterProperty(propertyName) {
      switch (propertyName) {
        case 'filterDepots':
          this.toggleProperty('filterDepots');
          if (this.get('filterDepots') === true) {
            this.setProperties({
              filterConnectionState: false,
              filterConnectionType: false,
              filterDevicesState: false,
              colorDepots: 'select-color',
              colorConnectionState: 'bg-box-filter-color',
              colorConnectionType: 'bg-box-filter-color',
              colorDevicesState: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDepots', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionState':
          this.toggleProperty('filterConnectionState');
          if (this.get('filterConnectionState') === true) {
            this.setProperties({
              filterDepots: false,
              filterConnectionType: false,
              filterDevicesState: false,
              colorDepots: 'bg-box-filter-color',
              colorConnectionState: 'select-color',
              colorConnectionType: 'bg-box-filter-color',
              colorDevicesState: 'bg-box-filter-color'
            });
          } else {
            this.set('colorConnectionState', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionType':
          this.toggleProperty('filterConnectionType');
          if (this.get('filterConnectionType') === true) {
            this.setProperties({
              filterDepots: false,
              filterConnectionState: false,
              filterDevicesState: false,
              colorDepots: 'bg-box-filter-color',
              colorConnectionState: 'bg-box-filter-color',
              colorConnectionType: 'select-color',
              colorDevicesState: 'bg-box-filter-color'
            });
          } else {
            this.set('colorConnectionType', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesState':
          this.toggleProperty('filterDevicesState');
          if (this.get('filterDevicesState') === true) {
            this.setProperties({
              filterDepots: false,
              filterConnectionState: false,
              filterConnectionType: false,
              colorDepots: 'bg-box-filter-color',
              colorConnectionState: 'bg-box-filter-color',
              colorConnectionType: 'bg-box-filter-color',
              colorDevicesState: 'select-color'
            });
          } else {
            this.set('colorDevicesState', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsOk':
          this.toggleProperty('filterDepotsOk');
          if (this.get('filterDepotsOk') === true) {
            this.setProperties({
              filterDepotsCritical: false,
              filterDepotsWarning: false,
              colorOk: 'normal-color',
              colorCritical: 'bg-box-filter-color',
              colorWarning: 'bg-box-filter-color'
            });
          } else {
            this.set('colorOk', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsCritical':
          this.toggleProperty('filterDepotsCritical');
          if (this.get('filterDepotsCritical') === true) {
            this.setProperties({
              filterDepotsOk: false,
              colorOk: 'bg-box-filter-color',
              colorCritical: 'critical-color'
            });
          } else {
            this.set('colorCritical', 'bg-box-filter-color');
          }
          break;
        case 'filterDepotsWarning':
          this.toggleProperty('filterDepotsWarning');
          if (this.get('filterDepotsWarning') === true) {
            this.setProperties({
              filterDepotsOk: false,
              colorOk: 'bg-box-filter-color',
              colorWarning: 'warning-color'
            });
          } else {
            this.set('colorWarning', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionStateOnline':
          this.toggleProperty('filterConnectionStateOnline');
          if (this.get('filterConnectionStateOnline') === true) {
            this.setProperties({
              filterConnectionStateOffline: false,
              filterConnectionStateOfflineCritical: false,
              colorOnline: 'select-color',
              colorOffline: 'bg-box-filter-color',
              colorOfflineCritical: 'bg-box-filter-color'
            });
          } else {
            this.set('colorOffline', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionStateOffline':
          this.toggleProperty('filterConnectionStateOffline');
          if (this.get('filterConnectionStateOffline') === true) {
            this.setProperties({
              filterConnectionStateOnline: false,
              filterConnectionStateOfflineCritical: false,
              colorOffline: 'warning-color',
              colorOnline: 'bg-box-filter-color',
              colorOfflineCritical: 'bg-box-filter-color'
            });
          } else {
            this.set('colorOffline', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionStateOfflineCritical':
          this.toggleProperty('filterConnectionStateOfflineCritical');
          if (this.get('filterConnectionStateOfflineCritical') === true) {
            this.setProperties({
              filterConnectionStateOnline: false,
              filterConnectionStateOffline: false,
              colorOfflineCritical: 'critical-color',
              colorOnline: 'bg-box-filter-color',
              colorOffline: 'bg-box-filter-color'
            });
          } else {
            this.set('colorOfflineCritical', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionTypeWifi':
          this.toggleProperty('filterConnectionTypeWifi');
          if (this.get('filterConnectionTypeWifi') === true) {
            this.setProperties({
              filterConnectionTypeMobile: false,
              filterConnectionTypeOthers: false,
              colorWifiNet: 'select-color',
              colorMobileNet: 'bg-box-filter-color',
              colorOthersNet: 'bg-box-filter-color'
            });
          } else {
            this.set('colorWifi', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionTypeMobile':
          this.toggleProperty('filterConnectionTypeMobile');
          if (this.get('filterConnectionTypeMobile') === true) {
            this.setProperties({
              filterConnectionTypeWifi: false,
              filterConnectionTypeOthers: false,
              colorWifiNet: 'bg-box-filter-color',
              colorMobileNet: 'select-color',
              colorOthersNet: 'bg-box-filter-color'
            });
          } else {
            this.set('colorMobileNet', 'bg-box-filter-color');
          }
          break;
        case 'filterConnectionTypeOthers':
          this.toggleProperty('filterConnectionTypeOthers');
          if (this.get('filterConnectionTypeOthers') === true) {
            this.setProperties({
              filterConnectionTypeWifi: false,
              filterConnectionTypeMobile: false,
              colorWifiNet: 'bg-box-filter-color',
              colorMobileNet: 'bg-box-filter-color',
              colorOthersNet: 'select-color'
            });
          } else {
            this.set('colorOthersNet', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesStateOk':
          this.toggleProperty('filterDevicesStateOk');
          if (this.get('filterDevicesStateOk') === true) {
            this.setProperties({
              filterDevicesStateWarning: false,
              filterDevicesStateCritical: false,
              colorDevicesOk: 'normal-color',
              colorDevicesWarning: 'bg-box-filter-color',
              colorDevicesCritical: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesOk', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesStateWarning':
          this.toggleProperty('filterDevicesStateWarning');
          if (this.get('filterDevicesStateWarning') === true) {
            this.setProperties({
              filterDevicesStateOk: false,
              filterDevicesStateCritical: false,
              colorDevicesOk: 'bg-box-filter-color',
              colorDevicesWarning: 'warning-color',
              colorDevicesCritical: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesWarning', 'bg-box-filter-color');
          }
          break;
        case 'filterDevicesStateCritical':
          this.toggleProperty('filterDevicesStateCritical');
          if (this.get('filterDevicesStateCritical') === true) {
            this.setProperties({
              filterDevicesStateOk: false,
              filterDevicesStateWarning: false,
              colorDevicesOk: 'bg-box-filter-color',
              colorDevicesCritical: 'critical-color',
              colorDevicesWarning: 'bg-box-filter-color'
            });
          } else {
            this.set('colorDevicesCritical', 'bg-box-filter-color');
          }
          break;
      }
    },

    filterDepots: false,
    filterDepotsOk: false,
    filterDepotsCritical: false,
    filterDepotsWarning: false,

    filterConnectionState: false,
    filterConnectionStateOnline: false,
    filterConnectionStateOffline: false,
    filterConnectionStateOfflineCritical: false,

    filterConnectionType: false,
    filterConnectionTypeWifi: false,
    filterConnectionTypeMobile: false,
    filterConnectionTypeOthers: false,

    filterDevicesState: false,
    filterDevicesStateOk: false,
    filterDevicesStateWarning: false,
    filterDevicesStateCritical: false,

    colorDepots: 'bg-box-filter-color',
    colorOk: 'bg-box-filter-color',
    colorCritical: 'bg-box-filter-color',
    colorWarning: 'bg-box-filter-color',

    colorAllSystemsMDE: 'bg-box-filter-color',
    colorDevicesAll: 'bg-box-filter-color',
    colorDevicesAvailable: 'bg-box-filter-color',
    //colorDevicesState: 'bg-box-filter-color',

    colorConnectionState: 'bg-box-filter-color',
    colorOnline: 'bg-box-filter-color',
    colorOffline: 'bg-box-filter-color',
    colorOfflineCritical: 'bg-box-filter-color',

    colorConnectionType: 'bg-box-filter-color',
    colorWifiNet: 'bg-box-filter-color',
    colorMobileNet: 'bg-box-filter-color',
    colorOthersNet: 'bg-box-filter-color',

    colorDevicesState: 'bg-box-filter-color',
    colorDevicesOk: 'bg-box-filter-color',
    colorDevicesWarning: 'bg-box-filter-color',
    colorDevicesCritical: 'bg-box-filter-color'

  });
});