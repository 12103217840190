define("op/pods/mdm-sites/mdm-site-battery-history/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    //router: Ember.inject.service('-routing'),
    drilledDown: false,
    actions: {
      chartClicked: function chartClicked(ident, snrId) {
        this.get('action')(ident, snrId);
      }
    }
  });
});