define('op/pods/incident/model', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    Ident: attr('number'),
    TN: attr(),
    Title: attr(),
    FinalText: attr(),
    CreationTime: attr('date'),
    ChangeTime: attr('date'),
    SiteId: attr('number'),
    SiteIdDetail: attr('number'),
    SiteName: attr(),
    SiteCCode: attr(),
    SiteCountry: attr(),
    SiteIdentifier: attr(),
    AdditionalAddress: attr(),
    SiteStreet: attr(),
    SiteStreetNumber: attr(),
    SitePostalCode: attr(),
    SiteCity: attr(),
    SitePhoneNumber: attr(),
    SitePostReceiver: attr(),
    ProjectId: attr('number'),
    ProjectName: attr(),
    ProjectFamilyId: attr('number'),
    ProjectFamily: attr(),
    TicketOpen: attr('number'),
    TicketClosed: attr('number'),
    TypeServiceRequest: attr('number'),
    TypeIncident: attr('number'),
    TypeIncidentSW: attr('number'),
    TypeIncidentHW: attr('number'),
    TypeIncidentProactive: attr('number'),
    TypeSWChange: attr('number'),
    TypeUnclassified: attr('number'),
    TypeOther: attr('number'),
    PrioHigh: attr('number'),
    PrioMedium: attr('number'),
    PrioLow: attr('number'),
    PrioNone: attr('number'),
    CategoryExtCustomer: attr('number'),
    CategoryExtKratzer: attr('number'),
    CategoryExt3rdParty: attr('number'),
    CategoryExtUnclassified: attr('number'),
    TicketType: attr(),
    TicketTypeId: attr('number-null-to-0'),
    TicketTypeGroupId: attr('number-null-to-0'),
    TicketTypeGroup: attr(),
    TicketState: attr(),
    TicketStateId: attr('number-null-to-0'),
    TicketPriority: attr(),
    TicketPriorityId: attr('number-null-to-0'),
    TicketPriorityGroupId: attr('number-null-to-0'),
    TicketPriorityGroup: attr(),
    TicketCategory: attr()

  });
});