define("op/pods/asset-sites/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var computed = _ember["default"].computed;
  var getOwner = _ember["default"].getOwner;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    session: service('session'),
    breadCrumb: {
      title: 'Assets Dashboard - Site Details'
    },

    componenttypes: service('componenttypes'),
    ajax: service('ajax'),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      console.log('attemptedTransition: ' + transition);
      if (!this.get('session').isAuthenticated) {
        this.transitionTo('application');
      }
    },
    model: function model(transition, params) {
      return _ember["default"].RSVP.hash({
        siteId: params.queryParams.siteId,
        sitehistories: this.store.query('sitehistory', {
          filter: {
            //projectFamilyId: params.queryParams.projectFamilyId,
            projectFamilyId: this.get('componenttypes').getProjectFamilyIDsString(),
            siteId: params.queryParams.siteId,
            //siteId: this.get('componenttypes').getSiteIDsString(),
            componentTypeID: this.get('componenttypes').getComponentTypeIDsString()
          }
        })
      });
    },
    userId: computed('session.session.content.authenticated', function () {
      var authenticator = getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData.userId;
    }),

    afterModel: function afterModel() {

      var ajax = this.get('ajax');

      this.set('userId', this.get('userId'));
      this.set('subModulId', 1);
      this.set('routeId', 2);
      this.set('description', ''); //"asset-sites");
      var pwdata = this.getProperties('userId', 'subModulId', 'routeId', 'description');

      ajax.request('/api/set-subModule', {
        method: 'POST',
        data: pwdata
      });
    },

    actions: {
      error: function error(_error, transition) {
        console.log(transition);
        alert('The following error occurred: ' + _error);
        this.transitionTo('application');
      }
    }
  });
});